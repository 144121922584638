import * as React from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import styles from "../css/common.module.css";
import $ from 'jquery';

export default function Main() {

	const authState = useSelector(state => state.auth);
    // console.log("authState : ", authState);

	const dispatch = useDispatch();	

	useEffect(() => {

		// 여기에서 jQuery를 초기화 합니다.
		$(document).ready(function(){

			// console.log("jquery ready!");

		});

	}, [$]);

	const downloadLink = () => {
		
		// 브라우저가 사용된 운영체제 정보
		// console.log(navigator.platform);
		// 브라우저가 실행된 운영체제 정보
		// console.log(navigator.userAgent);

		if (navigator.userAgent.indexOf('Windows') > -1)
		{
			// console.log('윈도우');
			window.open('about:blank').location.href = 'https://play.google.com/store/apps';
		}
		else if (navigator.userAgent.indexOf('Macintosh') > -1)
		{
			// console.log('맥킨토시');
			window.open('about:blank').location.href = 'https://www.apple.com/kr/app-store/';
		}
		else if (navigator.userAgent.indexOf('iPhone') > -1)
		{
			// console.log('아이폰');
			window.open('about:blank').location.href = 'https://www.apple.com/kr/app-store/';
		}
		else if (navigator.userAgent.indexOf('Android') > -1)
		{
			// console.log('안드로이드');
			window.open('about:blank').location.href = 'https://play.google.com/store/apps';
		}
		else
		{
			// console.log('기타 os');
			window.open('about:blank').location.href = 'https://play.google.com/store/apps';
		}
	};

  return (
    <>
      	{/* Header 분리됨 MainLayout Header에 있음 */}

		  <div className={styles.download_area}>
			<div className={styles.download_top_line_banner}>
				<div className={styles.inner}>
					<span>야미 다운로드</span>
				</div>
			</div>
			<div className={styles.download_wrap}>
				<div className={styles.top_top_area}>
					<div className={styles.left_area}>
						<div className={styles.download_title_wrap}>
							<span>언제 어디서나 즐겁게 코딩!</span>
							<img src="/assets/logo@2x.png" alt="logo" />
						</div>
						<p className={styles.sub_title}>Full 3D 야미코딩의 세계로 어서오세요.</p>
						<p>그동안 경험해보지 못한 <br/>코딩블록의 새로운 개념을 <br/>체험판을 통해 느껴보세요!</p>
						<div className={styles.down_link_area}>
							<a href="https://play.google.com/store/apps" target="_blank" rel='noopener noreferrer'><img src="/assets/down_google@2x.png" alt="google"/></a>        
							<a href="https://www.apple.com/kr/app-store/" target="_blank" rel='noopener noreferrer'><img src="/assets/down_apple@2x.png" alt="apple"/></a>
						</div>
					</div>
					<div className={styles.right_area}>
						<div className={styles.top_area}>
							<img src="/assets/download_img1@2x.png" alt="img1"/>
						</div>
						<div className={styles.bottom_area}>
							<img src="/assets/download_img2@2x.png" alt="img2"/>
							<img src="/assets/download_img3@2x.png" alt="img3"/>
							<img src="/assets/download_img4@2x.png" alt="img4"/>
							<img src="/assets/download_img5@2x.png" alt="img5"/>
							<img src="/assets/download_img6@2x.png" alt="img6"/>
							<img src="/assets/download_img7@2x.png" alt="img7"/>
						</div>
					</div>
				</div>
				<div className={styles.btn_area}>
					<button type="button" onClick={downloadLink}>다운로드</button>
				</div>
			</div>
		</div>{/* download_area */}
		
		{/* Footer 분리됨 MainLayout Footer에 있음 */}
    </>
  );
}