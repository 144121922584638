import * as React from 'react';
import styles from "../../css/common.module.css";
import $ from 'jquery';

import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { register, groupRegister } from "../../actions/auth";
import { studentCreate, studentIdCheck } from "../../actions/student";
import { useNavigate, useLocation } from 'react-router-dom';

export default function RegisterView() {

  const initialStudentState = {
    id: "", 
    password: "",
    confirmPassword: "",
    name: "",
    nickname: "",
    year: "",
    month: "",
    gender: 1,
    showPassword: false,
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // console.log('location state : ', location.state);

  const [student, setStudent] = useState(initialStudentState);
  const [join, setJoin] = useState(location?.state?.body);
  const [joinGroup, setJoinGroup] = useState(location?.state?.addbody);
  // const [parentIdx, setParentIdx] = useState(location.state.parentIdx);

  const [fontSizeValue, setFontSizeValue] = useState(null);

  const [idCheck, setIdCheck] = useState(false);

  // console.log(parentIdx);

  const handleInputChange = event => {
    const { name, value } = event.target;
    console.log("name : ", name);
    console.log("value : ", value);
    setStudent({ ...student, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (student.id === "")
		{
			return alert("아이디를 입력해주세요!");
		}
		else
		{
			if (!idCheck)
			{
        return alert("중복된 아이디입니다. 다른 아이디를 입력해주세요!");
			}

			const regId =  /^[A-Za-z0-9+]{2,50}$/;
      if (regId.test(student.id) === false) 
      {
        return alert('아이디는 2자리 이상 50자리 이하의 영어와 숫자만 입력해주세요.');
      }
		}

    if(student.name === "")
    {
      return alert('이름을 입력해주세요.');
    }

    if(student.nickname === "")
    {
      return alert('닉네임을 입력해주세요.');
    }

    if(student.year === "")
    {
      return alert('생년월일의 년도를 선택해주세요.');
    }

    if(student.month === "")
    {
      return alert('생년월일의 월을 선택해주세요.');
    }

    // 정규 표현식으로 비밀번호 생성 규칙에 맞는지 체크합니다.
    let reg = new RegExp(/(?=.*\d{1,20})(?=.*[~`!@#$%\^&*()-+=]{1,20})(?=.*[a-zA-Z]{1,20}).{8,20}$/);
    // console.log(reg.test(join.password));
    if( !reg.test(student.password) ) 
    {
        alert("비밀번호 생성 규칙은 영문, 숫자, 특수문자 포함 8자 이상 20자가지 입니다.");
        return false;
    }

    if(student.password !== student.confirmPassword)
    {
      return alert('비밀번호와 비밀번호 확인은 같아야 합니다.')
    }

    // console.log("student : ", student);
    // console.log("join : ", join);
    // console.log("joinGroup : ", joinGroup);

    if (joinGroup === undefined)  // 그룹 사용자의 정보가 없다면... 일반 가입입니다.
    {
      if (join === undefined)
        return alert('사용자 값 오류입니다.');

      dispatch(register(join))
      .then(data => 
      {
          // console.log("data : ", data);
          if (!data.success)
          {
              alert(data.msg);
          }
          else
          {
            // navigate("/auth/RegisterStudentView", {
            //   state: {
            //     parentIdx: data.msg,
            //   },
            // });
  
            let body = {
              mem_idx: data.msg,
              id: student.id,
              password: student.password,
              name: student.name,
              nickname: student.nickname,
              gender: student.gender,
              birthday: student.year + "-" + student.month + "-01",
            };
        
            // console.log("body : ", body);
  
            // 여기서 디스패치로~
            dispatch(studentCreate(body))
            .then(data => 
            {
                // console.log("data : ", data);
                if (!data.success)
                {
                    alert(data.msg);
                    return;
                }
                alert("가입이 완료되었습니다.");
                navigate("/auth/login");
            })
            .catch(e => {
                console.log(e);
            });
          }
        })
        .catch(e => {
            console.log(e);
        });   
    }
    else  // 정보가 있다면 단체 가입입니다.
    {
      if (join === undefined)
        return alert('사용자 값 오류입니다.');

      if (joinGroup === undefined)
        return alert('사용자 추가값 오류입니다.')

      dispatch(groupRegister({join: join, joinGroup: joinGroup}))
      .then(data => 
      {
          // console.log("data : ", data);
          if (!data.success)
          {
            alert(data.msg);
          }
          else
          {
            let body = {
              mem_idx: data.msg,
              id: student.id,
              password: student.password,
              name: student.name,
              nickname: student.nickname,
              gender: student.gender,
              birthday: student.year + "-" + student.month + "-01",
            };
        
            // console.log("body : ", body);
  
            // 여기서 디스패치로~
            dispatch(studentCreate(body))
            .then(data => 
            {
                // console.log("data : ", data);
                if (!data.success)
                {
                    alert(data.msg);
                    return;
                }
                alert("가입이 완료되었습니다.");
                navigate("/auth/login");
            })
            .catch(e => {
                console.log(e);
            });
          }
      })
      .catch(e => {
          console.log(e);
      });
    }
    
  }

  // const hasError = passwordEntered =>
  //   student.password.length < 5 ? true : false;

  const hasError = (passwordEntered) =>
  {
    // 정규 표현식으로 비밀번호 생성 규칙에 맞는지 체크합니다.
    let reg = new RegExp(/(?=.*\d{1,20})(?=.*[~`!@#$%\^&*()-+=]{1,20})(?=.*[a-zA-Z]{1,20}).{8,20}$/);
    // console.log(reg.test(join.password));
    return !reg.test(student.password);
  }
    
  const hasNotSameError = passwordEntered =>
    student.password != student.confirmPassword ? true : false;

  const getYears = () => 
  {
    let date=new Date();
    let selYear=date.getFullYear();
    
    //올해 기준으로 -30년을 보여줌
    let stY=Number(selYear)-30;
    let nY=Number(selYear);

    const result = [];
    let index = 0;

    for(let y=stY; y<=nY; y++)
    {
      result.push(<MenuItem value={y} key={index++}>{y}년</MenuItem>);
    }
    
    return result;
  };

  useEffect(() => {

    let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ? true : false;
			
			if(!isMobile) 
			{
				  //PC
          setFontSizeValue({style: {fontSize: 15}});
      }
      else 
      {
          // mobile
          setFontSizeValue({style: {fontSize: 12}});
      }

      // console.log(typeof(fontSizeValue));

      // console.log("location?.state?.body : ", location?.state?.body);
      // console.log("location?.state?.addbody : ", location?.state?.addbody);

      if (location?.state?.body !== undefined)
        setJoin(location?.state?.body);

      if (location?.state?.addbody !== undefined)
        setJoinGroup(location?.state?.addbody);

  }, []);

  const studentIDCheck = (event, id) => {

		// 학습자 중복 아이디 검사
		// console.log("dispatch studentIDCheck");
		// console.log("id : ", id);

    if(id === "")
		{
			return alert("아이디를 입력해주세요!");
		}

    dispatch(studentIdCheck(id))
    .then(data => 
    {
        // console.log("data : ", data);

      if(!data.success)
      {
        setIdCheck(true);
        $(`.${styles.input_area}.${styles.nikname}`).removeClass(`${styles.overlap}`);
        $(`.${styles.input_area}.${styles.nikname}`).removeClass(`${styles.overlapno}`);
        $(`.${styles.input_area}.${styles.nikname}`).addClass(`${styles.overlapno}`);
      }
      else
      {
        setIdCheck(false);
        $(`.${styles.input_area}.${styles.nikname}`).removeClass(`${styles.overlap}`);
        $(`.${styles.input_area}.${styles.nikname}`).removeClass(`${styles.overlapno}`);
        $(`.${styles.input_area}.${styles.nikname}`).addClass(`${styles.overlap}`);
      }
    })
    .catch(e => {
        console.log(e);
    });
	}

  return (
    <>
      <div className={`${styles.login_bg_wrapper} ${styles.stuedent_make_bg_wrapper}`}>
        <div className={`${styles.stuedent_make_area} ${styles.middle}`}>
          <div className={styles.login_top}>
            <a href="/" className={styles.logo}><img src="/assets/logo@2x.png" alt="logo"/></a>
            <a href="/" className={styles.to_join}>홈페이지로 돌아가기</a>
          </div>
          <span className={styles.login_title}>학습자 만들기</span>
          <div className={`${styles.stuedent_make_wrap} ${styles.center_align}`}>
            <div className={`${styles.input_area} ${styles.nikname}`}>
              <TextField
                required
                fullWidth
                id="id"
                label="아이디"
                name="id"
                autoComplete="username"
                autoFocus
                onChange={handleInputChange}
                InputLabelProps={fontSizeValue} // font size of input label
                sx={{ paddingRight: 2 }}
              />
              <button type="button" onClick={(e)=>studentIDCheck(e, student.id)}>중복확인</button>
            </div>
            <div className={`${styles.input_area} ${styles.name}`}>
              <TextField
                required
                fullWidth
                id="name"
                label="Name"
                name="name"
                onChange={handleInputChange}
                InputLabelProps={fontSizeValue} // font size of input label
              />
            </div> 
            <div className={`${styles.input_area} ${styles.name}`}>
              <TextField
                  required
                  fullWidth
                  id="nickname"
                  label="Nick Name"
                  name="nickname"
                  onChange={handleInputChange}
                  inputProps={{ maxLength: 7 }}
                  InputLabelProps={fontSizeValue} // font size of input label
              />
            </div>
            <div className={`${styles.input_area} ${styles.name}`}>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                value={student.year}
                onChange={handleInputChange}
                displayEmpty
                name='year'
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value="" disabled>
                  <em>년</em>
                </MenuItem>
                {getYears()}
              </Select>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                value={student.month}
                onChange={handleInputChange}
                displayEmpty
                name='month'
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value="" disabled>
                  <em>월</em>
                </MenuItem>
                <MenuItem value={`01`}>1월</MenuItem>
                <MenuItem value={`02`}>2월</MenuItem>
                <MenuItem value={`03`}>3월</MenuItem>
                <MenuItem value={`04`}>4월</MenuItem>
                <MenuItem value={`05`}>5월</MenuItem>
                <MenuItem value={`06`}>6월</MenuItem>
                <MenuItem value={`07`}>7월</MenuItem>
                <MenuItem value={`08`}>8월</MenuItem>
                <MenuItem value={`09`}>9월</MenuItem>
                <MenuItem value={`10`}>10월</MenuItem>
                <MenuItem value={`11`}>11월</MenuItem>
                <MenuItem value={`12`}>12월</MenuItem>
              </Select>
            </FormControl>
            </div>
            <div className={`${styles.input_area} ${styles.name}`}>
            <FormLabel id="gender">성별</FormLabel>
            <RadioGroup
              row
              aria-labelledby="gender"
              name="gender"
              defaultValue={1}
              onChange={handleInputChange}
            >
              <FormControlLabel value="1" control={<Radio />} label="남자" />
              <FormControlLabel value="2" control={<Radio />} label="여자" />
            </RadioGroup>
            </div>
            <div className={`${styles.input_area} ${styles.password}`}>
              <TextField
                required
                fullWidth
                name="password"
                label="비밀번호 영문,숫자,특수문자 조합 8~20자리"
                type="password"
                id="password"
                error={hasError('password')} // 해당 텍스트필드에 error 핸들러 추가
                onChange={handleInputChange}
                autoComplete="new-password"
                InputLabelProps={fontSizeValue} // font size of input label
              />
            </div> 
            <div className={`${styles.input_area} ${styles.re_password}`}>
              <TextField
                required
                fullWidth
                name="confirmPassword"
                label="패스워드 확인"
                type="password"
                id="confirmPassword"
                error={hasNotSameError('confirmPassword')} // 해당 텍스트필드에 error 핸들러 추가
                helperText={
                    hasNotSameError('confirmPassword') ? "입력한 비밀번호와 일치하지 않습니다." : null
                } // 에러일 경우에만 안내 문구 표시
                onChange={handleInputChange}
                autoComplete="new-password"
                InputLabelProps={fontSizeValue} // font size of input label
              />
            </div> 
            <a href="#" onClick={handleSubmit} className={styles.ok}>가입완료</a>
          </div>
        </div>
      </div>
    </>
  );
}