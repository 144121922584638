import {
  LOGIN_SUCCESS_AUTH,
  LOGIN_FAIL_AUTH,
  REGISTER_SUCCESS_AUTH,
  REGISTER_FAIL_AUTH,
  GROUP_REGISTER_SUCCESS_AUTH,
  GROUP_REGISTER_FAIL_AUTH,
  LOGOUT_AUTH,
  PASSWORD_CHANGE_AUTH,
  LOGIN_RELOAD_AUTH,
  PASSWORD_UPDATE_AUTH,
} from "../actions/types";

// 사용자 정보를 로컬스토리지에서 가져옵니다.
const user = JSON.parse(localStorage.getItem("user"));

// 사용자 정보가 로컬스토리지에 있다면 로그인된 상태입니다.
const initialState = user
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: null };

const authReducer = (state = initialState, action) => {
  
  const { type, payload } = action;

  // console.log("authReducer Call!");
  // console.log("type : ", type);
  // console.log("payload : ", payload);

  switch (type) {
    case LOGIN_SUCCESS_AUTH:
      return {
        ...state,
        isLoggedIn: true,
        user: payload,
      };

    case LOGIN_FAIL_AUTH:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };

    case LOGOUT_AUTH:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };

    case LOGIN_RELOAD_AUTH:
      return {
        ...state,
        isLoggedIn: true,
        user: payload,
      };

    case REGISTER_SUCCESS_AUTH:
      return {
        ...state,
        isLoggedIn: false,
        user: payload,
      };

    case REGISTER_FAIL_AUTH:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };

    case GROUP_REGISTER_SUCCESS_AUTH:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };

    case GROUP_REGISTER_FAIL_AUTH:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };

    case PASSWORD_CHANGE_AUTH:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };

    case PASSWORD_UPDATE_AUTH:
      return state;

    default:
      return state;
  }
};

export default authReducer;