import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import styles from "../css/common.module.css";
import $ from 'jquery';
import { orderCreate } from "../actions/order";
import http from "../http-common";
import { useLocation } from 'react-router-dom';

import * as common from "../lib";

export default function Main() {

	const authState = useSelector(state => state.auth);
    // console.log("authState : ", authState);

	const dispatch = useDispatch();	
	const location = useLocation();

	// console.log('location state : ', location.state);

	const [orderData, setOrderData] = useState(location?.state?.OrderData);
	const [payMethod, setPayMethod] = useState("card");

	useEffect(() => {

		// 여기에서 jQuery를 초기화 합니다.
		$(document).ready(function(){

			// console.log("jquery ready!");

		});

	}, [$]);

	const gotoUrl = (event, url) =>
	{
		event.preventDefault();
		window.location.href = url;
	}

	const payOnClick = (event) =>
	{
		event.preventDefault();

		const sendOrder = {
			product_idx:orderData.product_idx,
			order_count:orderData.order_count,
			order_amount:orderData.order_amount
		};

		// console.log("sendOrder : ", sendOrder);
		
		// 여기서 디스패치로~
		dispatch(orderCreate(sendOrder))
		.then(data => 
		{
			let order = null;

			// console.log("data : ", data);
			if (!data.success)
			{
				alert(data.msg);
				return;
			}
			else
			{
				order = data.data;

				// 결제 창 호출
				const IMP = window.IMP; // 생략 가능
				// IMP.init("imp80753747"); // Example: imp00000000
				IMP.init(process.env.REACT_APP_IMP_UID); // Example: imp00000000

				// console.log("order : ", order);
				
				let mobileCompleteUrl = process.env.REACT_APP_API_URL + "/payment/mobile/complete";
				let param = { // param
					pg: process.env.REACT_APP_PAY_PG,
					pay_method: payMethod,
					merchant_uid: order.ord_no,
					name: order.prd_name,
					amount: order.ord_amt,
					buyer_email: order.mem_email,
					buyer_name: order.mem_name,
					buyer_tel: order.mem_phone,
					m_redirect_url: mobileCompleteUrl,
				};

				// console.log("param : ", param);

				// IMP.request_pay(param, callback) 결제창 호출
				IMP.request_pay(param, rsp => { // callback

					// console.log("rsp : ", rsp);

					if (rsp.success) // 결제 성공 시: 결제 승인 또는 가상계좌 발급에 성공한 경우
					{ 
						// axios로 HTTP 요청
						const sendData = { imp_uid: rsp.imp_uid, merchant_uid: rsp.merchant_uid };
					
						http.post("payment/complete", sendData
						).then((data) => {
							// 서버 결제 API 성공시 로직
							// console.log(data);
							

							switch(data.status) 
							{
								case "vbankIssued":
								  // 가상계좌 발급 시 로직
								  alert("가상계좌가 발급되었습니다. 결제 상세보기를 확인하시고 입금해주시면 처리가 완료됩니다.");
								  window.location.href = "/UserMypage/PayInfo";
								  break;
								case "success":
								  // 결제 성공 시 로직
								  alert("결제가 완료되었습니다. 결제내역을 확인해주세요.");
								  window.location.href = "/UserMypage/PayInfo";
								  break;
							}
						})
					} 
					else 
					{
						alert(`결제에 실패하였습니다. 에러 내용: ${rsp.error_msg}`);
					}
				});
			}

		})
		.catch(e => {
			console.log(e);
		});
	}

	const handleChange = (e) => 
	{
		// console.log(`*****handleChange*****`);
		// console.log(`선택한 값 : ${e.target.value}`);
		setPayMethod(e.target.value);
	};

  return (
    <>
      	{/* Header 분리됨 MainLayout Header에 있음 */}

		  <div className={`${styles.pay_area} ${styles.order_area}`}>
			<div className={styles.pay_top_line_banner}>
				<div className={styles.inner}>
					<span>야미 구매하기</span>
				</div>
			</div>
            <div className={styles.inner}>
                <div className={styles.order_wrap}>
                    <span className={styles.title}>결제 정보</span>
                    <ul>
                        <li>
                            <div className={styles.left_area}>
                                <span>상품명</span>
                            </div>
                            <div className={styles.right_area}>
                                <span>{orderData?.product_name || ""}</span>
                            </div>
                        </li>
                        <li>
                            <div className={styles.left_area}>
                                <span>이용기간</span>
                            </div>
                            <div className={styles.right_area}>
                                <span>{orderData?.period || ""}</span>
                            </div>
                        </li>
                        <li>
                            <div className={styles.left_area}>
                                <span>상품 금액</span>
                            </div>
                            <div className={styles.right_area}>
                                <span className={styles.order_price}>{common.priceToString(orderData?.sell_prc_unit || 0)}</span>
                            </div>
                        </li>
                        <li>
                            <div className={styles.left_area}>
                                <span>학습자(수량)</span>
                            </div>
                            <div className={styles.right_area}>
                                <span>{orderData?.order_count || ""}명</span>
                            </div>
                        </li>
                        <li className={styles.order_total_price_wrap}>
                            <div className={styles.left_area}>
                                <span>총 결제 금액</span>
                            </div>
                            <div className={styles.right_area}>
                                <span className={styles.order_total_price}>{common.priceToString(orderData?.order_amount || 0)}</span>
                            </div>
                        </li>
                        <li className={styles.how_to_pay_wrap}>
                            <div className={styles.left_area}>
                                <span>결제 수단</span>
                            </div>
                            <div className={styles.right_area}>
                                <label className={styles.radio_btn_area}>
                                    <input type="radio" name="how_to_pay" value="card" defaultChecked onChange={(e)=>handleChange(e)}/>
                                    신용카드
                                </label>
                                <label className={styles.radio_btn_area}>
                                    <input type="radio" name="how_to_pay" value="trans" onChange={(e)=>handleChange(e)} />
                                    실시간 계좌이체
                                </label>
                                <label className={styles.radio_btn_area}>
                                    <input type="radio" name="how_to_pay" value="vbank" onChange={(e)=>handleChange(e)} />
                                    가상계좌
                                </label>
                            </div>
                        </li>
                    </ul>
                    <div className={styles.customer_info_title}>
                        <div></div>
                        <p>주문자 정보</p>
                    </div>
                    <ul className={styles.customer_info_wrap}>
                        <li>
                            <div className={styles.left_area}>
                                <span>이름</span>
                            </div>
                            <div className={styles.right_area}>
                                <span>{orderData?.name || ""}</span>
                            </div>
                        </li>
                        <li>
                            <div className={styles.left_area}>
                                <span>휴대폰 번호</span>
                            </div>
                            <div className={styles.right_area}>
                                <span>{orderData?.phone || ""}</span>
                            </div>
                        </li>
                        <li>
                            <div className={styles.left_area}>
                                <span>이메일</span>
                            </div>
                            <div className={styles.right_area}>
                                <span>{orderData?.email || ""}</span>
                            </div>
                        </li>
                    </ul>
                    <div className={styles.btn_area}>
                        <button type="button" onClick={(e) => gotoUrl(e, '/Pay')}>취소</button>
                        <button type="button" onClick={(e) => payOnClick(e)}>결제</button>
                    </div>
                </div>
            </div>
		</div>{/* //pay_area */}
		
		{/* Footer 분리됨 MainLayout Footer에 있음 */}
    </>
  );
}