import React from 'react';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import styles from "../../css/common.module.css";
import Header from './Header';
import Footer from './Footer';
import $ from 'jquery';
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';

const MainLayout = () => {

  const location = useLocation();
  
  // 로그인이 되어 있지 않으면 로그인화면으로~
  const authState = useSelector(state => state.auth);
  // console.log("redux state : ", authState);

  useEffect(() => 
  {
    // 로그인을 하지 않아도 되는 페이지... 로그인 체크~
    // console.log(location.pathname);
    if (location.pathname !== "/" && location.pathname !== "/Price" && location.pathname !== "/Use" && location.pathname !== "/Privacy" && location.pathname !== "/Pay" && location.pathname !== "/Download" && location.pathname !== "/Faq")
    {
      if (!authState.isLoggedIn)
      {
        // console.log(" isLoggedIn check ");
        document.location.href = "/auth/login";
      }
    }

    //페이지 로딩되는 동안은 .pageLoading가 보임
    // $(window).on('load', function()
    // {
    //   $(`.${styles.pageLoading}`).fadeOut(); //로딩객체 제거
    // });

    // var size = 
    // {
    //   width: window.innerWidth || document.body.clientWidth,
    //   height: window.innerHeight || document.body.clientHeight 
    // }

    // alert(size.width);
    // alert(size.height);

	}, [$, location]);

  return (
    <>
    {/* <div className={styles.pageLoading}>
      <div className={styles.loadingImgArea}>
        <div className={`${styles.loadingImgMoveWrap} ${styles.loadingBaloon}`}>
          <div className={styles.loadingImgMove}><img src="/assets/loading.png" alt="loading img"/></div>
        </div>
      </div>
    </div> */}
    <div className={styles.content_wrapper}>
      <Header />
        <Outlet />
      <Footer />
    </div>
    </>
  );
};

export default MainLayout;
