// 인증 관련 액션 타잎
export const LOGIN_SUCCESS_AUTH = "LOGIN_SUCCESS_AUTH";
export const LOGIN_FAIL_AUTH = "LOGIN_FAIL_AUTH";
export const REGISTER_SUCCESS_AUTH = "REGISTER_SUCCESS_AUTH";
export const REGISTER_FAIL_AUTH = "REGISTER_FAIL_AUTH";
export const GROUP_REGISTER_SUCCESS_AUTH = "GROUP_REGISTER_SUCCESS_AUTH";
export const GROUP_REGISTER_FAIL_AUTH = "GROUP_REGISTER_SUCCESS_AUTH";
export const LOGOUT_AUTH = "LOGOUT_AUTH";
export const PASSWORD_CHANGE_AUTH = "PASSWORD_CHANGE_AUTH";
export const LOGIN_RELOAD_AUTH = "LOGIN_RELOAD_AUTH";
export const PASSWORD_UPDATE_AUTH = "PASSWORD_UPDATE_AUTH";

// 사용자 관련 액션 타잎
export const CREATE_USERS = "CREATE_USERS";
export const CREATE_GROUP_USERS = "CREATE_GROUP_USERS";
export const UPDATE_USERS = "UPDATE_USERS";
export const UPDATE_GROUP_USERS = "UPDATE_GROUP_USERS";
export const DELETE_USERS = "DELETE_USERS";
export const LIST_USERS = "LIST_USERS";
export const ME_USERS = "ME_USERS";
export const PASSWORD_CHANGE_USERS = "PASSWORD_CHANGE_USERS";
export const SELECT_USERS = "SELECT_USERS";
export const SELECT_GROUP_USERS = "SELECT_GROUP_USERS";
export const ACTIVE_USERS = "ACTIVE_USERS";
export const MISSION_TOTAL_USERS = "MISSION_TOTAL_USERS";
export const MISSION_PROGRESS_USERS = "MISSION_PROGRESS_USERS";
export const KNOWLEDGE_ANSWER_USERS = "KNOWLEDGE_ANSWER_USERS";
export const TOTAL_HUMANABILITY_USERS = "TOTAL_HUMANABILITY_USERS";
export const HUMANABILITY_USERS = "HUMANABILITY_USERS";
export const IDCHECK_USERS = "IDCHECK_USERS";   // 아이디 중복확인

// 카테고리 관련 액션 타잎
export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const LIST_CATEGORY = "LIST_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const MODIFY_CATEGORY = "MODIFY_CATEGORY";
export const SELECT_CATEGORY = "SELECT_CATEGORY";

// 제품 관련 액션 타잎
export const CREATE_PRODUCT = "CREATE_PRODUCT";
export const LIST_PRODUCT = "LIST_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const MODIFY_PRODUCT = "MODIFY_PRODUCT";
export const SELECT_PRODUCT = "SELECT_PRODUCT";

// 주문 관련 액션 타잎
export const CREATE_ORDER = "CREATE_ORDER";
export const LIST_ORDER = "LIST_ORDER";
export const CANCEL_ORDER = "CANCEL_ORDER";
export const MODIFY_ORDER = "MODIFY_ORDER";
export const SELECT_ORDER = "SELECT_ORDER";
export const USE_ORDER = "USE_ORDER";

// 결제 관련 액션 타잎
export const CREATE_PAYMENT = "CREATE_PAYMENT";
export const LIST_PAYMENT = "LIST_PAYMENT";
export const CANCEL_PAYMENT = "CANCEL_PAYMENT";
export const VBANK_CANCEL_PAYMENT = "VBANK_CANCEL_PAYMENT";
export const SELECT_PAYMENT = "SELECT_PAYMENT";

// 환불 관련 액션 타잎
export const LIST_REFUND = "LIST_REFUND";

// 학습자 관련 액션 타잎
export const CREATE_STUDENT = "CREATE_STUDENT";
export const UPDATE_STUDENT = "UPDATE_STUDENT";
export const DELETE_STUDENT = "DELETE_STUDENT";
export const LIST_STUDENT = "LIST_STUDENT";
export const SELECT_STUDENT = "SELECT_STUDENT";
export const COUNT_STUDENT = "COUNT_STUDENT";       // 현재 결제가 되고 환불이 없는 실제 사용자의 갯수
export const IDCHECK_STUDENT = "IDCHECK_STUDENT";   // 아이디 중복확인
export const ADD_STUDENT = "ADD_STUDENT";
export const PAYINFO_STUDENT = "PAYINFO_STUDENT";


// 패키지 관련 액션 타잎
export const LIST_PACKAGE = "LIST_PACKAGE";
export const USE_STUDENT_PACKAGE = "USE_STUDENT_PACKAGE";
export const USE_NOT_STUDENT_PACKAGE = "USE_NOT_STUDENT_PACKAGE";
export const USE_UPDATE_PACKAGE = "USE_UPDATE_PACKAGE";
export const STUDENT_INFO_PACKAGE = "STUDENT_INFO_PACKAGE";
export const STUDENT_LIST_PACKAGE = "STUDENT_LIST_PACKAGE";


// 게시판 관련 액션 타임
export const CREATE_BBS = "CREATE_BBS";
export const CREATE_REPLY_BBS = "CREATE_REPLY_BBS";
export const LIST_BBS = "LIST_BBS";
export const DELETE_BBS = "DELETE_BBS";
export const MODIFY_BBS = "MODIFY_BBS";
export const SELECT_BBS = "SELECT_BBS";

// 댓글 관련 액션 타잎
export const CREATE_COMMENT = "CREATE_COMMENT";
export const LIST_COMMENT = "LIST_COMMENT";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const MODIFY_COMMENT = "MODIFY_COMMENT";

// 게시판 관련 액션 타잎
export const CREATE_NOTICE_BBS = "CREATE_NOTICE_BBS";
export const LIST_NOTICE_BBS = "LIST_NOTICE_BBS";
export const DELETE_NOTICE_BBS = "DELETE_NOTICE_BBS";
export const MODIFY_NOTICE_BBS = "MODIFY_NOTICE_BBS";
export const SELECT_NOTICE_BBS = "SELECT_NOTICE_BBS";

// 미션 맵 카테고리 관련 액션 타잎
export const CREATE_MISSION_CATEGORY = "CREATE_MISSION_CATEGORY";
export const LIST_MISSION_CATEGORY = "LIST_MISSION_CATEGORY";
export const DELETE_MISSION_CATEGORY = "DELETE_MISSION_CATEGORY";
export const MODIFY_MISSION_CATEGORY = "MODIFY_MISSION_CATEGORY";
export const SELECT_MISSION_CATEGORY = "SELECT_MISSION_CATEGORY";

// 미션 맵 관련 액션 타잎
export const CREATE_MISSION = "CREATE_MISSION";
export const LIST_MISSION = "LIST_MISSION";
export const DELETE_MISSION = "DELETE_MISSION";
export const MODIFY_MISSION = "MODIFY_MISSION";
export const SELECT_MISSION = "SELECT_MISSION";
export const ORDER_CHANGE_MISSION = "ORDER_CHANGE_MISSION";

// 제품에 설정된 미션맵 액션 타잎
export const REGISTER_PRODUCT_MISSION = "REGISTER_PRODUCT_MISSION";
export const USE_PRODUCT_MISSION = "USE_PRODUCT_MISSION";
export const USENOT_PRODUCT_MISSION = "USENOT_PRODUCT_MISSION";

// 지식은행 카테고리 관련 액션 타잎
export const CREATE_KNOWLEDGE_CATEGORY = "CREATE_KNOWLEDGE_CATEGORY";
export const LIST_KNOWLEDGE_CATEGORY = "LIST_KNOWLEDGE_CATEGORY";
export const DELETE_KNOWLEDGE_CATEGORY = "DELETE_KNOWLEDGE_CATEGORY";
export const MODIFY_KNOWLEDGE_CATEGORY = "MODIFY_KNOWLEDGE_CATEGORY";
export const SELECT_KNOWLEDGE_CATEGORY = "SELECT_KNOWLEDGE_CATEGORY";

// 지식은행 관련 액션 타잎
export const CREATE_KNOWLEDGE = "CREATE_KNOWLEDGE";
export const LIST_KNOWLEDGE = "LIST_KNOWLEDGE";
export const DELETE_KNOWLEDGE = "DELETE_KNOWLEDGE";
export const MODIFY_KNOWLEDGE = "MODIFY_KNOWLEDGE";
export const SELECT_KNOWLEDGE = "SELECT_KNOWLEDGE";

