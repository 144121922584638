import {
  CREATE_ORDER,
  LIST_ORDER,
  CANCEL_ORDER,
  MODIFY_ORDER,
  SELECT_ORDER,
  USE_ORDER,
} from "../actions/types";

const initialState = [];

const orderReducer = (order = initialState, action) => {
  const { type, payload } = action;

  // console.log("orderReducer Call!");
  // console.log("type : ", type);
  // console.log("payload : ", payload);

  switch (type) {
    
    case CREATE_ORDER:
      return [];

    case LIST_ORDER:
      return payload;

    case SELECT_ORDER:
      return payload;

    case MODIFY_ORDER:
      return order.map((order) => {
        if (order.ord_idx === payload.ord_idx) {
          return {
            ...order,
            ...payload,
          };
        } else {
          return order;
        }
      });

    case CANCEL_ORDER:
      return order.filter(({ ord_idx }) => ord_idx !== payload.ord_idx);

    case USE_ORDER:
      return order;

    default:
      return order;
  }
};

export default orderReducer;