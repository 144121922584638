import { combineReducers } from "redux";
import auth from "./auth";
import users from "./users";
import category from "./category";
import product from "./product";
import order from "./order";
import payment from "./payment";
import refund from "./refund";
import student from "./student";
import myPackage from "./package";
import bbs from "./bbs";
import comment from "./comment";
import notice from "./notice"
import knowledge_category from "./knowledge_category";
import knowledge from "./knowledge";
import mission_category from "./mission_category";
import mission from "./mission";
import product_mission from "./product_mission";

export default combineReducers({
  auth,
  users,
  category,
  product,
  order,
  payment,
  refund,
  student,
  myPackage,
  bbs,
  comment,
  notice,
  knowledge_category,
  knowledge,
  mission_category,
  mission,
  product_mission,
});
