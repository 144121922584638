import * as React from 'react';
import { useEffect } from 'react';
import styles from "../css/common.module.css";
import $ from 'jquery';

export default function Use() {

	useEffect(() => {

		// 여기에서 jQuery를 초기화 합니다.
		$(document).ready(function(){

		});

	}, [$]);

  	return (
    <>
		<div id={styles.content}>
			<div className={styles.inner}>
				<span className={styles.index_title}>이용약관</span>
				<div className={styles.wrap}>
					<div className={`${styles.privacy_area} ${styles.area1}`}>
						<span className={styles.tit}>제 1 장 총칙</span>
						<br/><span className={styles.sub_tit}>제 1 조 (목적)</span>
						<p>
						이 약관은 (주) 위클러버(이하 “회사”)가 제공하는 교육콘텐츠 및 이에 부수된 제반 서비스의 이용과 관련하여 회사와 회원 간의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
						</p>
						<span className={styles.sub_tit}>제 2 조 (용어의 정의)</span>
						<p>
						1. 이 약관에서 사용하는 용어의 정의는 다음과 같습니다
						<br/><br/>* “회사”라 함은 온라인 또는 모바일 기기를 통하여 서비스를 제공하는 사업자를 의미합니다.
						<br/>* “회원”이란 이 약관에 따라 이용계약을 체결하고, 회사가 제공하는 서비스를 이용하는 자를 의미합니다.
						<br/>* “교육서비스”라 함은 회사가 회원에게 제공하는 교육콘텐츠 및 이에 부수된 제반 서비스를 의미합니다.
						<br/>* “모바일 기기”란 콘텐츠를 다운로드 받거나 설치하여 사용할 수 있는 기기로서, 휴대폰, 스마트폰, 휴대정보단말기(PDA), 태블릿과 pc, 노트북 등도 포함합니다.
						<br/>* “모바일 교육서비스”란 회사가 회원에게 모바일 기기를 통하여 제공하는 교육콘텐츠 및 이에 부수된 제반서비스를 의미합니다.
						<br/>* “콘텐츠”란 회사가 교육서비스 제공과 관련하여 디지털 방식으로 제작한 유료 또는 무료의 내용물일체 (교육콘텐츠 및 네트워크 서비스, 애플리케이션 등)를 의미합니다.
						<br/>* “계정(ID)”이라 함은 회원의 식별과 교육서비스 이용을 위하여 회원이 선정하고 회사가 부여하는 문자, 숫자 또는 특수문자의 조합을 의미합니다.
						<br/>* “계정정보”란 회원의 계정, 비밀번호, 성명 등 회원이 회사에 제공한 일반정보, 기기정보, 이용요금 결제 정보 등을 통칭합니다.
						<br/>* “캐릭터”라 함은 회원이 교육서비스의 이용을 위하여 교육콘텐츠 내에서 회사가 제공하는 방식에 따라 선정하고 조종하는 데이터를 의미합니다.
						<br/>* “비밀번호”라 함은 회원이 부여받은 계정과 일치되는 회원임을 확인하고 회원의 정보 및 권익보호를 위해 회원 자신이 선정하여 비밀로 관리하는 문자, 숫자 또는 특수문자의 조합을 의미합니다.
						<br/>* “게시물”이라 함은 회원이 서비스를 이용함에 있어 회원이 게시한 문자, 문서, 그림, 음성, 영상 또는 이들의 조합으로 이루어진 모든 정보를 말합니다.
						<br/>이 약관에서 사용하는 용어의 정의는 본 조 제 1 항에서 정하는 것을 제외하고는 관계법령 및 서비스별 정책에서 정하는 바에 의하며, 이에 정하지 아니한 것은 일반적인 상 관례에 따릅니다.
						<br/>* 본 이용약관에서 "*" 표시는 "호" 와 동일한 의미를 뜻합니다.
						</p>
						<span className={styles.sub_tit}>제 3 조 (회사정보 등의 제공)</span>
						<p>
						회사는 다음 각 호의 사항을 회원이 알아보기 쉽도록 교육서비스 내에 표시합니다. 다만, 개인정보처리방침과 약관은 회원이 연결화면을 통하여 볼 수 있도록 할 수 있습니다

						<br/><br/>* 상호 및 대표자의 성명
						<br/>* 영업소 소재지 주소(회원의 불만을 처리할 수 있는 곳의 주소를 포함한다)
						<br/>* 전화번호, 전자우편주소
						<br/>* 사업자 등록번호
						<br/>* 통신판매업 신고번호
						<br/>* 개인정보처리방침
						<br/>* 서비스 이용약관						
						</p>
						<span className={styles.sub_tit}>제 4 조 (약관의 효력 및 변경)</span>
						<p>
						1. 회사는 이 약관의 내용을 회원이 알 수 있도록 교육서비스 내 또는 그 연결화면, 서비스 홈페이지(yamecoding.com)에 게시합니다.
						<br/><br/>2. 회사는 회원이 회사와 이 약관의 내용에 관하여 질의 및 응답을 할 수 있도록 조치를 취합니다.
						<br/><br/>3. 회사는 교육서비스를 이용하고자 하는 자(이하 “이용자”라 한다)가 약관의 내용을 쉽게 알 수 있도록 작성하고 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중 청약철회, 과오납금의 환급, 계약 해제ㆍ해지, 회사의 면책사항 및 회원에 대한 피해보상 등과 같은 중요한 내용을 회원이 쉽게 이해할 수 있도록 이용자의 동의를 얻도록 합니다.
						<br/><br/>4. 회사는 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」, 「게임산업진흥에 관한 법률」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「콘텐츠산업 진흥법」 등 관련 법령에 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
						<br/><br/>5. 회사가 약관을 개정할 경우에는 적용일자 및 개정내용, 개정사유 등을 명시하여 최소한 그 적용일 7 일 이전부터 교육서비스 내 또는 그 연결화면에 게시하여 회원에게 공지합니다. 다만, 변경된 내용이 회원에게 불리하거나 중대한 사항의 변경인 경우에는 그 적용일 30 일 이전까지 본문과 같은 방법으로 공지하고 제 34 조 제 1 항의 방법으로 회원에게 통지합니다. 이 경우 개정 전 내용과 개정 후 내용을 명확하게 비교하여 회원이 알기 쉽도록 표시합니다.
						<br/><br/>6. 회사가 약관을 개정할 경우 개정약관 공지 후 개정약관의 적용에 대한 회원의 동의 여부를 확인합니다. 회사는 제 2 항의 공지 또는 통지를 할 경우 회원이 개정약관에 대해 동의 또는 거부의 의사표시를 하지 않으면 동의한 것으로 볼 수 있다는 내용도 함께 공지 또는 통지를 하며, 회원이 이 약관 시행일까지 거부의 의사표시를 하지 않는다면 개정약관에 동의한 것으로 볼 수 있습니다.
						<br/><br/>7. 회원이 개정약관의 적용에 동의하지 않는 경우 회사 또는 회원은 교육서비스 이용계약을 해지할 수 있습니다.						
						</p>
						<span className={styles.sub_tit}>제 5 조 (약관 외 준칙)</span>
						<p>
						이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 「전자상거래 등에서의 소비자보호에 관한 법률」,「약관의 규제에 관한 법률」, 「게임산업진흥에 관한 법률」,「정보통신망이용촉진 및 정보보호 등에 관한 법률」,「콘텐츠산업진흥법」 등 관련 법령 또는 상 관례에 따릅니다.
						</p>
						<span className={styles.sub_tit}>제 6 조 (운영정책)</span>
						<p>
						1. 약관을 적용하기 위하여 필요한 사항과 회원의 권익을 보호하고 교육콘텐츠 내 질서를 유지하기 위하여 회사는 약관에서 구체적 범위를 정하여 위임한 사항을 교육서비스 운영정책(이하“운영정책”이라 합니다)으로 정할 수 있습니다.
						<br/><br/>2. 회사는 운영정책의 내용을 회원이 알 수 있도록 교육서비스 내 또는 그 연결화면, 서비스 홈페이지(yamecoding.com)에 게시합니다.
						<br/><br/>3. 운영정책을 개정하는 경우 제 4 조 제 5 항의 절차에 따릅니다. 단, 운영정책 개정이 다음 각 호의 어느 하나에 해당하는 경우에는 제 4 조 제 2 항의 방법으로 사전에 공지합니다.
						<br/><br/>* 약관에서 구체적으로 범위를 정하여 위임한 사항을 개정하는 경우
						<br/>* 회원의 권리ㆍ의무와 관련 없는 사항을 개정하는 경우
						<br/>* 운영정책의 내용이 약관에서 정한 내용과 근본적으로 다르지 않고 회원이 예측가능한 범위 내에서 운영정책을 개정하는 경우						
						</p>
					</div>
					<div className={`${styles.privacy_area} ${styles.area2}`}>
						<span className={styles.tit}>제 2 장 이용계약의 체결</span>
						<br/><span className={styles.sub_tit}>제 7 조 (이용계약의 체결 및 적용)</span>
						<p>
						1. 회사가 제공하는 교육서비스를 이용하고자 하는 자는 회사가 애플리케이션 초기 화면이나 서비스 홈페이지(yamecoding.com)에서 제공하는 이용신청서를 작성하는 방법으로 이용신청을 하여야 합니다.
						<br/><br/>2. 이용자는 이용신청시 회사에서 요구하는 제반 정보를 제공하여야 합니다.
						<br/><br/>3. 이용자는 제 1 항의 이용 신청 시 관계법령에 따라 본인의 실제 정보를 기재하여야 합니다. 실명 또는 식별정보를 허위로 기재하거나 타인의 명의를 도용한 경우 이 약관에 의한 회원의 권리를 주장할 수 없고, 회사는 환급없이 이용계약을 취소하거나 해지할 수 있습니다.
						<br/><br/>4. 회사는 회사정책 및 관련 법령에 따라 회원의 서비스 이용 범위, 이용시간 등에 차등을 둘 수 있습니다. 회사는 선택적 서비스의 제공이나 서비스 범위 조정을 위해 필요한 경우 추가 정보를 요청할 수 있습니다.
						<br/><br/>5. 회사는 회사가 이용자에게 요구하는 정보에 대해 이용자가 실제 정보를 정확히 기재하여 이용신청을 한 경우에 상당한 이유가 없는 한 이용신청을 승낙합니다.
						<br/><br/>6. 회사는 다음 각 호의 어느 하나에 해당하는 이용신청에 대해서는 승낙을 하지 않거나 사후에 승낙을 취소할 수 있습니다.
						<br/><br/>* 제 7 조에 위반하여 이용신청을 하는 경우
						<br/>* 회사가 서비스를 제공하지 않은 국가에서 비정상적이거나 우회적인 방법을 통해 서비스를 이용하는 경우
						<br/>* 「게임산업진흥에 관한 법률」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 및 관련 법령에서 금지하는 행위를 할 목적으로 신청하는 경우
						<br/>* 부정한 용도 또는 영리를 추구할 목적으로 교육서비스를 이용하고자 하는 경우
						<br/>* 그 밖에 위 각 호에 준하는 사유로서 승낙이 부적절하다고 판단되는 경우
						<br/><br/>7. 회사는 다음 각 호의 어느 하나에 해당하는 경우에는 그 사유가 해소될 때까지 승낙을 유보할 수 있습니다
						<br/><br/>* 회사의 설비에 여유가 없거나 기술적 장애가 있는 경우
						<br/>* 서비스 상의 장애 또는 서비스 이용요금 결제수단의 장애가 발생한 경우
						<br/>* 그 밖에 위 각 호에 준하는 사유로서 이용신청의 승낙이 곤란한 경우						
						</p>
						<span className={styles.sub_tit}>제 8 조 (회원 계정(ID) 및 비밀번호)</span>
						<p>
						1. 회사는 회원에 대하여 회원의 정보 보호, 서비스 이용안내 등의 편의를 위해 회원이 선정한 일정한 문자, 숫자 또는 특수문자의 조합을 계정으로 부여합니다.
						<br/><br/>2. 회사는 계정정보를 통하여 당해 회원의 서비스 이용가능 여부 등의 제반 회원 관리업무를 수행합니다.
						<br/><br/>3. 회원은 자신의 계정정보를 선량한 관리자로서의 주의 의무를 다하여 관리하여야 합니다. 회원이 본인의 계정정보를 소홀히 관리하거나 제 3 자에게 이용을 승낙함으로써 발생하는 손해에 대하여는 회원에게 책임이 있습니다.
						<br/><br/>4. 비밀번호의 관리책임은 회원에게 있으며, 회원이 원하는 경우에는 보안상의 이유 등으로 언제든지 변경이 가능합니다.
						<br/><br/>5. 회원은 정기적으로 비밀번호를 변경하여야 합니다
						</p>
						<span className={styles.sub_tit}>제 9 조 (회원 정보의 제공 및 변경))</span>
						<p>
						1. 회원은 이 약관에 의하여 회사에 정보를 제공하여야 하는 경우에는 진실된 정보를 제공하여야 하며, 허위정보 제공으로 인해 발생한 불이익에 대해서는 보호받지 못합니다.
						<br/><br/>2. 회원은 개인정보관리화면을 통하여 언제든지 자신의 개인정보를 열람하고 수정할 수 있습니다. 다만, 서비스 관리를 위해 필요한 일부 정보는 수정이 제한될 수 있습니다.
						<br/><br/>3. 회원은 회원가입 신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 기타 방법으로 회사에 대하여 그 변경사항을 알려야 합니다.
						<br/><br/>4. 제 3 항의 변경사항을 회사에 알리지 않아 발생한 불이익에 대하여 회사는 책임을 지지 않습니다.						
						</p>
					</div>
					<div className={`${styles.privacy_area} ${styles.area3}`}>
						<span className={styles.tit}>제 3 장 개인정보 관리</span>
						<br/><span className={styles.sub_tit}>제 10 조 (개인정보의 보호 및 사용)</span>
						<p>
						1. 회사는 관련 법령이 정하는 바에 따라 계정정보를 포함한 회원의 개인정보를 보호하기 위해 노력합니다. 회원 개인정보의 보호 및 사용에 대해서는 관련 법령 및 회사가 별도로 고지하는 개인정보처리방침이 적용됩니다.
						<br/><br/>2. 서비스의 일부로 제공되는 개별 서비스를 제외한 것으로서 홈페이지 및 교육서비스별 웹사이트에서 단순히 링크된 제 3 자 제공의 서비스에 대하여는 회사의 개인정보처리방침이 적용되지 않습니다.
						<br/><br/>3. 회사는 관련 법령에 의해 관련 국가기관 등의 요청이 있는 경우를 제외하고는 회원의 개인정보를 본인의 동의 없이 타인에게 제공하지 않습니다.
						<br/><br/>4. 회사는 회원의 귀책사유로 인하여 노출된 회원의 계정정보를 포함한 모든 정보에 대해서 일체의 책임을 지지 않습니다.						
						</p>
					</div>
					<div className={`${styles.privacy_area} ${styles.area4}`}>
						<span className={styles.tit}>제 4 장 이용계약 당사자의 의무</span>
						<br/><span className={styles.sub_tit}>제 11 조 (회사의 의무)</span>
						<p>
						1. 회사는 관련 법령, 이 약관에서 정하는 권리의 행사 및 의무의 이행을 신의에 따라 성실하게 준수합니다.
						<br/><br/>2. 회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해 보안시스템을 갖추어야 하며 개인정보처리방침을 공시하고 준수합니다. 회사는 이 약관 및 개인정보처리방침에서 정한 경우를 제외하고는 회원의 개인정보가 제 3 자에게 공개 또는 제공되지 않도록 합니다.
						<br/><br/>3. 회사는 계속적이고 안정적인 서비스의 제공을 위하여 서비스 개선을 하던 중 설비에 장애가 생기거나 데이터 등이 멸실․훼손된 때에는 천재지변, 비상사태, 현재의 기술로는 해결이 불가능한 장애나 결함 등 부득이한 사유가 없는 한 지체 없이 이를 수리 또는 복구하도록 최선의 노력을 다합니다.						
						</p>
						<span className={styles.sub_tit}>제 12 조 (회원의 의무)</span>
						<p>
						1. 회원은 회사에서 제공하는 서비스의 이용과 관련하여 다음 각 호에 해당하는 행위를 해서는 안 됩니다.
						<br/><br/>* 이용신청 또는 회원 정보 변경 시 허위내용 기재
						<br/>* 회사가 게시한 정보의 변경
						<br/>* 타인의 신용카드⋅유/무선 전화⋅은행 계좌 등을 도용하여 유료 콘텐츠를 구매하는 행위, 다른 회원의 ID 및 비밀번호를 부정사용하는 행위 등 타인의 정보(개인정보와 결제정보 포함) 도용
						<br/>* 다른 회원의 개인정보를 무단으로 수집⋅저장⋅게시 또는 유포하는 행위
						<br/>* 자신의 개인정보를 다른 사람에게 무단으로 게시 또는 유포하는 행위
						<br/>* 회사가 제공 또는 승인하지 아니한 컴퓨터 프로그램이나 기기 또는 장치를 제작, 배포, 이용, 광고하는 행위
						<br/>* 법령에 의하여 전송 또는 게시가 금지된 정보(컴퓨터 프로그램)나 컴퓨터 소프트웨어⋅하드웨어 또는 전기통신장비의 정상적인 작동을 방해⋅파괴할 목적으로 고안된 바이러스⋅컴퓨터 코드⋅파일⋅프로그램 등을 고의로 전송⋅게시⋅유포 또는 사용하는 행위
						<br/>* 회사로부터 특별한 권리를 부여받지 않고 교육서비스를 변경하거나, 교육서비스에 다른 프로그램을 추가⋅삽입하거나, 서버를 해킹⋅역설계하거나, 소스 코드나 데이터를 유출⋅변경하거나, 별도의 서버를 구축하거나, 웹사이트의 일부분을 임의로 변경⋅도용하는 행위
						<br/>* 서비스를 사행성이 있는 방법 또는 기타 불건전한 방법으로 이용하는 행위
						<br/>* 회사의 동의 없이 교육서비스를 영리, 영업, 광고, 홍보, 정치활동, 선거운동 등 본래의 용도 이외의 용도로 이용하는 행위
						<br/>* 회사의 서비스를 이용하여 얻은 정보를 무단으로 복제∙유통∙조장하거나 상업적으로 이용하는 행위, 알려지거나 알려지지 않은 버그를 악용하여 서비스를 이용하는 행위
						<br/>* 타인을 기망하여 이득을 취하는 행위, 회사의 서비스의 이용과 관련하여 타인에게 피해를 입히는 행위
						<br/>* 회사나 타인의 지식재산권 또는 초상권을 침해하는 행위, 타인의 명예를 훼손하거나 손해를 가하는 행위
						<br/>* 그 밖에 관련 법령에 위반되거나 선량한 풍속 기타 사회통념에 반하는 행위
						<br/>* 1 호 내지 14 호의 행위를 유도하거나 광고하는 행위
						<br/><br/>2. 회원은 이 약관의 규정, 이용안내 및 교육서비스와 관련하여 공지한 주의사항, 회사가 통지하는 사항 등을 확인하고 준수할 의무가 있습니다.
						<br/><br/>3. 회원의 계정 및 pc, 모바일 기기에 관한 관리 책임은 회원에게 있으며, 이를 타인이 이용하도록 하게 하여서는 안 됩니다. 회원의 계정 및 pc, 모바일 기기의 관리 부실이나 타인에게 이용을 승낙함으로 인해 발생하는 손해에 대해서 회사는 책임을 지지 않습니다.
						<br/><br/>4. 부주의로 인하여 발생하는 손해에 대해 회사는 책임지지 않습니다
						<br/><br/>5. 회사는 1, 2 항 및 다음 각 호의 행위의 구체적인 내용을 운영정책에서 정할 수 있으며, 회원은 이를 따라야 합니다
						<br/><br/>* 게시판이용 및 서비스이용 방법
						<br/>* 기타 회원의 교육서비스 이용에 대한 본질적 권리를 침해하지 않는 범위 내에서 회사가 교육서비스 운영상 필요하다고 인정되는 사항						
						</p>
					</div>
					<div className={`${styles.privacy_area} ${styles.area5}`}>
						<span className={styles.tit}>제 5 장 서비스 이용 및 이용제한</span>
						<br/><span className={styles.sub_tit}>제 13 조 (서비스의 제공 및 정지)</span>
						<p>
						1. 회사는 제 5 조의 규정에 따라 이용계약이 완료된 회원에게 그 즉시 서비스를 이용할 수 있도록 합니다. 다만, 일부 서비스의 경우 회사의 필요에 따라 지정된 일자부터 서비스를 개시할 수 있습니다.
						<br/><br/>2. 회사는 회원에게 교육서비스를 제공할 때 이 약관에 정하고 있는 서비스를 포함하여 기타 부가적인 서비스를 함께 제공할 수 있습니다.
						<br/><br/>3. 회사는 회원의 등급을 구분하고 이용시간, 이용횟수, 제공 서비스의 범위 등을 세분화하여 이용에 차등을 둘 수 있습니다.
						<br/><br/>4. 교육서비스는 회사의 영업방침에 따라 정해진 시간 동안 제공합니다. 회사는 교육서비스 제공시간을 애플리케이션 이용안내 화면이나 서비스 홈페이지(yamecoding.com)에 적절한 방법으로 안내합니다.
						<br/><br/>5. 제 4 항에도 불구하고 회사는 다음 각 호의 경우에는 일정한 시간 동안 교육서비스가 제공하지 아니할 수 있으며, 해당 시간 동안 회사는 교육서비스를 제공할 의무가 없습니다
						<br/><br/>* 컴퓨터 등 정보통신설비의 보수점검, 교체, 정기점검 또는 게임 내용이나 교육서비스의 수정을 위하여 필요한 경우
						<br/>* 해킹 등의 전자적 침해사고, 통신사고, 회원들의 비정상적인 게임 이용행태, 미처 예상하지 못한 교육서비스의 불안정성에 대응하기 위하여 필요한 경우
						<br/>* 관련 법령에서 특정 시간 또는 방법으로 교육서비스 제공을 금지하는 경우
						<br/>* 천재지변, 비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 교육서비스 제공이 불가능할 경우
						<br/><br/>6. 회사는 제 5 항 제 1 호의 경우, 매주 또는 격주 단위로 일정 시간을 정하여 교육서비스를 중지할 수 있습니다. 이 경우 회사는 최소한 24 시간 전에 그 사실을 회원에게 애플리케이션 초기화면이나 서비스 홈페이지(yamecoding.com)에 고지합니다.
						<br/><br/>7. 제 5 항 제 2 호의 경우, 회사는 사전 고지 없이 교육서비스를 일시 중지할 수 있습니다. 회사는 이러한 경우 그 사실을 애플리케이션 초기화면이나 서비스 홈페이지(yamecoding.com)에 사후 고지할 수 있습니다.
						<br/><br/>8. 회사는 회사가 제공하는 무료서비스 이용과 관련하여 회원에게 발생한 어떠한 손해에 대해서도 책임을 지지 않습니다. 다만, 회사의 고의 또는 중대한 과실로 인하여 발생한 손해의 경우는 제외합니다.
						<br/><br/>9. 제 5 항 제 3 호 내지 제 4 호의 경우에 회사는 기술상ㆍ운영상 필요에 의해 교육서비스 전부를 중단할 수 있으며, 30 일전에 홈페이지에 이를 공지하고 교육서비스의 제공을 중단할 수 있습니다. 사전에 통지할 수 없는 부득이한 사정이 있는 경우는 사후에 통지를 할 수 있습니다.
						<br/><br/>10. 회사가 제 10 항에 따라 교육서비스를 종료하는 경우 회원은 무료서비스 및 사용 기간이 남아 있지 않은 유료서비스ㆍ계속적 유료 이용계약ㆍ기간제 유료아이템에 대하여 손해배상을 청구할 수 없습니다. 사용 기간에 제한이 없는 유료아이템은 서비스 종료일까지를 해당 유료아이템의 사용 기간으로 봅니다.
						<br/><br/>11. 다운로드하여 설치한 애플리케이션 또는 네트워크를 통해 이용하는 서비스의 경우에는 모바일 기기 또는 이동통신사의 특성에 맞도록 제공됩니다. 모바일 기기의 변경․번호 변경 또는 해외 로밍의 경우에는 콘텐츠의 전부 또는 일부의 이용이 불가능할 수 있으며, 이 경우 회사는 책임을 지지 않습니다.
						<br/><br/>12. 다운로드하여 설치한 애플리케이션 또는 네트워크를 통해 이용하는 서비스의 경우에는 백그라운드 작업이 진행될 수 있습니다. 이 경우 모바일 기기 또는 이동통신사의 특성에 맞도록 추가요금이 발생할 수 있으며 이와 관련하여 회사는 책임을 지지 않습니다.						
						</p>
						<span className={styles.sub_tit}>제 14 조 (서비스의 변경 및 중단)</span>
						<p>
						1. 회원은 회사가 제공하는 교육서비스를 이 약관, 운영정책 및 회사가 설정한 규칙에 따라 이용할 수 있습니다.
						<br/><br/>2. 회사는 원활한 교육서비스 제공을 위해 운영상 또는 기술상의 필요에 따라 서비스를 변경할 수 있으며, 변경 전에 해당 내용을 교육서비스 내에 공지합니다. 다만, 버그․오류 등의 수정이나 긴급 업데이트 등 부득이하게 변경할 필요가 있는 경우, 중대한 변경에 해당하지 않는 경우, 서버기기결함, 긴급 보안문제 등 부득이한 사정이 있는 경우에는 사후에 공지할 수 있습니다.
						<br/><br/>3. 영업양도․분할․합병 등에 따른 영업의 폐지, 게임제공의 계약만료, 당해 교육서비스의 현저한 수익 악화 등 경영상의 중대한 사유로 인해 교육서비스를 지속하기 어려운 경우, 기술상 운영상 필요한 경우 회사는 서비스 전부를 중단할 수 있습니다. 이 경우 중단일자 30 일 이전까지 중단일자․중단사유․보상조건 등을 게임 초기화면 또는 그 연결화면을 통해 공지하고 제 34 조 제 1 항의 방법으로 회원에게 통지합니다.
						<br/><br/>4. 회사가 제 5 항에 따라 교육서비스를 종료하는 경우 회원은 무료서비스 및 사용 기간이 남아 있지 않은 유료서비스ㆍ계속적 유료 이용계약ㆍ기간제 유료아이템에 대하여 손해배상을 청구할 수 없습니다. 사용 기간에 제한이 없는 유료아이템은 서비스 종료일까지를 해당 유료아이템의 사용 기간으로 봅니다						
						</p>
						<span className={styles.sub_tit}>제 15 조 (서비스의 변경 및 중단)</span>
						<p>
						1. 회사는 서비스의 원활하고 안정적인 운영 및 서비스 품질의 개선을 위하여 회원의 개인정보를 제외한 회원 PC 등 단말기 설정 및 사양 정보, 실행중인 프로그램 정보, 회원의 모바일 기기 정보(설정, 사양, 운영체제, 버전 등)를 수집 ‧ 활용할 수 있습니다.
						<br/><br/>2. 회사는 서비스 개선 및 회원 대상 서비스 소개 등을 위한 목적으로 회원에게 추가정보를 요청할 수 있습니다. 이 요청에 대해 회원은 승낙하거나 거절할 수 있으며, 회사가 이 요청을 할 경우에는 회원이 이 요청을 거절할 수 있다는 뜻을 함께 고지합니다.						
						</p>
						<span className={styles.sub_tit}>제 16 조 (광고의 제공)</span>
						<p>
						1. 회사는 서비스의 운영과 관련하여 교육서비스 내에 광고를 게재할 수 있습니다. 또한 수신에 동의한 회원에 한하여 전자우편, 문자서비스(LMS/SMS), 푸시메시지(Push Notification) 등의 방법으로 광고성 정보를 전송할 수 있습니다. 이 경우 회원은 언제든지 수신을 거절할 수 있으며, 회사는 회원의 수신 거절 시 광고성 정보를 발송하지 아니합니다.
						<br/><br/>2. 회사가 제공하는 서비스 중의 배너 또는 링크 등을 통해 타인이 제공하는 광고나 서비스에 연결될 수 있습니다.
						<br/><br/>3. 제 2 항에 따라 타인이 제공하는 광고나 서비스에 연결될 경우 해당 영역에서 제공하는 서비스는 회사의 서비스 영역이 아니므로 회사가 신뢰성, 안정성 등을 보장하지 않으며, 그로 인한 회원의 손해에 대하여도 회사는 책임을 지지 않습니다. 다만, 회사가 고의 또는 중과실로 손해의 발생을 용이하게 하거나 손해 방지를 위한 조치를 취하지 아니한 경우에는 그러하지 아니합니다.						
						</p>
						<span className={styles.sub_tit}>제 17 조 (저작권 등의 귀속)</span>
						<p>
						1. 교육서비스 내 회사가 제작한 콘텐츠에 대한 저작권 기타 지식재산권은 회사의 소유이며, 회사는 회원에게 이를 교육서비스와 관련하여 회사가 정한 조건 아래에서 이용할 수 있는 권한만을 부여합니다.
						<br/><br/>2. 회원은 회사가 제공하는 교육서비스를 이용함으로써 얻은 정보 중 회사 또는 제공업체에 지식재산권이 귀속된 정보를 회사 또는 제공업체의 사전승낙 없이 아래 각 호의 방법으로 이용하거나 제 3 자에게 이용하게 하여서는 안 됩니다.
						<br/><br/>* 복제, 전송, 출판, 배포, 방송 기타 방법에 의한 영리목적 이용
						<br/>* 기타 이 약관 또는 회사의 정책으로 금지하는 목적을 위한 이용
						<br/><br/>3. 회원은 교육콘텐츠 내에서 보여지거나 교육서비스와 관련하여 회원 또는 다른 이용자가 클라이언트 또는 교육서비스를 통해 업로드 또는 이미지, 사운드 및 모든 자료 및 정보(이하 “이용자 콘텐츠”라 합니다.)에 대하여 회사가 다음과 같은 방법과 조건으로 이용하는 것을 허락합니다.
						<br/><br/>* 해당 이용자 콘텐츠를 이용, 편집 형식의 변경 및 기타 변형하는 것(공표, 복제, 공연, 전송, 배포, 방송) 2차적 저작물 작성 등 어떠한 형태로든 이용하면 안되고, 이용기간과 지역에는 역시 제한됩니다.
						<br/>* 이용자 콘텐츠를 제작한 이용자의 사전 동의 없이 거래를 목적으로 이용자 콘텐츠를 판매, 대여, 양도 행위를 하지 않음
						<br/><br/>4. 교육콘텐츠 내에서 보여지지 않고 교육서비스와 일체화되지 않은 회원의 이용자 콘텐츠(예컨대, 일반게시판 등에서의 게시물)에 대하여 회사는 회원의 명시적인 동의가 없이 이용하지 않으며, 회원은 언제든지 이러한 이용자 콘텐츠를 삭제할 수 있습니다.
						<br/><br/>5. 회사는 회원이 게시하거나 등록하는 교육서비스 내의 게시물, 게시 내용에 대해 제 12조에서 규정하는 금지행위에 해당된다고 판단되는 경우, 사전통지 없이 이를 삭제하거나 이동 또는 등록을 거부할 수 있습니다.
						<br/><br/>6. 이 조(제 15 조)는 회사가 교육서비스를 운영하는 동안 유효하며, 회원 탈퇴 후에도 지속적으로 적용됩니다.						
						</p>
						<span className={styles.sub_tit}>제18조 (이용상품)</span>
						<p>
						1 회사는 회원이 별도 비용지급 없이 이용할 수 있는 교육서비스(이하 “무료서비스”라 한다)와 회사가 미리 책정한 요금을 지불하고 이용하는 교육서비스(이하 “유료서비스”라 한다)를 제공할 수 있으며, 회원은 교육서비스를 선택하여 이용할 수 있습니다. 모바일 교육서비스 내에서 회원이 구매한 유료 콘텐츠는 해당 애플리케이션을 다운로드 받거나 설치한 모바일 기기에서만 이용할 수 있습니다.
						<br/><br/>2. 교육서비스의 이용에 대한 대금 지급방법은 회사가 미리 정한 방법을 통하여 할 수 있습니다.
						<br/><br/>3. 유료서비스 이용을 신청한 회원은 회사가 정한 방법에 따라 대금을 성실히 납부하여야 합니다.
						<br/><br/>4. 회원이 구매한 유료서비스의 사용 기간은 유료 계정을 유지하는 동안 유지가 됩니다. 다만, 유료서비스 구매 시 별도로 사용 기간을 정한 경우 그에 따르며, 제14조 제5항에 따라 서비스 중단이 이루어지는 경우 기간의 정함이 없는 유료 콘텐츠의 이용기간은 서비스 중단 공지 시 공지된 서비스의 중단일자까지로 합니다.						
						</p>
						<span className={styles.sub_tit}>제 19 조 (유료 콘텐츠)</span>
						<p>
						1. 유료 콘텐츠는 아이템, 추가 미션, 음악 등 회사에서 지정한 모든 유료 콘텐츠가 포함이 됩니다.
						<br/><br/>2. 회사는 결제업체(이동통신사, 신용카드사 등) 및 회사의 정책에 따라 회원의 아이템 충전•보유•이용 한도를 설정할 수 있습니다.
						<br/><br/>3. 회사는 다음의 각 호에 해당하는 경우 회원의 결제 신청에 대해 승낙하지 않거나, 사후에 승낙을 취소할 수 있습니다.
						<br/><br/>* 1. 결제한 요금을 납부하지 않거나, 납부자 확인이 불가능한 경우
						<br/>* 2. 타인의 결제 정보를 도용한 경우
						<br/>* 3. 미성년자의 충전 신청에 대해 법정 대리인이 동의하지 않은 경우
						<br/>* 4. 그 밖에 1 호 내지 3 호에 준하는 사유로서 승낙이 부적절하다고 판단되는 경우						
						</p>
						<span className={styles.sub_tit}>제 20 조 (회원에 대한 서비스 이용제한)</span>
						<p>
						1. 회사는 회원에게 다음 각 호의 구분에 따라 회원의 교육서비스 이용을 제한할 수 있습니다. 이용제한이 이루어지는 구체적인 회원의 의무위반 사유는 제 26 조에 따라 개별 운영정책에서 정하기로 합니다.
						<br/><br/>* 계정 일부 권한 제한: 일정기간 또는 영구히 게시판 글쓰기 등 회원 계정의 일정 권한을 제한
						<br/>* 계정 이용제한: 일정기간 또는 영구히 회원 계정의 이용을 제한
						<br/>* 회원 이용제한: 일정기간 또는 영구히 회원의 교육서비스 이용을 제한
						<br/><br/>2. 회사의 이용제한이 정당한 경우에 회사는 이용제한으로 인하여 회원이 입은 손해를 배상하지 않습니다.
						<br/><br/>3. 회원의 계정(ID)을 다른 사람이 이용하여 약관 또는 운영정책을 위반한 경우에도 회원 본인의 교육서비스 이용이 제 1 항의 기준에 따라 제한될 수 있습니다.
						<br/><br/>4. 회사는 서비스 개선과 회원 정보 보호를 위하여 운영정책에서 별도로 고지하는 일정 기간 이상 교육콘텐츠를 이용하지 않은 계정등 사용자 정보를 삭제할 수 있습니다. 단, 회사는 정보 삭제 7 일 전 회원에게 그 사실을 고지하여야 합니다.						
						</p>
						<span className={styles.sub_tit}>제 21 조 (잠정조치로서의 이용제한)</span>
						<p>
						1. 회사는 다음 각 호에 해당하는 문제에 대한 조사가 완료될 때까지 계정을 정지할 수 있습니다..
						<br/><br/>* 계정이 해킹 또는 도용당하였다는 정당한 신고가 접수된 경우
						<br/>* 불법프로그램 사용자, 작업장 등 위법행위자로 합리적으로 의심되는 경우
						<br/>* 그 밖에 위 각호에 준하는 사유로 계정의 잠정조치가 필요한 경우
						<br/><br/>2. 제 1 항의 경우 회사는 조사가 완료된 후 교육서비스 이용 기간에 비례하여 일정액을 지급하여 이용하는 회원에게 정지된 기간만큼 회원의 교육서비스 이용기간을 연장합니다. 다만, 제 1 항에 의한 위법행위자로 판명된 경우에는 그러하지 아니합니다						
						</p>
						<span className={styles.sub_tit}>제 22 조(이용제한 조치의 사유와 절차)</span>
						<p>
						1. 회사는 위반행위의 내용, 정도, 횟수, 결과 등 제반사정을 고려하여 이용제한이 이루어지는 구체적인 사유 및 절차를 운영정책으로 정합니다.
						<br/><br/>회사가 제 20 조 제 1 항에서 정한 이용제한 조치를 하는 경우에는 다음 각 호의 사항을 회원에게 사전 통지합니다. 다만, 긴급히 조치할 필요가 있는 경우에는 사후에 통지할 수 있습니다.
						<br/><br/>* 이용제한 조치의 사유
						<br/>* 이용제한 조치의 유형 및 기간
						<br/>* 이용제한 조치에 대한 이의신청 방법						
						</p>
						<span className={styles.sub_tit}>제 23 조 (이용제한 조치에 대한 이의신청 절차)</span>
						<p>
						1. 회원이 회사의 이용제한 조치에 불복하고자 할 때에는 이 조치의 통지를 받은 날부터 15 일 이내에 불복 이유를 기재한 이의 신청서를 서면, 전자우편 또는 이에 준하는 방법으로 회사에 제출하여야 합니다.
						<br/><br/>2. 회사는 제 1 항의 이의신청서를 접수한 날부터 15 일 이내에 불복 이유에 대하여 서면, 전자우편, 전화 또는 이에 준하는 방법으로 답변합니다. 다만, 회사는 이 기간 내에 답변이 어려운 경우에는 그 사유와 처리일정을 통지합니다.
						<br/><br/>3. 회사는 불복 이유가 타당한 경우에는 이에 따른 조치를 취합니다.						
						</p>
						<span className={styles.sub_tit}>제 24 조 (컴퓨터 프로그램의 설치)</span>
						<p>
						1. 회사는 서비스 제공을 위하여 필요한 경우 회원에게 회사가 제공하는 컴퓨터 프로그램의 설치를 요청할 수 있습니다. 회사는 회원이 해당 프로그램을 설치하기 전까지 용량, 기능, 제거방법, 다른 프로그램에 미치는 영향 등 중요사항을 프로그램의 성격에 따라 적절한 방법으로 고지하고 프로그램 설치에 대한 동의를 얻어야 합니다. 단, 회원이 ActiveX 프로그램을 자동으로 설치하도록 브라우저 옵션을 설정한 경우 회사는 회원이 ActiveX 프로그램 설치에 동의한 것으로 간주하고 별도 의사 확인 없이 해당 프로그램을 설치할 수 있습니다.
						<br/><br/>2. 회사가 제 1 항에서 고지한 내용을 크게 변경하지 않는 범위 내에서 프로그램을 패치하거나 업데이트할 경우 중요사항 고지 및 동의를 생략할 수 있습니다.
						<br/><br/>3. 회사는 컴퓨터 프로그램의 설치 또는 업데이트 시 데이터의 원활한 전송을 위하여 회원들 간에 데이터를 중계 전송하는 기술을 사용할 수 있습니다. 회원은 PC 자원(스토리지, 네트워크 장비 등)을 통하여 다른 회원에게 데이터를 중계 전송하거나 자신이 중계 전송받는 것에 대해 동의합니다.						
						</p>
					</div>
					<div className={`${styles.privacy_area} ${styles.area6}`}>
						<span className={styles.tit}>제 6 장 청약철회, 계약 해제·해지</span>
						<br/><span className={styles.sub_tit}>제 25 조 (모바일 대금결제)</span>
						<p>
						1. 콘텐츠에 대한 구매 대금의 부과와 납부는 원칙적으로 이동통신사나 오픈마켓 사업자 등에서 정하는 정책이나 방법에 따릅니다. 또한 각 결제수단별 한도가 회사나 오픈마켓 사업자가 정하는 정책 또는 정부의 방침에 따라 부여되거나 조정될 수 있습니다.
						<br/><br/>2. 콘텐츠의 구매대금을 외화로 결제하는 경우에는 환율‧수수료 등으로 인하여 실제 청구금액이 서비스의 상점 등에서 표시된 가격과 달라질 수 있습니다. 					
						</p>
						<span className={styles.sub_tit}>제 26 조 (청약 철회 등)</span>
						<p>
						1. 회사와 유료서비스 이용에 관한 계약을 체결한 회원은 구매계약일과 유료 교육서비스 이용가능일 중 늦은 날부터 7 일 이내에 별도의 수수료‧위약금 등의 부담 없이 청약철회를 할 수 있습니다. 다만 서비스 사용을 하지 않은 경우입니다. (제30조 참조)
						<br/><br/>2. 회원은 다음 각 호에 해당하는 경우에는 회사의 의사에 반하여 제 1 항에 따른 청약철회를 할 수 없습니다.
						<br/><br/>* 회원에게 책임이 있는 사유로 재화 등이 멸실되거나 훼손된 경우
						<br/>* 소비자의 사용 또는 일부 소비로 재화등의 가치가 현저히 감소한 경우
						<br/>- 구매 즉시 사용되거나 적용되는 유료 콘텐츠
						<br/>- 개봉행위를 사용으로 볼 수 있거나 개봉 시 효용이 결정되는 콘텐츠의 개봉행위가 있는 경우
						<br/>* 콘텐츠의 제공이 개시된 경우. 다만 가분적 콘텐츠로 구성된 계약의 경우 제공이 개시되지 아니한 부분은 그러하지 아니합니다.
						<br/><br/>3. 회사는 제 2 항 제 2 호부터 제 3 호의 규정에 따라 청약철회 등이 불가능한 재화 등의 경우에는 그 사실을 재화 등의 포장이나 그 밖에 회원이 쉽게 알 수 있는 곳에 명확하게 적시하거나 시험 사용 상품을 제공하는 등의 방법으로 청약철회 등의 권리 행사가 방해받지 아니하도록 조치하여야 합니다. 다만, 제 2 항 제 3 호에 따라 회원이 청약철회 등을 할 수 없는 경우에는 청약철회등이 불가능하다는 사실의 표시와 함께 시험사용상품을 제공하는 등의 방법으로 청약철회등의 권리 행사가 방해받지 아니하도록 합니다.
						만약 회사가 이러한 조치를 하지 아니한 경우에는 제 2 항 제 2 호 내지 제 3 호의 청약철회 제한사유에도 불구하고 회원은 청약철회를 할 수 있습니다.
						<br/><br/>4. 회원은 제 1 항 내지 제 3 항의 규정에도 불구하고 유료서비스의 내용이 표시•광고 내용과 다르거나 계약내용과 다르게 이행된 경우에는 구매일 또는 유료서비스 이용가능일로부터 3 개월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30 일 이내에 청약철회를 할 수 있습니다.
						<br/><br/>5. 애플리케이션 관련 회원이 청약철회를 할 경우 회사는 플랫폼사업자 또는 오픈마켓 사업자를 통해 구매내역을 확인합니다. 또한 회사는 회원의 정당한 철회 사유를 확인하기 위해 회원에게서 제공받은 정보를 통해 회원에게 연락할 수 있으며, 추가적인 증빙을 요구할 수 있습니다.
						<br/><br/>6. 미성년자인 회원이 결제가 필요한 유료 교육서비스 계약을 체결하는 경우, 회사는 법정대리인의 동의가 없으면 미성년자 본인 또는 법정대리인이 그 계약을 취소할 수 있다는 내용을 고지하며, 미성년자가 법정대리인의 동의 없이 계약을 체결한 때에는 미성년자 본인 또는 법정대리인은 회사에 그 계약을 취소할 수 있습니다. 다만, 미성년자가 법정대리인이 범위를 정하여 처분을 허락한 재산으로 유료 교육서비스계약을 체결한 경우 또는 미성년자가 속임수로써 자기를 성년자로 믿게 하거나 법정대리인의 동의가 있는 것으로 믿게 한 경우에는 취소할 수 없습니다.
						<br/><br/>7. 교육서비스이용계약의 당사자가 미성년자인지 여부는 결제가 진행된 기기, 결제 실행자 정보, 결제 수단 명의자 등을 근거로 판단합니다. 또한 회사는 정당한 취소인지를 확인하기 위해 미성년자 및 법정대리인임을증명할 수 있는 서류의 제출을 요청할 수 있습니다.						
						</p>
						<span className={styles.sub_tit}>제 27 조 (과오납금의 환급)</span>
						<p>
						1. 회사는 과오금이 발생한 경우 이용대금의 결제와 동일한 방법으로 과오금 전액을 환불하여야 합니다. 다만, 동일한 방법으로 환불이 불가능할 때는 이를 사전에 고지합니다.
						<br/><br/>2. 회사의 책임 있는 사유로 과오금이 발생한 경우 회사는 계약비용, 수수료 등에 관계없이 과오금 전액을 환불합니다. 다만, 회원의 책임 있는 사유로 과오금이 발생한 경우, 회사가 과오금을 환불하는 데 소요되는 비용은 합리적인 범위 내에서 회원이 부담하여야 합니다.
						<br/><br/>3. 회사는 회원이 주장하는 과오금에 대해 환불을 거부할 경우에 정당하게 이용대금이 부과되었음을 입증할 책임을 집니다.
						<br/><br/>4. 애플리케이션의 경우 환급은 서비스를 이용하고 있는 모바일 기기의 운영체제 종류에 따라 각 오픈마켓 사업자 또는 회사의 환급정책에 따라 진행됩니다.
						<br/><br/>5. 회사는 과오납금의 환급을 처리하기 위해 회원에게서 제공받은 정보를 통해 회원에게 연락할 수 있으며, 필요한 정보의 제공을 요청할 수 있습니다.
						<br/><br/>6. 회사는 과오금의 환불절차를 콘텐츠 이용자보호지침에 따라 처리합니다.						
						</p>
						<span className={styles.sub_tit}>제 28 조 (회원의 해제 및 해지)</span>
						<p>
						1. 회사는 회원이 이 약관에서 정한 회원의 의무를 위반한 경우에는 회원에 대한 사전 통보 후 계약을 해지할 수 있습니다. 다만, 회원이 현행법 위반 및 고의 또는 중대한 과실로 회사에 손해를 입힌 경우에는 사전 통보 없이 이용계약을 해지할 수 있습니다.
						<br/><br/>2. 회원이 보유한 계정(ID)에 대해 제 1 항의 조치가 3 회 이상 적용된 경우 회사는 회원 명의의 모든 계정(ID)에 대해 계약을 해지할 수 있습니다.
						<br/><br/>3. 회사는 회원 개인정보 보호 및 계정도용 방지를 위해 마지막 서비스 이용일로부터 1 년이 경과한 계정(ID)의 개인정보를 파기하고 이용계약을 해지하거나, 해당 계정(ID)의 개인정보를 다른 이용자의 개인정보와 분리하여 별도로 저장∙관리하고 서비스 이용을 제한할 수 있습니다.
						<br/><br/>4. 제 3 항에 따른 서비스 제한 이후 2 년이 경과할 때까지 회원이 서비스 이용 재개 신청을 하지 않을 경우 회사는 해당 계정(ID)에 대해 이용계약을 해지하고 관련 정보를 삭제할 수 있습니다.
						<br/><br/>5. 회사가 이용계약을 해지하는 경우 회사는 회원에게 서면, 전자우편 또는 이에 준하는 방법으로 다음 각 호의 사항을 회원에게 통보합니다.
						<br/><br/>* 해지사유
						<br/>* 해지일
						<br/><br/>6. 제 1 항 단서의 경우, 회원은 유료서비스의 사용권한을 상실하고 이로 인한 환불 및 손해배상을 청구할 수 없습니다						
						</p>
					</div>
					<div className={`${styles.privacy_area} ${styles.area7}`}>
						<span className={styles.tit}>제 7 장 손해배상 및 면책조항 등</span>
						<br/><span className={styles.sub_tit} id="refund_policy">제 29 조 (손해배상)</span>
						<p>
						1. 회사가 고의 또는 중과실로 회원에게 손해를 끼친 경우, 손해에 대하여 배상할 책임이 있습니다.
						<br/><br/>2. 회사는 유료서비스의 결함 등에 의한 회원 피해보상의 기준ㆍ범위ㆍ방법 및 절차에 관한 사항을 콘텐츠 이용자 보호지침에 따라 처리합니다. 단, 유료서비스 구매 시 또는 이 약관에서 피해보상에 대해 별도로 정한 경우 그에 따릅니다.
						<br/><br/>3. 회원이 회사로부터 구매한 유료서비스가 회사의 귀책사유로 인하여 손실된 경우 회사는 이를 손실 이전의 상태로 원상복구하며, 원상복구가 불가능하거나 곤란한 경우 회사의 선택에 따라 동종의 유사한 가치를 가진 유료서비스를 다시 제공하거나 유료서비스 구매대금을 환불해야 합니다.
						<br/><br/>4. 회원이 이 약관을 위반하여 회사에 손해를 끼친 경우, 회원은 회사에 대하여 그 손해에 대하여 배상할 책임이 있습니다.						
						</p>
						<span className={styles.sub_tit}>제 30 조 (환불)</span>
						<p>
						1. 회원이 구매한 유료서비스 이용계약의 해지, 청약철회 등의 사유로 환불을 요청하는 경우에는 다음 각 호의 금액을 환급 받을 수 있습니다.
						<br/><br/>* 환불은 전액 사용하지 않은 상태여야 환불이 가능합니다.
						<br/>* 결제 후 유료서비스를 사용하지 않은 상태에서 환불이 가능하나 일부라도 사용한 상태에서는 환불이 불가합니다
						<br/><br/>2. 제 1 항에서 환불기준을 정하지 않은 상품 또는 회사가 유료서비스 판매 시 제 1 항과 다른 환불 기준을 정하여 고지한 상품에 대해서는 회사가 별도로 고지하는 환불 기준이 적용됩니다.
						<br/><br/>3. 회사는 회원이 제 1 항의 환불 의사 표시를 한 날로부터 5 영업일 이내에 제 1 항에서 정한 금액을 환불하여야 합니다. 다만, 수납확인이 필요한 결제 수단(예: 휴대폰 결제 등)의 경우에는 수납확인일로부터 5 영업일 이내에 이를 환불하도록 합니다.
						</p>
						<span className={styles.sub_tit}>제 31 조 (회사의 면책)</span>
						<p>
						1. 회사는 전시, 사변, 천재지변, 비상사태, 현재의 기술로는 해결이 불가능한 기술적 결함 기타 불가항력적 사유로 서비스를 제공할 수 없는 경우에는 책임이 면제됩니다.
						<br/><br/>2. 회사는 회원의 귀책사유로 인한 교육서비스의 중지, 이용장애 및 계약해지에 대하여 책임이 면제됩니다.
						<br/><br/>3. 회사는 기간통신 사업자가 전기통신서비스를 중지하거나 정상적으로 제공하지 아니하여 회원에게 손해가 발생한 경우에 대해서 회사의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다.
						<br/><br/>4. 회사는 사전에 공지된 교육서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 교육서비스가 중지되거나 장애가 발생한 경우에 대해서 회사의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다.
						<br/><br/>5. 회사는 회원의 컴퓨터 환경으로 인하여 발생하는 제반 문제 또는 회사의 고의 또는 중대한 과실이 없는 네트워크 환경으로 인하여 발생하는 문제에 대해서 책임이 면제됩니다.
						<br/><br/>6. 회사는 회원 또는 제 3 자가 교육서비스 내 또는 웹사이트 상에 게시 또는 전송한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 대해서는 회사의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다.
						<br/><br/>7. 회사는 회원 상호간 또는 회원과 제 3 자간에 교육서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없으며 이로 인한 손해를 배상할 책임도 없습니다.
						<br/><br/>8. 회사가 제공하는 교육서비스 중 무료서비스의 경우에는 회사의 고의 또는 중대한 과실이 없는 한 회사는 손해배상을 하지 않습니다.
						<br/><br/>9. 본 교육서비스 중 일부의 교육서비스는 다른 사업자가 제공하는 교육서비스를 통하여 제공될 수 있으며, 회사는 다른 사업자가 제공하는 교육서비스로 인하여 발생한 손해 등에 대해서는 회사의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다.
						<br/><br/>10. 회사는 회원이 교육서비스를 이용하며 교육서비스에 대한 취사선택 또는 이용으로 발생하는 손해 등에 대해서 회사의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다.
						<br/><br/>11. 회사는 회원의 애플리케이션 사이버 자산 손실에 대하여 회사의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다.
						<br/><br/>12. 회사는 회원의 컴퓨터 오류에 의한 손해가 발생한 경우 또는 신상정보 및 전자우편주소를 부정확하게 기재하거나 미기재하여 손해가 발생한 경우에 대하여 회사의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다.
						<br/><br/>13. 회사는 관련 법령, 정부 정책 등에 따라 교육서비스 또는 회원에 따라 교육서비스 이용시간 등을 제한할 수 있으며, 이러한 제한사항 및 제한에 따라 발생하는 교육서비스 이용 관련 제반사항에 대해서는 책임이 면제됩니다.
						<br/><br/>14. 회사는 회원이 비밀번호를 관리하지 않아 발생하는 제 3 자 결제에 대해 책임을 지지 않습니다. 다만, 회사의 고의 또는 중과실에 의한 경우에는 그러하지 아니합니다.
						<br/><br/>15. 회원이 모바일 기기의 변경, 모바일 기기의 번호 변경, 운영체제(OS) 버전의 변경, 해외 로밍, 통신사 변경 등으로 인해 콘텐츠 전부나 일부의 기능을 이용할 수 없는 경우 회사는 이에 대해 책임을 지지 않습니다. 다만, 회사의 고의 또는 중과실에 의한 경우에는 그러하지 아니합니다.
						<br/><br/>16. 회원이 회사가 제공하는 콘텐츠나 계정정보를 삭제한 경우 회사는 이에 대해 책임을 지지 않습니다. 다만, 회사의 고의 또는 중과실에 의한 경우에는 그러하지 아니합니다.
						<br/><br/>17. 회사는 임시회원(guest)의 서비스 이용으로 발생한 손해에 대해서는 책임을 지지 않습니다. 다만, 회사의 고의 또는 중과실에 의한 경우에는 그러하지 아니합니다.						
						</p>
						<span className={styles.sub_tit}>제 32 조 (회원에 대한 통지 및 공지)</span>
						<p>
						1. 회사가 회원에게 통지를 하는 경우 회원의 전자우편주소, 문자메시지(LMS/SMS) 등으로 할 수 있습니다.
						<br/><br/>2. 회사는 회원 전체에게 통지를 하는 경우 7 일 이상 교육서비스 내에 게시하거나 팝업화면 등을 제시함으로써 제 1 항의 통지에 갈음할 수 있습니다.						
						</p>
						<span className={styles.sub_tit}>제 33 조 (재판권 및 준거법)</span>
						<p>
						이 약관은 대한민국 법률에 따라 규율되고 해석됩니다. 회사와 회원 간에 발생한 분쟁으로 소송이 제기되는 경우에는 법령에 정한 절차에 따른 법원을 관할 법원으로 합니다.						
						</p>
						<span className={styles.sub_tit}>제 34 조 (회원의 고충처리 및 분쟁해결)</span>
						<p>
						1. 회사는 회원의 편의를 고려하여 회원의 의견이나 불만을 제시하는 방법을 교육서비스 내 또는 그 연결화면에 안내합니다. 회사는 이러한 회원의 의견이나 불만을 처리하기 위한 전담인력을 운영합니다.
						<br/><br/>2. 회사는 회원으로부터 제기되는 의견이나 불만이 정당하다고 객관적으로 인정될 경우에는 합리적인 기간 내에 이를 신속하게 처리합니다. 다만, 처리에 장기간이 소요되는 경우에는 회원에게 장기간이 소요되는 사유와 처리일정을 교육서비스 내 공지하거나 제 32 조 제 1 항에 따라 통지합니다.
						<br/><br/>3. 회사와 회원 간에 분쟁이 발생하여 제 3 의 분쟁조정기관이 조정할 경우 회사는 이용제한 등 회원에게 조치한 사항을 성실히 증명하고, 조정기관의 조정에 따를 수 있습니다.						
						</p>
					</div>

				</div>
				<div className={styles.to_top}>
					<a href="/Use">Top</a>
				</div>
			</div>
		</div>
    </>
  );
}