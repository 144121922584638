import * as React from 'react';
import { useState } from 'react';

export default function ImgFileUpload(props) {

const initialImgFileState = {
    detailImageFile: null,
    detailImageUrl: null
};

const [imgFile, setImgFile] = useState(initialImgFileState);

    // set file reader function
const setImageFromFile = ({ file, setImageUrl }) => {
    let reader = new FileReader();
    reader.onload = function () {
       setImageUrl({ result: reader.result });
    };
    reader.readAsDataURL(file);
 };

  return (
    <>
      <input
          type="file"
          name="imgFile"
          id="detail_image"
          accept="image/*"
          onChange={({ target: { files } }) => {
              if (files.length) 
              {
                  setImageFromFile(
                    { 
                        file: files[0], 
                        setImageUrl: ({ result }) => setImgFile({detailImageFile: files[0], detailImageUrl: result}) 
                    }
                  );

                  props.handleImgFileChange(files[0]);
              }
          }}
      />
      {imgFile.detailImageFile && (
        <div>
          <img src={imgFile.detailImageUrl} alt={imgFile.detailImageFile.name} />
        </div>
      )}
    </>
  );
}