import * as React from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import styles from "../css/common.module.css";
import $ from 'jquery';

export default function Faq() {

	const authState = useSelector(state => state.auth);
    // console.log("authState : ", authState);

	const dispatch = useDispatch();	

	let faqArr=[
		{"index":1,"categori":"결제","title":"1.단체 구매는 어떻게 하나요?","answer":"1.홈페이지 상단의 회원가입 버튼을 클릭하여 가입하실 수 있습니다."}
		,{"index":2,"categori":"결제","title":"2.단체 구매는 어떻게 하나요?","answer":"2.홈페이지 상단의 회원가입 버튼을 클릭하여 가입하실 수 있습니다."}
		,{"index":3,"categori":"결제","title":"3.단체 구매는 어떻게 하나요?","answer":"3.홈페이지 상단의 회원가입 버튼을 클릭하여 가입하실 수 있습니다."}
		,{"index":4,"categori":"결제","title":"4.단체 구매는 어떻게 하나요?","answer":"4.홈페이지 상단의 회원가입 버튼을 클릭하여 가입하실 수 있습니다."}
		,{"index":5,"categori":"학습(앱)","title":"5.캐릭터는 어떻게 바꾸나요?","answer":"5.홈페이지 상단의 회원가입 버튼을 클릭하여 가입하실 수 있습니다."}
		,{"index":6,"categori":"홈페이지","title":"6.회원가입은 어디서 하나요?","answer":"6.홈페이지 상단의 회원가입 버튼을 클릭하여 가입하실 수 있습니다."}
		,{"index":7,"categori":"홈페이지","title":"7.회원가입은 어디서 하나요?","answer":"7.홈페이지 상단의 회원가입 버튼을 클릭하여 가입하실 수 있습니다."}
		,{"index":8,"categori":"홈페이지","title":"8.회원가입은 어디서 하나요?","answer":"8.홈페이지 상단의 회원가입 버튼을 클릭하여 가입하실 수 있습니다."}
		,{"index":9,"categori":"홈페이지","title":"9.회원가입은 어디서 하나요?","answer":"9.홈페이지 상단의 회원가입 버튼을 클릭하여 가입하실 수 있습니다."}
		,{"index":10,"categori":"기타","title":"10.기타 자주묻는질문","answer":"10.홈페이지 상단의 회원가입 버튼을 클릭하여 가입하실 수 있습니다."}
	];

	useEffect(() => {

		// 여기에서 jQuery를 초기화 합니다.
		$(document).ready(function(){

			// console.log("jquery ready!");

			// FAQ 리스트 출력 변수
			let outputHtml = "";
			
			// FAQ 리스트 반복문 (전체 출력)className={styles.faq_tab}
			faqArr.map((x)=> 
			{
				// console.log(x.categori);
				outputHtml += `<li class=${styles.list}>
					<div class=${styles.question_area}>
						<span>Q</span>
						<p>${x.title}</p>
						<img src="assets/faq_arrow.png" alt="arrow"/>
					</div>
					<div class=${styles.answer_area}>
						<p class=${styles.answer}>${x.answer}</p>
					</div>
				</li>`;
			})

			// FAQ 리스트 출력
			// console.log('outputHtml : ', outputHtml);
			$(`.${styles.faq_list_wrap}`).html(outputHtml);

			//카테고리(셀렉트 값) 변경될때마다 해당 질문 뿌려주기
			$(`#${styles.faq_categori_select}`).on('change', function()
			{
				let th=$(this).val();
				$(`#${styles.faq_input_id}`).val("");

				outputHtml = "";
				$(`.${styles.faq_list_wrap}`).html(outputHtml);

				if(th==="결제")
				{
					faqArr.filter((x)=> x.categori==="결제").map((x)=> 
					{
						// console.log(x.categori);
						outputHtml += `<li class=${styles.list}>
							<div class=${styles.question_area}>
								<span>Q</span>
								<p>${x.title}</p>
								<img src="assets/faq_arrow.png" alt="arrow"/>
							</div>
							<div class=${styles.answer_area}>
								<p class=${styles.answer}>${x.answer}</p>
							</div>
						</li>`;
					})
				}
				else if(th==="학습(앱)")
				{
					faqArr.filter((x)=> x.categori==="학습(앱)").map((x)=> 
					{
						// console.log(x.categori);
						outputHtml += `<li class=${styles.list}>
							<div class=${styles.question_area}>
								<span>Q</span>
								<p>${x.title}</p>
								<img src="assets/faq_arrow.png" alt="arrow"/>
							</div>
							<div class=${styles.answer_area}>
								<p class=${styles.answer}>${x.answer}</p>
							</div>
						</li>`;
					})
				}
				else if(th==="홈페이지")
				{
					faqArr.filter((x)=> x.categori==="홈페이지").map((x)=> 
					{
						// console.log(x.categori);
						outputHtml += `<li class=${styles.list}>
							<div class=${styles.question_area}>
								<span>Q</span>
								<p>${x.title}</p>
								<img src="assets/faq_arrow.png" alt="arrow"/>
							</div>
							<div class=${styles.answer_area}>
								<p class=${styles.answer}>${x.answer}</p>
							</div>
						</li>`;
					})
				}
				else if(th==="기타")
				{
					faqArr.filter((x)=> x.categori==="기타").map((x)=> 
					{
						// console.log(x.categori);
						outputHtml += `<li class=${styles.list}>
							<div class=${styles.question_area}>
								<span>Q</span>
								<p>${x.title}</p>
								<img src="assets/faq_arrow.png" alt="arrow"/>
							</div>
							<div class=${styles.answer_area}>
								<p class=${styles.answer}>${x.answer}</p>
							</div>
						</li>`;
					})
				}
				else
				{
					faqArr.map((x)=> 
					{
						// console.log(x.categori);
						outputHtml += `<li class=${styles.list}>
							<div class=${styles.question_area}>
								<span>Q</span>
								<p>${x.title}</p>
								<img src="assets/faq_arrow.png" alt="arrow"/>
							</div>
							<div class=${styles.answer_area}>
								<p class=${styles.answer}>${x.answer}</p>
							</div>
						</li>`;
					})
				};
				
				// FAQ 리스트 출력
				// console.log('outputHtml : ', outputHtml);
				$(`.${styles.faq_list_wrap}`).html(outputHtml);

			});


			//질문 영역 클릭시 답변 영역 보이도록, 답변은 하나만 보이도록
			$(document).on('click',`.${styles.faq_list_wrap} li`,function()
			{
				let tg=$(this);
				let activeList=$(`.${styles.faq_list_wrap}`).find(`li.${styles.on}`);
				// console.log('tg :', tg);
				// console.log('activeList :', activeList);
				
				if(activeList.index()===tg.index())
				{
					//현재 활성화된 질문을 또 클릭했을 경우
					$(`.${styles.faq_list_wrap} li`).removeClass(`${styles.on}`);
				}
				else
				{
					//현재 활성화된 질문이 아닌 다른 질문을 클릭한 경우
					$(`.${styles.faq_list_wrap} li`).removeClass(`${styles.on}`);
					tg.addClass(`${styles.on}`);
				};
			});



		});

	}, [$]);


	//FAQ 검색기능
	function faqSearch()
	{
		let keyword=$(`#${styles.faq_input_id}`).val();
		let srchSelect=$(`#${styles.faq_srch_select}`).val();
		// console.log(srchSelect);
		
		if(keyword==="")
		{
			//검색어를 입력하지 않았을때
			alert('검색어를 입력해주세요.');
		}
		else
		{
			if(srchSelect===null)
			{
				//검색 카테고리를 선택하지 않았을때
				alert('검색하실 내용을 선택해주세요.');
			}
			else if(srchSelect==="1")
			{
				//검색 카테고리가 제목 일때
				$(`#${styles.faq_categori_select}`).val("");
				let outputHtml = "";
		
				if(faqArr.filter((x)=> x.title.includes(keyword)===true).length>0)
				{
					faqArr.filter((x)=> x.title.includes(keyword)===true).map((x)=> 
					{
						// console.log(x.categori);
						outputHtml += `<li class=${styles.list}>
							<div class=${styles.question_area}>
								<span>Q</span>
								<p>${x.title}</p>
								<img src="assets/faq_arrow.png" alt="arrow"/>
							</div>
							<div class=${styles.answer_area}>
								<p class=${styles.answer}>${x.answer}</p>
							</div>
						</li>`;
					})
				}
				else
				{
					// console.log('검색 결과 없음');
					outputHtml = `<li class=${styles.no_result}>검색결과가 없습니다</li>`;
				};	
		
				$(`.${styles.faq_list_wrap}`).html(outputHtml);
			}
			else if(srchSelect==="2")
			{
				//검색 카테고리가 내용 일때
				$(`#${styles.faq_categori_select}`).val("");
				let outputHtml = "";
				
				if(faqArr.filter((x)=> x.answer.includes(keyword)===true).length>0)
				{
					faqArr.filter((x)=> x.answer.includes(keyword)===true).map((x)=> 
					{
						// console.log(x.categori);
						outputHtml += `<li class=${styles.list}>
							<div class=${styles.question_area}>
								<span>Q</span>
								<p>${x.title}</p>
								<img src="assets/faq_arrow.png" alt="arrow"/>
							</div>
							<div class=${styles.answer_area}>
								<p class=${styles.answer}>${x.answer}</p>
							</div>
						</li>`;
					})
				}
				else
				{
					// console.log('검색 결과 없음');
					outputHtml = `<li class=${styles.no_result}>검색결과가 없습니다</li>`;
				};	
		
				$(`.${styles.faq_list_wrap}`).html(outputHtml);
			}
			else
			{
				$(`#${styles.faq_categori_select}`).val("");
				let outputHtml = "";
				outputHtml = `<li class=${styles.no_result}>검색결과가 없습니다</li>`;
				$(`.${styles.faq_list_wrap}`).html(outputHtml);
			};
		};

	};

  return (
    <>
      	{/* Header 분리됨 MainLayout Header에 있음 */}

		  <div className={styles.faq_top_line_banner}>
			<div className={styles.inner}>
				<span>FAQ</span>
			</div>
		</div>
		<div className={styles.faq_tab}>
			<div className={styles.inner}>
				<span className={styles.faq_tit}>자주 묻는 질문</span>
				<p className={styles.faq_txt}>궁금한 질문을 찾아 클릭하면 답변을 확인할 수 있습니다</p>
				<div className={styles.faq_srch_area}>
					<select name="faq_srch_categori" id={styles.faq_srch_select} defaultValue={'DEFAULT'}>
						<option value="DEFAULT" disabled>선택</option>
						<option value="1">제목</option>
						<option value="2">내용</option>
					</select>
					<input type="text" id={styles.faq_input_id}/>
					<button type="button" onClick={faqSearch}>검색</button>
				</div>
				<div className={styles.faq_area}>
					<div className={styles.faq_categori_area}>
						<select name="faq_categori" id={styles.faq_categori_select} defaultValue="전체">
							<option value="" disabled>선택</option>
							<option value="전체">전체</option>
							<option value="결제">결제</option>
							<option value="학습(앱)">학습(앱)</option>
							<option value="홈페이지">홈페이지</option>
							<option value="기타">기타</option>
						</select>
					</div>
					<ul className={styles.faq_list_wrap}></ul>
					{/* <ul className={styles.faq_list_pagebtn_area}>
						<li className={`${styles.arrow_btn} ${styles.first}`}><a href="#"><img src="assets/first_btn@2x.png" alt="첫번째 페이지로 이동"/></a></li>
						<li className={`${styles.arrow_btn} ${styles.prev}`}><a href="#"><img src="assets/prev_btn@2x.png" alt="이전 페이지로 이동"/></a></li>
						<li className={styles.on}><a href="#">1</a></li>
						<li><a href="#">2</a></li>
						<li><a href="#">3</a></li>
						<li><a href="#">4</a></li>
						<li><a href="#">5</a></li>
						<li><a href="#">6</a></li>
						<li><a href="#">7</a></li>
						<li><a href="#">8</a></li>
						<li><a href="#">9</a></li>
						<li><a href="#">10</a></li>
						<li className={`${styles.arrow_btn} ${styles.next}`}><a href="#"><img src="assets/next_btn@2x.png" alt="다음 페이지로 이동"/></a></li>
						<li className={`${styles.arrow_btn} ${styles.last}`}><a href="#"><img src="assets/last_btn@2x.png" alt="마지막 페이지로 이동"/></a></li>
					</ul> */}
				</div>
			</div>
		</div>
		
		{/* Footer 분리됨 MainLayout Footer에 있음 */}
    </>
  );
}