import React from 'react';
import { useGoogleLogin } from 'react-google-login';

// refresh token
import { refreshTokenSetup } from '../../common/refreshToken';

import { socialLogin } from "../../actions/auth";

import { useDispatch } from "react-redux";

import { useNavigate } from 'react-router-dom';

import styles from "../../css/common.module.css";

const clientId = '42504825298-eavp5n14pprd0prdghkbc89f36l7k1r0.apps.googleusercontent.com';

function GoogleLoginButton() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

  const onSuccess = (res) => {
    // console.log('Login Success: currentUser:', res.profileObj);
    
    // alert(
    //   `Logged in successfully welcome ${res.profileObj.name} 😍. \n See console for full profile object.`
    // );

    // 여기서 디스패치로~
    dispatch(socialLogin("google", res.profileObj.googleId, res.profileObj.email, res.profileObj.name))
    .then(data => 
    {
        // console.log("socialLogin data : ", data);
        // if (data.success)
        //     navigate("/dashboard/NoticeListClient/notice");
    })
    .catch(e => {
        console.log(e);
    });

    refreshTokenSetup(res);
  };

  const onFailure = (res) => {

    // console.log('Login failed: res:', res);

    if (res.error === "idpiframe_initialization_failed")
      return;
    else if (res.error === "popup_closed_by_user")
      return;

    // alert(res.error);
    // console.log(res.error);

    // alert(
    //   `Failed to login. 😢 Please ping this to repo owner twitter.com/sivanesh_fiz`
    // );

  };

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
    isSignedIn: true,
    accessType: 'offline',
    // responseType: 'code',
    // prompt: 'consent',
  });

  return (
    // <button onClick={signIn} className="button">
    //   <img src="/GoogleLogin.png" alt="google login" className="icon"></img>
    // </button>
    <>
      <button type="button" onClick={signIn} className={styles.google}>
        <img src={process.env.PUBLIC_URL + '/assets/logo_googleg_48dp.png'} alt="google logo" />
      </button>
      {/* <a href="#"  onClick={signIn} className={styles.google}>
        <img src={process.env.PUBLIC_URL + '/assets/google_login.png'} alt="구글로 간편로그인"/>
      </a> */}
    </>
  );
}

export default GoogleLoginButton;