import {
  CREATE_USERS,
  CREATE_GROUP_USERS,
  UPDATE_USERS,
  UPDATE_GROUP_USERS, 
  DELETE_USERS,
  LIST_USERS,
  ME_USERS,
  PASSWORD_CHANGE_USERS,
  SELECT_USERS,
  SELECT_GROUP_USERS,
  ACTIVE_USERS,
  MISSION_TOTAL_USERS,
  MISSION_PROGRESS_USERS,
  KNOWLEDGE_ANSWER_USERS,
  TOTAL_HUMANABILITY_USERS,
  HUMANABILITY_USERS,
  IDCHECK_USERS,
} from "./types";

import UsersService from "../services/UsersService";

export const userCreate = (data) => async (dispatch) => {
  
  try {
    
    const res = await UsersService.userCreate(data);

    // console.log("res : ", res);

    dispatch({
      type: CREATE_USERS,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const userGroupCreate = (data) => async (dispatch) => {
  
  try {
    
    const res = await UsersService.userGroupCreate(data);

    // console.log("res : ", res);

    dispatch({
      type: CREATE_GROUP_USERS,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const userModify = (data) => async (dispatch) => {
  
  try {
    
    const res = await UsersService.userModify(data);

    // console.log("res : ", res);

    dispatch({
      type: UPDATE_USERS,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const userGroupModify = (data) => async (dispatch) => {
  
  try {
    
    const res = await UsersService.userGroupModify(data);

    // console.log("res : ", res);

    dispatch({
      type: UPDATE_GROUP_USERS,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    // console.log("Error: ", err);
    // return Promise.reject(err.response);
  }

};

export const userPasswordChange = (data) => async (dispatch) => {
  
  try {
    
    const res = await UsersService.userPasswordChange(data);

    // console.log("res : ", res);

    dispatch({
      type: PASSWORD_CHANGE_USERS,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const userDelete = (data) => async (dispatch) => {
  
  try {

    const res = await UsersService.userDelete(data);

    // console.log("res : ", res);

    if (res.data.success === false)
    {
      alert(res.data.msg);
      return Promise.resolve(res.data);
    }
    else
    {
      dispatch({
        type: DELETE_USERS,
        payload: res.data,
      });
  
      return Promise.resolve(res.data);
    }

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const userActive = (data) => async (dispatch) => {
  
  try {

    const res = await UsersService.userActive(data);

    // console.log("res : ", res);

    if (res.data.success === false)
    {
      alert(res.data.msg);
      return Promise.resolve(res.data);
    }
    else
    {
      dispatch({
        type: ACTIVE_USERS,
        payload: res.data,
      });
  
      return Promise.resolve(res.data);
    }

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const userListAll = data => async (dispatch) => {
  
  try {
    
    const res = await UsersService.getAll(data);

    // console.log("res : ", res);

    dispatch({
      type: LIST_USERS,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const userSelect = email => async (dispatch) => {
  
  try {
    
    const res = await UsersService.getSelect(email);

    // console.log("res : ", res);

    dispatch({
      type: SELECT_USERS,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const userGroupSelect = data => async (dispatch) => {
  
  try {
    
    const res = await UsersService.getGroupSelect(data);

    // console.log("res : ", res);

    dispatch({
      type: SELECT_GROUP_USERS,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const userMissionTotal = () => async (dispatch) => {
  
  try {
    
    const res = await UsersService.userMissionTotal();

    // console.log("res : ", res);

    dispatch({
      type: MISSION_TOTAL_USERS,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const userMissionProgress = (idx) => async (dispatch) => {
  
  try {
    
    const res = await UsersService.userMissionProgress(idx);

    // console.log("res : ", res);

    dispatch({
      type: MISSION_PROGRESS_USERS,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const userKnowledgeAnswer = (idx) => async (dispatch) => {
  
  try {
    
    const res = await UsersService.userKnowledgeAnswer(idx);

    // console.log("res : ", res);

    dispatch({
      type: KNOWLEDGE_ANSWER_USERS,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const userTotalHumanAbility = () => async (dispatch) => {
  
  try {
    
    const res = await UsersService.userTotalHumanAbility();

    // console.log("res : ", res);

    dispatch({
      type: TOTAL_HUMANABILITY_USERS,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const userHumanAbility = (id) => async (dispatch) => {
  
  try {
    
    const res = await UsersService.userHumanAbility(id);

    // console.log("res : ", res);

    dispatch({
      type: HUMANABILITY_USERS,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const userMe = (id) => async (dispatch) => {
  
  try {
    
    const res = await UsersService.me({ id });

    // console.log("res : ", res);

    dispatch({
      type: ME_USERS,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const parentIdCheck = (data) => async (dispatch) => {
  
  try {
    
    const res = await UsersService.parentIdCheck(data);

    // console.log("res : ", res);

    dispatch({
      type: IDCHECK_USERS,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};