import {
  CREATE_ORDER,
  LIST_ORDER,
  CANCEL_ORDER,
  MODIFY_ORDER,
  SELECT_ORDER,
  USE_ORDER,
} from "./types";

import OrderService from "../services/OrderService";

export const orderCreate = (data) => async (dispatch) => {
  
  try {
    
    const res = await OrderService.orderCreate(data);

    // console.log("res : ", res);

    dispatch({
      type: CREATE_ORDER,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const orderList = () => async (dispatch) => {
  
  try {
    
    const res = await OrderService.orderGetAll();

    // console.log("res : ", res);

    dispatch({
      type: LIST_ORDER,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const orderSelect = (ord_idx) => async (dispatch) => {
  
  try {
    
    const res = await OrderService.orderSelect(ord_idx);

    // console.log("res : ", res);

    dispatch({
      type: SELECT_ORDER,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const orderCancel = (ord_idx) => async (dispatch) => {
  
  try {

    // console.log("orderCancel! ord_idx : ", ord_idx);

    const res = await OrderService.orderCancel(ord_idx);

    // console.log("res : ", res);

    if (res.data.success === false)
    {
      alert(res.data.msg);
      return Promise.resolve(ord_idx);
    }
    else
    {
      dispatch({
        type: CANCEL_ORDER,
        payload: { ord_idx },
      });
  
      return Promise.resolve(res.data);
    }

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const orderModify = (ord_idx, category_name, category_desc) => async (dispatch) => {
  
  try {
    
    const res = await OrderService.categoryModify({ ord_idx, category_name, category_desc });

    // console.log("res : ", res);

    dispatch({
      type: MODIFY_ORDER,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const orderUse = (ord_no) => async (dispatch) => {
  
  try {
    
    const res = await OrderService.orderUse(ord_no);

    console.log("res : ", res);

    dispatch({
      type: USE_ORDER,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};