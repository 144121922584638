import React from 'react';
import styles from "../css/common.module.css";

const NotFound = () => {

    return (
        <>
            <div className={styles.not_found_area}>
                <img src="/assets/404-3@2x.png" alt="404 img" />
                <p>페이지를 찾을 수 없어요</p>
                <a href="/">홈페이지로 돌아가기</a>
            </div>
        </>
    );
    
};

export default NotFound;