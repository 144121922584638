import * as React from "react";
import { Routes, Route } from "react-router-dom";
import { StyledEngineProvider } from '@mui/material/styles';

// layouts
import MainLayout from "./layouts/MainLayout";
import DashboardLayout from "./layouts/DashboardLayout";
import SubLayout from "./layouts/SubLayout";

// views - Front Page
import Main from './views/Main';
import Price from "./views/Price";
import Use from "./views/Use";
import Privacy from "./views/Privacy";
import Pay from "./views/Pay";
import Download from "./views/Download";
import Faq from "./views/Faq";
import Order from "./views/Order";

// views - auth
import LoginView from './views/auth/LoginView';
import RegisterView from './views/auth/RegisterView';
import GroupRegisterView from "./views/auth/GroupRegisterView";
import PasswordChange from "./views/auth/PasswordChange";
import RegisterAgree from "./views/auth/RegisterAgree";
import RegisterStudentView from "./views/auth/RegisterStudentView";

// views - users
import UserProfile from "./views/users/UserProfile";
import UserList from "./views/users/UserList";
import UserPasswordChange from "./views/users/UserPasswordChange";

// views - dashboard
import DashboardMain from "./views/dashboard/DashboardMain";

// views - category
import CategoryWrite from "./views/admin/Category/CategoryWrite";
import CategoryList from "./views/admin/Category/CategoryList";
import CategoryModify from "./views/admin/Category/CategoryModify";
import CategoryView from "./views/admin/Category/CategoryView";

// views - knowledge category
import KnowledgeCategoryWrite from "./views/admin/KnowledgeCategory/CategoryWrite";
import KnowledgeCategoryList from "./views/admin/KnowledgeCategory/CategoryList";
import KnowledgeCategoryModify from "./views/admin/KnowledgeCategory/CategoryModify";
import KnowledgeCategoryView from "./views/admin/KnowledgeCategory/CategoryView";

// views - knowledge
import KnowledgeWrite from "./views/admin/Knowledge/KnowledgeWrite";
import KnowledgeList from "./views/admin/Knowledge/KnowledgeList";
import KnowledgeModify from "./views/admin/Knowledge/KnowledgeModify";
import KnowledgeView from "./views/admin/Knowledge/KnowledgeView";

// views - mission category
import MissionCategoryWrite from "./views/admin/MissionCategory/CategoryWrite";
import MissionCategoryList from "./views/admin/MissionCategory/CategoryList";
import MissionCategoryModify from "./views/admin/MissionCategory/CategoryModify";
import MissionCategoryView from "./views/admin/MissionCategory/CategoryView";

// views - mission
import MissionWrite from "./views/admin/Mission/MissionWrite";
import MissionList from "./views/admin/Mission/MissionList";
import MissionModify from "./views/admin/Mission/MissionModify";
import MissionView from "./views/admin/Mission/MissionView";

// views - notice
import NoticeWrite from "./views/admin/Notice/NoticeWrite";
import NoticeList from "./views/admin/Notice/NoticeList";
import NoticeModify from "./views/admin/Notice/NoticeModify";
import NoticeView from "./views/admin/Notice/NoticeView";
import NoticeListClient from "./views/admin/Notice/NoticeListClient";
import NoticeViewClient from "./views/admin/Notice/NoticeViewClient";

// views - product
import ProductWrite from "./views/admin/Product/ProductWrite";
import ProductList from "./views/admin/Product/ProductList";
import ProductModify from "./views/admin/Product/ProductModify";
import ProductView from "./views/admin/Product/ProductView";

// views - order
import OrderWrite from "./views/users/Order/OrderWrite";
import OrderList from "./views/users/Order/OrderList";
import OrderModify from "./views/users/Order/OrderModify";
import OrderView from "./views/users/Order/OrderView";

// views - payment
import PaymentWrite from "./views/admin/Payment/PaymentWrite";
import PaymentList from "./views/admin/Payment/PaymentList";
import PaymentView from "./views/admin/Payment/PaymentView";

// views - refund
import RefundList from "./views/users/Refund/RefundList";

// views - student
import StudentList from "./views/users/Student/StudentList";
import StudentWrite from "./views/users/Student/StudentWrite";
import StudentView from "./views/users/Student/StudentView";
import StudentModify from "./views/users/Student/StudentModify";

// views - package
import PackageList from "./views/users/Package/PackageList";

// views - Board
import BoardList from "./views/Board/List";
import BoardWrite from "./views/Board/Write";
import BoardReply from "./views/Board/Reply";
import BoardView from "./views/Board/View";
import BoardModify from "./views/Board/Modify";

// views - User Mypage
import MypageReport from "./views/ParentMypage/MypageReport";
import MypageStudentInfo from "./views/ParentMypage/MypageStudentInfo";
import MypageParentsInfo from "./views/ParentMypage/MypageParentsInfo";
import MypagePayInfo from "./views/ParentMypage/MypagePayInfo";
import MypageStudentRegister from "./views/ParentMypage/MypageStudentRegister";
import MypageStudentInfoFix from "./views/ParentMypage/MypageStudentInfoFix";
import MypagePayInfoMore from "./views/ParentMypage/MypagePayInfoMore";
import MypageStudentAdd from "./views/ParentMypage/MypageStudentAdd";

// 404 NotFound
import NotFound from "./views/NotFound";

import Test from "./views/Test";

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <div className="App">
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route index path="" element={<Main />} />
            <Route path="Price" element={<Price />} />
            <Route path="Use" element={<Use />} />
            <Route path="Privacy" element={<Privacy />} />
            <Route path="test" element={<Test />} />
            <Route path="Pay" element={<Pay />} />
            <Route path="Download" element={<Download />} />
            <Route path="Faq" element={<Faq />} />
            <Route path="Order" element={<Order />} />

            <Route index path="BoardList/:board_name" element={<BoardList />} />
            <Route index path="BoardList/:board_name/:page" element={<BoardList />} />
            <Route index path="BoardList/:board_name/:page/:condition/:keyword" element={<BoardList />} />
            <Route index path="BoardWrite/:board_name" element={<BoardWrite />} />
            <Route index path="BoardReply/:board_name/:bbs_idx/:prevPage" element={<BoardReply />} />
            <Route index path="BoardView/:board_name/:idx/:prevPage" element={<BoardView />} />
            <Route index path="BoardView/:board_name/:idx/:prevPage/:condition/:keyword" element={<BoardView />} />
            <Route index path="BoardModify/:board_name/:idx/:prevPage" element={<BoardModify />} />
            <Route index path="BoardModify/:board_name/:idx/:prevPage/:condition/:keyword" element={<BoardModify />} />
          </Route>

          <Route path="/auth" element={<SubLayout />}>
            <Route index path="login" element={<LoginView />} />
            <Route index path="register" element={<RegisterView />} />
            <Route index path="groupRegister" element={<GroupRegisterView />} />
            <Route index path="PasswordChange" element={<PasswordChange />} />
            <Route index path="RegisterAgree" element={<RegisterAgree />} />
            <Route index path="RegisterStudentView" element={<RegisterStudentView />} />
          </Route>

          <Route path="/UserMypage" element={<MainLayout />}>
            <Route index path="Report" element={<MypageReport />} />
            <Route index path="StudentInfo" element={<MypageStudentInfo />} />
            <Route index path="ParentsInfo" element={<MypageParentsInfo />} />
            <Route index path="PayInfo" element={<MypagePayInfo />} />
            <Route index path="StudentRegister" element={<MypageStudentRegister />} />
            <Route index path="StudentInfoFix/:mst_id" element={<MypageStudentInfoFix />} />
            <Route index path="PayInfoMore/:ord_idx" element={<MypagePayInfoMore />} />
            <Route index path="MypageStudentAdd" element={<MypageStudentAdd />} />
          </Route>

          <Route path="/dashboard" element={<DashboardLayout />}>

            <Route index path="" element={<DashboardMain />} />
            <Route index path="profile" element={<UserProfile />} />
            <Route index path="UserPasswordChange" element={<UserPasswordChange />} />
            <Route index path="userList" element={<UserList />} />
            <Route index path="userList/:page" element={<UserList />} />
            <Route index path="userList/:page/:condition/:keyword" element={<UserList />} />
            
            <Route index path="categoryWrite" element={<CategoryWrite />} />
            <Route index path="CategoryList" element={<CategoryList />} />
            <Route index path="CategoryModify/:category_idx" element={<CategoryModify />} />
            <Route index path="CategoryView/:category_idx" element={<CategoryView />} />

            <Route index path="KnowledgecategoryWrite" element={<KnowledgeCategoryWrite />} />
            <Route index path="KnowledgeCategoryList" element={<KnowledgeCategoryList />} />
            <Route index path="KnowledgeCategoryModify/:category_idx" element={<KnowledgeCategoryModify />} />
            <Route index path="KnowledgeCategoryView/:category_idx" element={<KnowledgeCategoryView />} />

            <Route index path="KnowledgeWrite" element={<KnowledgeWrite />} />
            <Route index path="KnowledgeList" element={<KnowledgeList />} />
            <Route index path="KnowledgeModify/:idx" element={<KnowledgeModify />} />
            <Route index path="KnowledgeView/:idx" element={<KnowledgeView />} />

            <Route index path="KnowledgecategoryWrite" element={<KnowledgeCategoryWrite />} />
            <Route index path="KnowledgeCategoryList" element={<KnowledgeCategoryList />} />
            <Route index path="KnowledgeCategoryModify/:category_idx" element={<KnowledgeCategoryModify />} />
            <Route index path="KnowledgeCategoryView/:category_idx" element={<KnowledgeCategoryView />} />

            <Route index path="KnowledgeWrite" element={<KnowledgeWrite />} />
            <Route index path="KnowledgeList" element={<KnowledgeList />} />
            <Route index path="KnowledgeModify/:idx" element={<KnowledgeModify />} />
            <Route index path="KnowledgeView/:idx" element={<KnowledgeView />} />

            <Route index path="MissioncategoryWrite" element={<MissionCategoryWrite />} />
            <Route index path="MissionCategoryList" element={<MissionCategoryList />} />
            <Route index path="MissionCategoryModify/:category_idx" element={<MissionCategoryModify />} />
            <Route index path="MissionCategoryView/:category_idx" element={<MissionCategoryView />} />

            <Route index path="MissionWrite/:list_category_idx" element={<MissionWrite />} />
            <Route index path="MissionList" element={<MissionList />} />
            <Route index path="MissionList/:list_category_idx" element={<MissionList />} />
            <Route index path="MissionModify/:idx/:list_category_idx" element={<MissionModify />} />
            <Route index path="MissionView/:idx/:list_category_idx" element={<MissionView />} />

            <Route index path="NoticeList/:board_name" element={<NoticeList />} />
            <Route index path="NoticeList/:board_name/:page" element={<NoticeList />} />
            <Route index path="NoticeWrite/:board_name" element={<NoticeWrite />} />
            <Route index path="NoticeModify/:board_name/:idx/:prevPage" element={<NoticeModify />} />
            <Route index path="NoticeView/:board_name/:idx/:prevPage" element={<NoticeView />} />

            <Route index path="NoticeListClient/:board_name" element={<NoticeListClient />} />
            <Route index path="NoticeListClient/:board_name/:page" element={<NoticeListClient />} />
            <Route index path="NoticeViewClient/:board_name/:idx/:prevPage" element={<NoticeViewClient />} />
            
            <Route index path="ProductWrite" element={<ProductWrite />} />
            <Route index path="ProductList" element={<ProductList />} />
            <Route index path="ProductModify/:prd_idx" element={<ProductModify />} />
            <Route index path="ProductView/:prd_idx" element={<ProductView />} />

            <Route index path="OrderWrite" element={<OrderWrite />} />
            <Route index path="OrderList" element={<OrderList />} />
            <Route index path="OrderModify/:ord_idx" element={<OrderModify />} />
            <Route index path="OrderView/:ord_idx" element={<OrderView />} />

            <Route index path="PaymentWrite" element={<PaymentWrite />} />
            <Route index path="PaymentList" element={<PaymentList />} />
            <Route index path="PaymentView/:pay_idx" element={<PaymentView />} />

            <Route index path="RefundList" element={<RefundList />} />

            <Route index path="StudentList" element={<StudentList />} />
            <Route index path="StudentWrite" element={<StudentWrite />} />
            <Route index path="StudentView/:mst_id" element={<StudentView />} />
            <Route index path="StudentModify/:mst_id" element={<StudentModify />} />

            <Route index path="PackageList" element={<PackageList />} />

          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </StyledEngineProvider>
  );
}

export default App;