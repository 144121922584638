// 서버에 있는 이미지 경로를 리턴하는 함수
export function returnImgSrc (ImgPath, ImgFileName) 
{      
    let ServerName = process.env.REACT_APP_IMG_URL;
    let retunValue = "";

    if (ImgPath !== "" && ImgFileName !== "")
      retunValue = ServerName + ImgPath + ImgFileName;

    return retunValue;
}

// 카테고리 이름을 리턴하는 함수
export function returnCategoryName (category_array, category_idx) 
{   
    // console.log("returnCategoryName : category_array : ", category_array, " category_idx : ", category_idx);   
    let retunValue = "";

    for (var key in category_array)
    {
        if (category_array[key].category_idx === category_idx)
        {
            retunValue = category_array[key].category_name;
            break;
        }
    }

    return retunValue;
}

// 천단위 콤마를 찍어주는 함수
export function priceToString(price) 
{
    if (price === null)
        price = 0;

    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

// 주문상태를 문자열로 돌려주는 함수
export function returnOrderState(state) 
{
    let returnValue = "";

    // 주문상태코드 / 결제대기(W : wait), 결제오류(IA : inactive), 결제완료(A : active), 무료쿠폰(F : free)
    switch (state)
    {
        case "W":
            returnValue = "결제대기";
            break;

        case "IA":
            returnValue = "결제오류";
            break;

        case "A":
            returnValue = "결제완료";
            break;

        case "F":
            returnValue = "무료쿠폰";
            break;

        default:
            returnValue = "주문에러";
    }

    return returnValue;
}

// 결제상태를 문자열로 돌려주는 함수
// *** 결제상태 ***
// ready(브라우저 창 이탈, 가상계좌 발급 완료 등 미결제 상태), 
// paid(결제완료), 
// failed(신용카드 한도 초과, 체크카드 잔액 부족, 브라우저 창 종료 또는 취소 버튼 클릭 등 결제실패 상태)
export function returnPaymentState(state) 
{
    let returnValue = "";

    
    switch (state)
    {
        case "ready":
            returnValue = "미결제";
            break;

        case "paid":
            returnValue = "결제완료";
            break;

        case "failed":
            returnValue = "결제실패";
            break;

        default:
            returnValue = "결제에러";
    }

    return returnValue;
}

// 환불상태를 문자열로 돌려주는 함수
// *** 환불 상태 ***
// 환불상태 / cancelled(환불), failed(실패 - 에러메세지 참조)
export function returnRefundState(state) 
{
    let returnValue = "";

    switch (state)
    {
        case "cancelled":
            returnValue = "환불완료";
            break;

        case "failed":
            returnValue = "환불실패";
            break;

        default:
            returnValue = "결제에러";
    }

    return returnValue;
}


export function dateChangeReturn(changeDate)
{
    let returnValue = "";

    if (changeDate !== null && changeDate !== undefined)
    {
        const returnDate = new Date(changeDate);
        const year = returnDate.getFullYear(); 
        const month = returnDate.getMonth() + 1; 
        const date = returnDate.getDate();
        returnValue = year + '.' + month + '.' + date;
        // console.log("date : ", returnValue);
    }
    else
    {
        returnValue = '';
    }
	
	return returnValue;
};


// 권한을 체크하는 함수
export function checkAuth (userInfo) {
	
	if (!userInfo.isLoggedIn)
	{
		return false;
	}
	else
	{
		if (userInfo.user.type !== 1)
			return false
	}
		
	return true;
}

// 결제 상세 페이지에서 날짜의 포멧을 바꿔주는 함수
// 2022-06-17 15:16:54 -> 2022.03.25 - 16시35분
export function payDateChangeReturn(changeDate)
{
    let returnValue = "";

    if (changeDate !== null && changeDate !== undefined)
    {
        const returnDate = new Date(changeDate);
        const year = returnDate.getFullYear(); 
        const month = returnDate.getMonth() + 1; 
        const date = returnDate.getDate();
        const hour = returnDate.getHours();
        const minute = returnDate.getMinutes();

        returnValue = `${year}.${month}.${date} - ${hour}시 ${minute}분`;
        // console.log("date : ", returnValue);
    }
    else
    {
        returnValue = '';
    }
	
	return returnValue;
};

// 몇 퍼센트인지 구하기
// 35는 350의 몇 퍼센트인지 구하기
// percent(35, 350)
export function percent(par,total) 
{
    return (par / total) * 100;
}

