import * as React from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';

import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { packageUseStudent, packageUseNotStudent, packageUseStudentModify, packageStudentList } from "../actions/package";

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export default function TransferList(props) {

  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);
  const [useCount, setUseCount] = React.useState(0);

  const useStudent = useSelector(state => state.myPackage.useStudent);
  console.log("useStudent : ", useStudent);
  const useNotStudent = useSelector(state => state.myPackage.useNotStudent);
  console.log("useNotStudent : ", useNotStudent);
  const studentList = useSelector(state => state.myPackage.studentList);
  console.log("studentList : ", studentList);

  const dispatch = useDispatch();

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  // 학습자를 추가하는 함수
  const handleCheckedRight = () => {
    // 추가되는 학생의 배열값  
    console.log("leftChecked : ", leftChecked);

    console.log("handleCheckedRight useCount : ", useCount);
    console.log("props.orderCount : ", props.orderCount);
    console.log("leftChecked.length : ", leftChecked.length);

    if(leftChecked.length + useCount > props.orderCount)
    {
        alert("1패키지 추가 인원을 초과했습니다.");
        return;
    }
    
    if (useCount > props.orderCount)
    {
        alert("2패키지 추가 인원을 초과했습니다.");
        return;
    }

    let arr = new Array();

    for (let i in leftChecked)
    {
        for (let j in studentList)
        {
            if (studentList[j].mst_name === leftChecked[i])
                arr.push(studentList[j].mst_idx);
        }
    }

    const SendJaon = {
        mst_idx: arr,
        package_no: props.packageNo
    }

    console.log("SendJaon : ", SendJaon);

    // 여기서 디스패치로~
    dispatch(packageUseStudentModify(SendJaon))
    .then(data => 
    {
        console.log("data : ", data);
    })
    .catch(e => {
        console.log(e);
    });

    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
    setUseCount(useCount + leftChecked.length);
    props.setUseCount(useCount + leftChecked.length);
  };

  // 학습자를 빼는 함수
  const handleCheckedLeft = () => {
    // 삭제되는 학생의 배열값  
    console.log("rightChecked : ", rightChecked);

    console.log("handleCheckedLeft useCount : ", useCount);

    let arr = new Array();

    for (let i in rightChecked)
    {
        for (let j in studentList)
        {
            if (studentList[j].mst_name === rightChecked[i])
                arr.push(studentList[j].mst_idx);
        }
    }

    const SendJaon = {
        mst_idx: arr,
        package_no: ""
    }

    // 여기서 디스패치로~
    dispatch(packageUseStudentModify(SendJaon))
    .then(data => 
    {
        console.log("data : ", data);
    })
    .catch(e => {
        console.log(e);
    });

    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
    setUseCount(useCount - rightChecked.length);
    props.setUseCount(useCount - rightChecked.length);
  };

    useEffect(() => {

        // 여기서 디스패치로~
        dispatch(packageStudentList())              
        .then(data => 
        {
            let arr = new Array();

            console.log("data : ", data);
            for (const key in data) 
            {
                console.log(data[key].mst_name);
                arr.push(data[key].mst_name);
            }
        })
        .catch(e => {
            console.log(e);
        });

        // 여기서 디스패치로~
        dispatch(packageUseStudent(props.packageNo))              
        .then(data => 
        {
            let arr = new Array();

            console.log("data : ", data);
            for (const key in data) 
            {
                console.log(data[key].mst_name);
                arr.push(data[key].mst_name);
            }
            setRight(arr);
            console.log("data.length : ", data.length);
            setUseCount(data.length);
            props.setUseCount(data.length);
        })
        .catch(e => {
            console.log(e);
        });

        dispatch(packageUseNotStudent())
        .then(data => 
        {
            let arr = new Array();

            console.log("data : ", data);
            for (const key in data) 
            {
                console.log(data[key].mst_name);
                arr.push(data[key].mst_name);
            }
            setLeft(arr);
        })
        .catch(e => {
            console.log(e);
        });
        
        return () => {      // 언마운트
            console.log("언마운트!");
        };

    }, [dispatch])

  const customList = (title, items) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={
              numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              'aria-label': 'all items selected',
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        sx={{
          width: 200,
          height: 230,
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item>{customList('미사용자', left)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList('사용자', right)}</Grid>
    </Grid>
  );
}