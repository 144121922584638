import {
  LIST_PACKAGE,
  USE_STUDENT_PACKAGE,
  USE_NOT_STUDENT_PACKAGE,
  USE_UPDATE_PACKAGE,
  STUDENT_INFO_PACKAGE,
  STUDENT_LIST_PACKAGE
} from "../actions/types";

const initialState = [];

const packageReducer = (myPackage = initialState, action) => {
  const { type, payload } = action;

  // console.log("packageReducer Call!");
  // console.log("type : ", type);
  // console.log("payload : ", payload);

  switch (type) {

    case LIST_PACKAGE:
      return {
        ...myPackage,
        list : payload,
      };

    case USE_STUDENT_PACKAGE:
      return {
        ...myPackage,
        useStudent : payload,
      };

    case USE_NOT_STUDENT_PACKAGE:
      return {
        ...myPackage,
        useNotStudent : payload,
      };
    
    case STUDENT_INFO_PACKAGE:
      return {
        ...myPackage,
        studentInfo : payload,
      };

    case USE_UPDATE_PACKAGE:
      return payload;

    case STUDENT_LIST_PACKAGE:
      return {
        ...myPackage,
        studentList : payload,
      };

    default:
      return myPackage;
  }
};

export default packageReducer;