import http from "../http-common";

const login = data => {
  return http.post("users/login", data);
};

const logout = () => {
  localStorage.removeItem("user");
};

const register = data => {
  return http.post("users/commonUser", data);
};

const socialUserCheck = (type, unique, email, name) => {
  return http.get(`/users/socialUser/${type}/${unique}/${email}/${name}`);
};

const groupRegister = data => {
  return http.post("users/groupUser", data);
};

const passwordChange = data => {
  return http.post("users/commonUserPasswordChange", data);
};

const loginReload = () => {
  return http.get(`/users/loginReload`);
};

const passwordUpdate = data => {
  return http.put("users/commonUserPassword", data);
};

const AuthService = {
  login,
  logout,
  register,
  socialUserCheck,
  groupRegister,
  passwordChange,
  loginReload,
  passwordUpdate,
};

export default AuthService;