import * as React from 'react';
import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import styles from "../../css/common.module.css";
import $ from 'jquery';

import { orderList, orderUse } from "../../actions/order";
import { paymentCancel, paymentVbankCancel } from "../../actions/payment";

import * as common from "../../lib";

export default function MypagePayInfo() {

	const order = useSelector(state => state.order);
    // console.log("order : ", order);

	const dispatch = useDispatch();	

	const mypage_tab_btn_areaRef =  useRef();
    const hover_bgRef =  useRef();
    const onRef =  useRef();

	const pay_info_list_pagebtn_areaRef =  useRef();

	useEffect(() => {

		// 여기에서 jQuery를 초기화 합니다.
		// jquery ready 호출. jquery3 부터 변경됨
		$(function(){
			// console.log("jquery ready!");

            //마이페이지 탭 버튼(활성화 효과)
            $(mypage_tab_btn_areaRef.current).children('li').children('a').on('click',function(){
                let tg=$(this);
                
                $(mypage_tab_btn_areaRef.current).children('li').removeClass('on');
                tg.addClass('on');
            });
            
            //마이페이지 탭 버튼(후버 효과)
            $(window).on("resize", function(){
                if (window.innerWidth < 1200) 
                { 
                    // $('.mypage_tab_btn_area li.hover_bg').css('display','none');
                    $(mypage_tab_btn_areaRef.current).find(`li.`+`${styles.hover_bg}`).css('display','none');
                }
                else
                {
                    let nowbtnNum=$(mypage_tab_btn_areaRef.current).find(`li.`+`${styles.on}`).index();
                    let hoverBg=$(mypage_tab_btn_areaRef.current).find(`li.`+`${styles.hover_bg}`);

                    $(mypage_tab_btn_areaRef.current).children('div').children('ul').hover(function()
                    {
                        $(mypage_tab_btn_areaRef.current).children('div').children('ul').children('li').removeClass(`${styles.on}`);
                        hoverBg.css('display','block');
                        
                        $(mypage_tab_btn_areaRef.current).children('div').children('ul').children('li').on('mouseenter',function()
                        {
                            let tgIndex=$(this).index();
                            
                            if(tgIndex===1)
                            {
                                hoverBg.stop().animate({left:'332px'},100).animate({width:'116px'},100);
                            }
                            else if(tgIndex===2)
                            {
                                hoverBg.stop().animate({left:'460px'},100).animate({width:'150px'},100);
                            }
                            else if(tgIndex===3)
                            {
                                hoverBg.stop().animate({left:'620px'},100).animate({width:'116px'},100);
                            }
                            else if(tgIndex===4)
                            {
                                hoverBg.stop().animate({left:'742px'},100).animate({width:'116px'},100);
                            };
                        });
                        
                    },
                    function()
                    {
                        $(mypage_tab_btn_areaRef.current).children('div').children('ul').children('li').eq(nowbtnNum).addClass(`${styles.on}`);
                        hoverBg.css('display','none');
                        
                        if(nowbtnNum===0)
                        {
                            hoverBg.css({left:'332px'}).css({width:'116px'});
                        }
                        else if(nowbtnNum===1)
                        {
                            hoverBg.css({left:'460px'}).css({width:'150px'});
                        }
                        else if(nowbtnNum===2)
                        {
                            hoverBg.css({left:'620px'}).css({width:'116px'});
                        }
                        else if(nowbtnNum===3)
                        {
                            hoverBg.css({left:'742px'}).css({width:'116px'});
                        };
                    });
                    
                }
            }).resize();
			
			//결제 정보 페이지 페이징 버튼	
			$(pay_info_list_pagebtn_areaRef.current).find('li').on('click',function()
			{
				let tg=$(this);
				let num=tg.index();
				let prev;
				let next;
				let last;
				let nowBtn=$(pay_info_list_pagebtn_areaRef.current).children(`.${styles.on}`);
				let nowIndex=nowBtn.index();
				
				$(pay_info_list_pagebtn_areaRef.current).find('li').removeClass(`${styles.on}`);
				
				if(tg.hasClass(`${styles.arrow_btn}`)==true)
				{
					if(tg.hasClass(`${styles.first}`)==true)
					{
						tg=$(pay_info_list_pagebtn_areaRef.current).find('li').eq(2);
					}
					else if(tg.hasClass(`${styles.prev}`)==true)
					{
						prev=nowIndex-1;
						
						if(nowIndex===2)
						{
							tg=$(pay_info_list_pagebtn_areaRef.current).find('li').eq(2);
						}else
						{
							tg=$(pay_info_list_pagebtn_areaRef.current).find('li').eq(prev);
						};
						// console.log("prev click");
					}
					else if(tg.hasClass(`${styles.next}`)==true)
					{
						let lastNum=$(pay_info_list_pagebtn_areaRef.current).find('li').last().index();
						next=nowIndex+1;
						last=lastNum-2;
						
						if(nowIndex===last)
						{
							tg=$(pay_info_list_pagebtn_areaRef.current).find('li').eq(last);
						}else
						{
							tg=$(pay_info_list_pagebtn_areaRef.current).find('li').eq(next);
						};
						// console.log("next click");
					}
					else if(tg.hasClass(`${styles.last}`)==true)
					{
						last=num-2;
						tg=$(pay_info_list_pagebtn_areaRef.current).find('li').eq(last);
					};
					
					tg.addClass(`${styles.on}`);
					
				}
				else
				{
					tg.addClass(`${styles.on}`);
				};
			});

		});

		// 여기서 디스패치로~
        dispatch(orderList())
        .then(data => 
        {
            // console.log("data : ", data);
        })
        .catch(e => {
            console.log(e);
        });

	}, [$, dispatch]);


	const refundClick = (event, ord_no, pay_idx, pay_no, imp_uid, pay_amt, pay_opt_cd) => {

		event.preventDefault();
		// console.log("ord_no : ", ord_no);
		// console.log("pay_idx : ", pay_idx);
		// console.log("pay_no : ", pay_no);
		// console.log("imp_uid : ", imp_uid);
		// console.log("pay_amt : ", pay_amt);
		// console.log("pay_opt_cd : ", pay_opt_cd);

		// 한불을 위한 정보를 셋팅해줍니다.
		let refund_holder = "";  	// [가상계좌 환불시 필수입력] 환불 수령계좌 예금주
		let refund_bank = ""; 		// [가상계좌 환불시 필수입력] 환불 수령계좌 은행코드(ex. KG이니시스의 경우 신한은행은 88번)
		let refund_account = ""; 	// [가상계좌 환불시 필수입력] 환불 수령계좌 번호

		if (pay_opt_cd === "vbank")		// 가상계좌의 경우엔 정보를 입력받아야 합니다.
		{
			// 팝업을 띄워서 여기에서 입력을 받습니다.
			let input = prompt("가상계좌는 환불계좌 정보가 필요합니다. (예금주/은행코드/계좌번호)", "예금주/은행코드/계좌번호");
			// console.log(input);

			if (input === "" || input === "예금주/은행코드/계좌번호")
				return alert("환불 정보가 없습니다. 다시 해주세요.");

			let strSplit = input.split('/');
			refund_holder = strSplit[0];
			refund_bank = strSplit[1];
			refund_account = strSplit[2];

			// console.log("refund_holder - refund_bank - refund_account : ", refund_holder, "-", refund_bank, "-", refund_account);
		}

		// 환불이 가능한지 검사합니다.
		// 여기서 디스패치로~
        dispatch(orderUse(ord_no))
        .then(data => 
        {
            // console.log("data : ", data);
			if (data === 0)
			{
				if (!window.confirm("환불가능합니다. 환불 하시겠습니까?")) 
				{
					// 취소(아니오) 버튼 클릭 시 이벤트
					return;
				} 
				else 
				{

					if (pay_opt_cd !== "vbank")			// 카드, 실시간계좌이체 환불일 때
					{
						// 확인(예) 버튼 클릭 시 이벤트
						const cancelPay = 
						{ 
							pay_idx: pay_idx,
							pay_no: pay_no,
							merchant_uid: ord_no, // 주문번호
							imp_uid: imp_uid, // 아임포트 고유번호
							cancel_request_amount: parseInt(pay_amt), // 환불금액
							reason: "야미 홈페이지에서 사용자의 요청에 의한 환불", // 환불사유
							refund_holder: "", // [가상계좌 환불시 필수입력] 환불 수령계좌 예금주
							refund_bank: "", // [가상계좌 환불시 필수입력] 환불 수령계좌 은행코드(예: KG이니시스의 경우 신한은행은 88번)
							refund_account: "" // [가상계좌 환불시 필수입력] 환불 수령계좌 번호
						}

						// console.log("cancelPay : ", cancelPay);

						// 여기서 디스패치로~
						dispatch(paymentCancel(cancelPay))
						.then(data => 
						{
							// console.log("data : ", data);
							
							if (!data.success)
								alert(data.msg);

							window.location.reload();
						})
						.catch(e => {
							console.log(e);
						});

						return;
					}
					else	// 가상계좌 환불일 때
					{
							// 확인(예) 버튼 클릭 시 이벤트
							const cancelPay = 
							{ 
								pay_idx: pay_idx,
								pay_no: pay_no,
								merchant_uid: ord_no, // 주문번호
								imp_uid: imp_uid, // 아임포트 고유번호
								cancel_request_amount: parseInt(pay_amt), // 환불금액
								reason: "야미 홈페이지에서 사용자의 요청에 의한 환불", // 환불사유
								refund_holder: refund_holder, // [가상계좌 환불시 필수입력] 환불 수령계좌 예금주
								refund_bank: refund_bank, // [가상계좌 환불시 필수입력] 환불 수령계좌 은행코드(예: KG이니시스의 경우 신한은행은 88번)
								refund_account: refund_account // [가상계좌 환불시 필수입력] 환불 수령계좌 번호
							}

							// console.log("cancelPay : ", cancelPay);

							// 여기서 디스패치로~
							dispatch(paymentVbankCancel(cancelPay))
							.then(data => 
							{
								// console.log("data : ", data);
								
								if (!data.success)
									alert(data.msg);

								window.location.reload();
							})
							.catch(e => {
								console.log(e);
							});

							return;
					}
					
				}
			}
			else
			{
				alert("패키지를 사용하시면 환불이 되지 않습니다. 약관을 참조하세요.");
				return;
			}
        })
        .catch(e => {
            console.log(e);
        });
	}

  return (
    <>
      	{/* Header 분리됨 MainLayout Header에 있음 */}

		<div className={styles.mypage_top_line_banner}>
			<div className={styles.inner}>
				<span>학부모 페이지</span>
			</div>
		</div>
		<div className={styles.mypage_tab_btn_area} ref={mypage_tab_btn_areaRef}>
			<div className={styles.inner}>
				<ul>
					<li className={styles.hover_bg} ref={hover_bgRef}></li>
					<li><a href="/UserMypage/Report">학습 리포트</a></li>
					<li><a href="/UserMypage/StudentInfo">학습자 정보/관리</a></li>
					<li><a href="/UserMypage/ParentsInfo">학부모 정보</a></li>
					<li className={styles.on} ref={onRef}><a href="/UserMypage/PayInfo">결제 정보</a></li>
				</ul>
			</div>
		</div>
		<div className={`${styles.mypage_tab} ${styles.mypage_pay_info}`}>
			<div className={styles.inner}>
				<span className={styles.my_tit}>결제 정보</span>
				<div className={styles.pay_info_area}>
					<div className={styles.title}>
						<div></div>
						<p>결제 내역</p>
					</div>
					<ul className={styles.pay_info_list_wrap}>
						<li className={styles.list_head}>
							<div>
								<span>주문일자</span>
							</div>
							<div>
								<span>주문상품</span>
							</div>
							<div>
								<span>학습자</span>
							</div>
							<div>
								<span>결제금액</span>
							</div>
							<div>
								<span>결제상태</span>
							</div>
							<div></div> 
						</li>

						{
							order.filter(row => row.ord_stat_cd === 'A')?.length === 0
							? <li className={styles.list}>
								<ul
								style={{
									display:'flex',
									alignItems:'center',
									justifyContent:'center',
									width:'100%',
									height:'100%'
								}}>
									데이터 없음
								</ul>
							</li>
							: null

						}

						{order && order.filter(row => row.ord_stat_cd === 'A')?.map((row, index) => (
						
						<li className={styles.list} key={index}>
							<ul className={styles.top_area}>
								<li>
									<span className={styles.date}>{common.dateChangeReturn(row.ord_date || "0000-00-00")}</span>
								</li>
								<li>
									<p className={`${styles.name} ${styles.ellip}`}>{row.prd_name || ""}</p>
								</li>
							</ul>
							<ul className={styles.bottom_area}>
								<li>
									<span className={`${styles.student_name} ${styles.ellip}`}>{row.mem_name || ""}</span>
								</li>
								<li>
									<span className={styles.fee}>{common.priceToString(row.ord_amt)}</span>
								</li>
								<li>
									<span className={styles.pay_condition}>{row.ref_stat_cd === "cancelled" ? "환불" : common.returnOrderState(row.ord_stat_cd)}</span>
								</li>
								<li>
									<a href={`/UserMypage/PayInfoMore/${row.ord_idx}`}>결제 상세보기</a>
									{
										(row.ref_stat_cd !== "cancelled" && row.ord_stat_cd === "A") ? <a href="#!" onClick={(e) => refundClick(e, row.ord_no, row.pay_idx, row.pay_no, row.imp_uid, row.pay_amt, row.pay_opt_cd)}>환불</a> : ""
									}						
								</li>
							</ul>
						</li>

						))}
					</ul>
					{/* <ul className={styles.pay_info_list_pagebtn_area} ref={pay_info_list_pagebtn_areaRef}>
						<li className={`${styles.arrow_btn} ${styles.first}`}><a href="#!"><img src="/assets/first_btn@2x.png" alt="첫번째 페이지로 이동"/></a></li>
						<li className={`${styles.arrow_btn} ${styles.prev}`}><a href="#!"><img src="/assets/prev_btn@2x.png" alt="이전 페이지로 이동"/></a></li>
						<li className={styles.on}><a href="#!">1</a></li>
						<li><a href="#!">2</a></li>
						<li><a href="#!">3</a></li>
						<li><a href="#!">4</a></li>
						<li><a href="#!">5</a></li>
						<li><a href="#!">6</a></li>
						<li><a href="#!">7</a></li>
						<li><a href="#!">8</a></li>
						<li><a href="#!">9</a></li>
						<li><a href="#!">10</a></li>
						<li className={`${styles.arrow_btn} ${styles.next}`}><a href="#!"><img src="/assets/next_btn@2x.png" alt="다음 페이지로 이동"/></a></li>
						<li className={`${styles.arrow_btn} ${styles.last}`}><a href="#!"><img src="/assets/last_btn@2x.png" alt="마지막 페이지로 이동"/></a></li>
					</ul> */}
				</div>
			</div>{/* inner */}
		</div>{/* mypage_tab */}
		
		{/* Footer 분리됨 MainLayout Footer에 있음 */}
    </>
  );
}