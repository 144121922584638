import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { FormControl, TextField, Select, MenuItem, InputLabel } from '@mui/material';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';

import { useState, useEffect } from 'react';

import { useDispatch, useSelector } from "react-redux";
import { missionModify, missionSelect } from "../../../actions/mission";
import { missionCategoryList } from "../../../actions/mission_category";
import { useNavigate, useParams } from 'react-router-dom';

export default function MissionModify() {

    const initialMissionState = {
        id: null,  
        category_idx: null,
        orderby: null,
        type: "G", 
        item_reward: null,
        indicators: [],
        use_code_block: [],
        core_code_block: [],
    };

    const [mission, setMission] = useState(initialMissionState);

    const category = useSelector(state => state.mission_category);
    console.log("category : ", category);

    const idx  = useParams().idx;
    console.log("idx : ", idx);

    let list_category_idx  = useParams().list_category_idx;
    console.log("list_category_idx : ", list_category_idx);

    if (list_category_idx === undefined)
      list_category_idx = 1;

    console.log("list_category_idx : ", list_category_idx);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleInputChange = event => {

        const { name, value } = event.target;
        setMission({ ...mission, [name]: value });
    };

    const handleMissionChange = event => {
        const { name, value } = event.target;
        console.log("name : ", name);
        console.log("value : ", value);

        let nameSplit = name.split("-");
        let index = parseInt(nameSplit[0]);
        let selectType = nameSplit[1];
        let indicators = [...mission.indicators];

        if (selectType === "type")
            indicators[index] = {"type": value, "point": mission.indicators[index].point};
        else if (selectType === "point")
            indicators[index] = {"type": mission.indicators[index].type, "point": value};

        setMission({ ...mission, ["indicators"]: indicators });
    }

    const handleUseCodeChange = event => {
        const { name, value } = event.target;
        console.log("name : ", name);
        console.log("value : ", value);

        let nameSplit = name.split("-");
        let index = parseInt(nameSplit[0]);

        let use_code_block = [...mission.use_code_block];
        use_code_block[index] = {"id": value};

        setMission({ ...mission, ["use_code_block"]: use_code_block });
    }

    const handleCoreCodeChange = event => {
        const { name, value } = event.target;
        console.log("name : ", name);
        console.log("value : ", value);

        let nameSplit = name.split("-");
        let index = parseInt(nameSplit[0]);

        let core_code_block = [...mission.core_code_block];
        core_code_block[index] = {"id": value};

        setMission({ ...mission, ["core_code_block"]: core_code_block });
    }

    const handleSubmit = (event) => {

        event.preventDefault();

        console.log("mission : ", mission);

        const sendData = {
            mis_idx:idx,
            mis_id: mission.id,  
            mis_category_idx: mission.category_idx,
            mis_orderby: mission.orderby,
            mis_type: mission.type, 
            mis_item_reward: mission.item_reward,
            indicators: mission.indicators,
            use_code_block: mission.use_code_block,
            core_code_block: mission.core_code_block,
        }

        // // 여기서 디스패치로~
        dispatch(missionModify(sendData))
        .then(data => 
        {
            console.log("data : ", data);
            if (!data.success)
                alert(data.msg);
            
            navigate(`/dashboard/MissionList/${list_category_idx}`);
        })
        .catch(e => {
            console.log(e);
        });
    };

    const clickAddIndicators = () => {
        setMission({...mission, ["indicators"]: mission.indicators.concat([{"type": "", "point": ""}])});
    };

    const clickDelIndicators = (event, index) => {
        event.preventDefault();

        mission.indicators.splice(index, 1);
        setMission({...mission, ["indicators"]: mission.indicators});
    };

    const clickAddUsecode = () => {
        setMission({...mission, ["use_code_block"]: mission.use_code_block.concat([{"id": ""}])});
    };

    const clickDelUsecode = (event, index) => {
        event.preventDefault();

        mission.use_code_block.splice(index, 1);
        setMission({...mission, ["use_code_block"]: mission.use_code_block});
    };

    const clickAddCorecode = () => {
        setMission({...mission, ["core_code_block"]: mission.core_code_block.concat([{"id": ""}])});
    };

    const clickDelCorecode = (event, index) => {
        event.preventDefault();

        mission.core_code_block.splice(index, 1);
        setMission({...mission, ["core_code_block"]: mission.core_code_block});
    };


    useEffect(() => {

        console.log("dispatch missionCategoryList");
        // 여기서 디스패치로~
        dispatch(missionCategoryList())
        .then(data => 
        {
            console.log("data : ", data);
        })
        .catch(e => {
            console.log(e);
        });

        dispatch(missionSelect(idx))
        .then(data => 
        {
            console.log("missionSelect data : ", data);
            const initData = {
                id: data[0].mis_id,  
                category_idx: data[0].mis_category_idx,
                orderby: data[0].mis_orderby,
                type: data[0].mis_type, 
                item_reward: data[0].mis_item_reward,
                indicators: data[0].indicators,
                use_code_block: data[0].use_code_block,
                core_code_block: data[0].core_code_block,
            }
            setMission(initData);
        })
        .catch(e => {
            console.log(e);
        });

        return () => {      // 언마운트
            console.log("언마운트!");
        };

    }, [dispatch])

  return (
    <Card sx={{ minWidth: 360, width:'100%' }}>
      <CardContent>
      <FormControl sx={{minWidth:360}}>
            <TextField
            id="id"
            label="미션 아이디"
            helperText="미션 아이디를 입력해주세요."
            variant="standard"
            name="id"
            onChange={handleInputChange}
            value={mission.id || ""}
            />

            <br></br>

            <FormControl sx={{width:250}}>
            <InputLabel id="category_idx">카테고리</InputLabel>
            <Select
            labelId="category_idx"
            id="category_idx"
            value={mission.category_idx || ""}
            label="카테고리"
            onChange={handleInputChange}
            name="category_idx"
            >
            {category && category.map((item, index) => (
            <MenuItem value={item.mis_category_idx} key={index}>
                {item.mis_category_name}
            </MenuItem>
            ))}
            </Select>
            </FormControl>

            <br></br>

            <TextField
            disabled
            id="orderby"
            label="순서"
            helperText="순서의 수정은 리스트에서만 가능합니다."
            type="number"
            variant="standard"
            name="orderby"
            onChange={handleInputChange}
            sx={{width:200}}
            inputProps={{
                inputMode: 'numeric', 
                pattern: '[0-9]*',
                step: 1,
                min: 1,
                max: 1000,
                type: 'number',
            }}
            value={mission.orderby || ""}
            />
            
            <br></br>

            <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">타입</FormLabel>
            <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="type"
                value={mission.type || ""}
                onChange={handleInputChange}
            >
                <FormControlLabel value="G" control={<Radio />} label="일반 미션" />
                <FormControlLabel value="E" control={<Radio />} label="기타 미션" />
            </RadioGroup>
            </FormControl>

            <TextField
            id="item_reward"
            label="아이템 리워드"
            helperText="정답 설명을 입력해주세요."
            variant="standard"
            name="item_reward"
            onChange={handleInputChange}
            value={mission.item_reward || ""}
            />
            
            <Button onClick={clickAddIndicators} 
            style={{
                marginBottom:'10px'
            }}
            >지표추가</Button>

            {
              mission.indicators.map(((item, index) => (
                <div key={index}
                    style={{
                        marginBottom:'15px'
                    }}
                >
                <TextField 
                  sx={{marginRight:'10px'}}
                  id={`${index}-type`}
                  name={`${index}-type`}
                  label={`지표 타입`}
                  onChange={handleMissionChange}  
                  value={mission.indicators[index].type || ""}
                />
                <TextField 
                  id={`${index}-point`}
                  name={`${index}-point`}
                  label={`지표 포인트`}
                  onChange={handleMissionChange}  
                  value={mission.indicators[index].point || ""}
                />
                <Button onClick={(e)=>{clickDelIndicators(e, index)}}>삭제</Button>
                </div>
              )))
            }

            <Button onClick={clickAddUsecode} 
            style={{
                marginBottom:'10px'
            }}
            >주요코딩 블록추가</Button>

            {
              mission.use_code_block.map(((item, index) => (
                <div key={index}
                    style={{
                        marginBottom:'15px'
                    }}
                >
                <TextField 
                  sx={{marginRight:'10px'}}
                  id={`${index}-use_code_block`}
                  name={`${index}-use_code_block`}
                  label={`블록 아이디`}
                  onChange={handleUseCodeChange}  
                  value={mission.use_code_block[index].id || ""}
                />
                <Button onClick={(e)=>{clickDelUsecode(e, index)}}>삭제</Button>
                </div>
              )))
            }

            <Button onClick={clickAddCorecode} 
            style={{
                marginBottom:'10px'
            }}
            >핵심코딩 블록추가</Button>

            {
              mission.core_code_block.map(((item, index) => (
                <div key={index}
                    style={{
                        marginBottom:'15px'
                    }}
                >
                <TextField 
                  sx={{marginRight:'10px'}}
                  id={`${index}-core_code_block`}
                  name={`${index}-core_code_block`}
                  label={`블록 아이디`}
                  onChange={handleCoreCodeChange}  
                  value={mission.core_code_block[index].id || ""}
                />
                <Button onClick={(e)=>{clickDelCorecode(e, index)}}>삭제</Button>
                </div>
              )))
            }
            
        </FormControl>
      </CardContent>
      <CardActions>
        <Button onClick={handleSubmit} size="small">미션 수정</Button>
      </CardActions>
    </Card>
  );
}