import http from "../http-common";

const studentCreate = data => {
  return http.post("student/student", data);
};

const studentGetAll = () => {
  return http.get("users/commonStudent");
};

const studentDelete = data => {
  return http.delete("student/student", {data : {mst_idx : data}});
};

const studentModify = data => {
  return http.put("student/student", data);
};

const studentSelect = data => {
  return http.get(`student/student/${data}`);
};

const studentGetCount = () => {
  return http.get("users/commonStudent/studentCount");
};

const studentIdCheck = data => {
  return http.get(`student/studentIDCheck/${data}`);
};

const studentAddCreate = data => {
  return http.post("student/studentAdd", data);
};

const studentPayInfo = data => {
  return http.get(`student/studentPayInfo/${data}`);
};

const StudentService = {
  studentCreate,
  studentGetAll,
  studentDelete,
  studentModify,
  studentSelect,
  studentGetCount,
  studentIdCheck,
  studentAddCreate,
  studentPayInfo,
};

export default StudentService;
