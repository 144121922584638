import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import styles from "../../css/common.module.css";
import $ from 'jquery';
import * as common from "../../lib";

import { studentList } from "../../actions/student";
import { userSelect } from "../../actions/users";
import { packageList, packageStudentInfo, packageUseStudentModify } from "../../actions/package";

export default function MypageStudentInfo() {

	const [totalPackageCount, setTotalPackageCount] = useState(0);

	/************* 데이터 처리 영역 *************/

	const authState = useSelector(state => state.auth);
    // console.log("authState : ", authState);

	const parent = useSelector(state => state.users.user);
    // console.log("parent : ", parent);

	const student = useSelector(state => state.student.list);
    // console.log("student : ", student);

	const myPackage = useSelector(state => state.myPackage.list);
    // console.log("myPackage : ", myPackage);

    const studentInfo = useSelector(state => state.myPackage.studentInfo);
    // console.log("studentInfo : ", studentInfo);

	const dispatch = useDispatch();	

	/************* JQuery 처리 영역 *************/

	const mypage_tab_btn_areaRef =  useRef();
    const hover_bgRef =  useRef();
    const onRef =  useRef();

	useEffect(() => {

		// 여기에서 jQuery를 초기화 합니다.
		// jquery ready 호출. jquery3 부터 변경됨
		$(function(){
			// console.log("jquery ready!");

            //마이페이지 탭 버튼(활성화 효과)
            $(mypage_tab_btn_areaRef.current).children('li').children('a').on('click',function(){
                let tg=$(this);
                
                $(mypage_tab_btn_areaRef.current).children('li').removeClass('on');
                tg.addClass('on');
            });
            
            //마이페이지 탭 버튼(후버 효과)
            $(window).on("resize", function(){

                if (window.innerWidth < 1200) 
                { 
                    // $('.mypage_tab_btn_area li.hover_bg').css('display','none');
                    $(mypage_tab_btn_areaRef.current).find(`li.`+`${styles.hover_bg}`).css('display','none');
                }
                else
                {
                    let nowbtnNum=$(mypage_tab_btn_areaRef.current).find(`li.`+`${styles.on}`).index();
                    let hoverBg=$(mypage_tab_btn_areaRef.current).find(`li.`+`${styles.hover_bg}`);

                    $(mypage_tab_btn_areaRef.current).children('div').children('ul').hover(function()
                    {
                        $(mypage_tab_btn_areaRef.current).children('div').children('ul').children('li').removeClass(`${styles.on}`);
                        hoverBg.css('display','block');
                        
                        $(mypage_tab_btn_areaRef.current).children('div').children('ul').children('li').on('mouseenter',function()
                        {
                            let tgIndex=$(this).index();
                            
                            if(tgIndex===1)
                            {
                                hoverBg.stop().animate({left:'332px'},100).animate({width:'116px'},100);
                            }
                            else if(tgIndex===2)
                            {
                                hoverBg.stop().animate({left:'460px'},100).animate({width:'150px'},100);
                            }
                            else if(tgIndex===3)
                            {
                                hoverBg.stop().animate({left:'620px'},100).animate({width:'116px'},100);
                            }
                            else if(tgIndex===4)
                            {
                                hoverBg.stop().animate({left:'742px'},100).animate({width:'116px'},100);
                            };
                        });
                        
                    },
                    function()
                    {
                        $(mypage_tab_btn_areaRef.current).children('div').children('ul').children('li').eq(nowbtnNum).addClass(`${styles.on}`);
                        hoverBg.css('display','none');
                        
                        if(nowbtnNum===0)
                        {
                            hoverBg.css({left:'332px'}).css({width:'116px'});
                        }
                        else if(nowbtnNum===1)
                        {
                            hoverBg.css({left:'460px'}).css({width:'150px'});
                        }
                        else if(nowbtnNum===2)
                        {
                            hoverBg.css({left:'620px'}).css({width:'116px'});
                        }
                        else if(nowbtnNum===3)
                        {
                            hoverBg.css({left:'742px'}).css({width:'116px'});
                        };
                    });
                    
                }
            }).resize();

			//마이페이지 학습자 정보관리 학부모 기간 정보 팝업
			$(`.${styles.info_popup_btn}`).on('click',function(event)
			{
				event.preventDefault();

				// console.log($('input[name=packageCount]').attr('value'));
				let count = parseInt($('input[name=packageCount]').attr('value'));
				if (count > 0)
					$(`.${styles.info_popup}`).toggle();	
				else
					return alert('이용권이 없습니다. 이용권을 구매해주세요.');
			});

			//이용권 변경 팝업에서 '확인' 클릭했을 때
			$(`.${styles.translate_popup} .${styles.confirm}`).on('click', function(e)
			{
				e.preventDefault();
				
				// console.log($(`#${styles.student_info_product_select}`).val());
				let ordNo = $(`#${styles.student_info_product_select}`).val();
				// console.log($('input[name=mst_idx]').attr('value'));
				let mst_idx = $('input[name=mst_idx]').attr('value');

				if(ordNo === null || ordNo === "")
					return alert("상품을 선택해주세요.");

				let arr = new Array(mst_idx);

				const SendJaon = {
					mst_idx: arr,
					package_no: ordNo
				}

				// console.log("SendJaon : ", SendJaon);

				// 여기서 디스패치로~
				dispatch(packageUseStudentModify(SendJaon))
				.then(data => 
				{
					// console.log("packageUseStudentModify data : ", data);

					$(`.${styles.translate_popup}`).css('display','none');
					blackBg(2);

					window.location.reload();
				})
				.catch(e => {
					console.log(e);
				});
				
			});

			//이용권 변경 팝업에서 '취소' 클릭했을 때
			$(`.${styles.translate_popup} .${styles.cancle}`).on('click',function()
			{
				$(`.${styles.translate_popup}`).css('display','none');
				blackBg(2);
			});
			
			$('html').click(function(e)
			{ 
				
				if(!$(`.${styles.info_popup_btn}`).has(e.target).length)
				{
					$(`.${styles.info_popup}`).css('display','none');
				}
				
			});
		});

		// 여기서 디스패치로~
		// 부모 정보 불러오기
        dispatch(userSelect(authState.user.email))
        .then(data => 
        {
            // console.log("userSelect data : ", data);
        })
        .catch(e => {
            console.log(e);
        });

		// 학생 리스트 불러오기
        dispatch(studentList())
        .then(data => 
        {
            // console.log("studentList data : ", data);
        })
        .catch(e => {
            console.log(e);
        });

		// 여기서 디스패치로~
        dispatch(packageList())
        .then(data => 
        {
            // console.log("packageList data : ", data);
			let totalCount= 0;
			for (let i=0; i<data.length; i++)
			{
				if (data[i].ord_stat_cd === 'A')
					totalCount += data[i].ord_count;
			}
			setTotalPackageCount(totalCount);
        })
        .catch(e => {
            console.log(e);
        });

        // 여기서 디스패치로~
        dispatch(packageStudentInfo())
        .then(data => 
        {
            // console.log("packageStudentInfo data : ", data);
        })
        .catch(e => {
            console.log(e);
        });

	}, [$, dispatch]);

	const returnPackageList = (myPackage) => 
	{
		// console.log("myPackage : ", myPackage);
		let myPackageCopy = myPackage.sort((a, b) => a.ord_valid_date?.replace("-", "").replace("-", "").substr(0, 8) - b.ord_valid_date?.replace("-", "").replace("-", "").substr(0, 8));

		// 패키지 정보에서 필요한 정보만 추출함
		let liArray = [];

		for(let i=0; i<myPackageCopy.length; i++)
		{
			if (myPackageCopy[i].ord_stat_cd === "A")
			{
				// console.log("유료 패키지");
				// console.log(myPackage[i].ord_valid_date.replace("-", "").replace("-", "").substr(0, 8));
				liArray.push(myPackageCopy[i]);
			}
		}

		// console.log(liArray);

		let drawLiArray = [];

		// 추출한 패키지 정보를 그림
		for (let i=0; i<liArray.length; i++)
		{
			drawLiArray.push(<li key={i}>
				<span>{liArray[i].prd_name}</span>
				<p>{common.dateChangeReturn(liArray[i].ord_valid_date)} 까지</p>
			</li>);
		}

		return <ul className={styles.info_popup}>
			{drawLiArray}
		</ul>; 
	};

	//이용권 변경 클릭시 팝업
	const translateProduct = (i) =>
	{
		// console.log("myPackage.length : ", myPackage && myPackage.filter(row => row?.ord_stat_cd === 'A' && row?.ref_stat_cd !== 'cancelled').length || 0);
		let myPackageCount = myPackage && myPackage.filter(row => row?.ord_stat_cd === 'A' && row?.ref_stat_cd !== 'cancelled').length || 0;	// 유료 패키지의 갯수를 구합니다.

		if (myPackageCount === 0)
		{
			return alert("이용권 추가구매가 필요합니다.");
		}
		else
		{
			// console.log(i);
			$('input[name=mst_idx]').attr('value', i);
			blackBg(1);
			$(`.${styles.translate_popup}`).css('display','block');
		}
	};

	//검정 반투명 배경 제어
	const blackBg = (i) =>
	{
		if(i===1)
			{
				$(`.${styles.body_black}`).css('display','block');
				$('body').css('overflow','hidden');
				// console.log('배경보임');
			}
			else
			{
				$(`.${styles.body_black}`).css('display','none');
				$('body').css('overflow','visible');
				// console.log('배경꺼짐');
			};
	};

	const returnAddStudent = (length, used) => {

		// 최대 4개까지 생성됩니다.
		// 사용하고 있는 사람이 4명 이상이면 더 이상 생성하지 않습니다.
		if (used >= 4)
			length = 0;

		let drawArray = [];

		// 추출한 패키지 정보를 그림
		for (let i=0; i<length; i++)
		{
			drawArray.push(

				<div className={`${styles.student_state_box} ${styles.make}`} key={i}>
						<div className={styles.state_box_cover}>
							<div>
								<p>* '학습자 등록'을 해주셔야 야미에서 플레이가 가능합니다.</p>
								<a href={`/UserMypage/StudentRegister`}>학습자 추가하기</a>
							</div>
						</div>
						<div className={styles.state_head}>
							<div className={styles.profile_wrap}>
								<span>3P</span>
							</div>
							<p className={styles.ellip}>김철수</p>
						</div>
						<div className={styles.right_area}>
							<div className={styles.state_list}>
								<span>이름</span>
								<p className={styles.ellip}>와르바시와르바시와르바시</p>
							</div>
							<div className={styles.state_list}>
								<span>아이디</span>
								<p className={styles.ellip}>ayayayayayayyayay</p>
							</div>
							<div className={styles.state_list}>
								<span>기간</span>
								<p>2022.05.03 까지</p>
							</div>
							<div className={styles.state_list}>
								<a href="student_info_fix.html">정보 수정</a>
								<button type="button" onClick={translateProduct}>이용권 변경</button>
							</div>
						</div>
					</div>

			);
		}

		return <>
			{drawArray}
		</>; 
	};

	const returnPopupPackageList = (myPackage) => 
	{
		// console.log("returnPopupPackageList myPackage : ", myPackage);
		let myPackageCopy = myPackage.sort((a, b) => a.ord_valid_date?.replace("-", "").replace("-", "").substr(0, 8) - b.ord_valid_date?.replace("-", "").replace("-", "").substr(0, 8));

		// 패키지 정보에서 필요한 정보만 추출함
		let liArray = [];

		for(let i=0; i<myPackageCopy.length; i++)
		{
			if (myPackageCopy[i].ord_stat_cd === "A")
			{
				// console.log("유료 패키지");
				liArray.push(myPackageCopy[i]);
			}
		}

		// console.log(liArray);

		let drawLiArray = [];

		// 추출한 패키지 정보를 그림
		for (let i=0; i<liArray.length; i++)
		{
			drawLiArray.push(<option value={liArray[i].ord_no} key={i}>{liArray[i].prd_name}</option>);
		}

		return <>
			{drawLiArray}
		</>; 
	};

  return (
    <>
      	{/* Header 분리됨 MainLayout Header에 있음 */}
		<input type='hidden' name='packageCount' value={myPackage && myPackage.filter(row => row?.ord_stat_cd === 'A').length || 0}></input>
		<div className={styles.body_black}></div>
		<div className={styles.translate_popup}>
			<div>
				<p>이용권을 선택해주세요.</p>
				<select name="student_info_select" id={styles.student_info_product_select} defaultValue={'DEFAULT'}>
					<option value="DEFAULT" disabled >선택</option>
					{/* <option value="1">야미코딩(1개월 이용권)</option>
					<option value="2">야미코딩(3개월 이용권)</option>
					<option value="3">야미코딩(6개월 이용권)</option> */}
					
					{/* 패키지 리스트 불러오기 */}
					{myPackage !== undefined ? returnPopupPackageList(myPackage) : ""}
				</select>
				<div className={styles.btn_area}>
					<button type="button" className={styles.confirm}>확인</button>
					<button type="button" className={styles.cancle}>취소</button>
				</div>
			</div>
		</div>

		<div className={styles.mypage_top_line_banner}>
			<div className={styles.inner}>
				<span>학부모 페이지</span>
			</div>
		</div>
		<div className={styles.mypage_tab_btn_area} ref={mypage_tab_btn_areaRef}>
			<div className={styles.inner}>
				<ul>
					<li className={styles.hover_bg} ref={hover_bgRef}></li>
					<li><a href="/UserMypage/Report">학습 리포트</a></li>
					<li className={styles.on} ref={onRef}><a href="/UserMypage/StudentInfo">학습자 정보/관리</a></li>
					<li><a href="/UserMypage/ParentsInfo">학부모 정보</a></li>
					<li><a href="/UserMypage/PayInfo">결제 정보</a></li>
				</ul>
			</div>
		</div>
		<div className={`${styles.mypage_tab} ${styles.mypage_stuedent_info}`}>
			<div className={styles.inner}>
				<span className={styles.my_tit}>학습자 정보/관리</span>
				<input type='hidden' name='mst_idx' value={''}></input>
				<div className={styles.parents_state_area}>
					<p>일반 계정의 학습자는 최대 4명까지 입니다. 5명 부터는 단체로 가입하셔야 합니다.</p>
					<div className={styles.parents_state_box}>
						<div className={styles.state_head}>
							<div className={styles.profile_wrap}>
								<img src="/assets/my_info_basic_profile@2x.png" alt="profile"/>
							</div>
							<span>학부모</span>
						</div>
						<div className={styles.right_area}>
							<div className={styles.state_list}>
								<span>이름</span>
								<p className={styles.ellip}>{parent?.mem_name || ""}</p>
							</div>
							<div className={styles.state_list}>
								<span>아이디</span>
								<p className={styles.ellip}>{parent?.mem_email || ""}</p>
							</div>
							<div className={styles.state_list}>
								<span>기간</span>
								<div>
									<p className={styles.pay}>{myPackage?.filter(row => row?.ord_stat_cd === 'A').length !== 0 ? common.dateChangeReturn(myPackage?.filter(row => row?.ord_stat_cd === 'A').sort((a, b) => a.ord_valid_date?.replace("-", "").replace("-", "").substr(0, 8) - b.ord_valid_date?.replace("-", "").replace("-", "").substr(0, 8))[0].ord_valid_date) : "-"} 까지</p>
									<button type="button" className={styles.info_popup_btn}><img src="/assets/plus_on@2x.png" alt="btn"/></button>
									{/* 패키지 리스트 불러오기 */}
									{myPackage !== undefined ? returnPackageList(myPackage) : ""}
								</div>
							</div>
							<div className={styles.state_list}>
								<span>상태</span>
								<p className={styles.pay}>이용권 {totalPackageCount}개 보유 중</p>
							</div>
						</div>
					</div>
				</div>
				<div className={styles.line}></div>
				<div className={styles.student_state_area}>
				{
					student && student.map((row, index) => (
					
					<div className={`
										${styles.student_state_box}
										${row.ord_stat_cd === `A` ? `${styles.pay}` : ``}
										${row.ord_valid_date === null || row.ref_stat_cd === "cancelled" ? `${styles.new}` : ``}
									`} key={index}>
						<div className={styles.state_box_cover}>
							<div>
								<p>* '학습자 등록'을 해주셔야 야미에서 플레이가 가능합니다.</p>
								<a href={`/UserMypage/StudentRegister`}>학습자 추가하기</a>
							</div>
						</div>
						<div className={styles.state_head}>
							<div className={styles.profile_wrap}>
								<span>{index+1}P</span>
							</div>
							<p className={styles.ellip}>학습자<br/>
							{
								{
									A : `(${row.prd_name} 1개월 특별할인 상품)`,
									F : `(무료체험)`,
								} [row.ord_stat_cd]
							}
							</p>
						</div>
						<div className={styles.right_area}>
							<div className={styles.state_list}>
								<span>이름</span>
								<p className={styles.ellip}>{row.mst_name || "-"}</p>
							</div>
							<div className={styles.state_list}>
								<span>아이디</span>
								<p className={styles.ellip}>{row.mst_id}</p>
							</div>
							<div className={styles.state_list}>
								<span>기간</span>
								<p className={`${row.ord_stat_cd === `A` ? `${styles.end}` : ``}`}>
									{`${common.dateChangeReturn(row.ord_valid_date) !== "" && row.ref_stat_cd !== "cancelled" ? `${common.dateChangeReturn(row.ord_valid_date)} 까지` : `-`}`}
								</p>
							</div>
							<div className={styles.state_list}>
								<a className={`${row.ord_valid_date !== null ? `${styles.active}` : ``}`} href={`/UserMypage/StudentInfoFix/${row.mst_id}`}>정보 수정</a>
								{/* <a className={`${row.ord_valid_date === null ? `${styles.active}` : ``}`} href={`/UserMypage/StudentRegister/${row.mst_id}`}>학습자 등록</a> */}
								<button type="button" onClick={() => translateProduct(row.mst_idx)}>이용권 변경</button>
							</div>
						</div>
					</div>
					
					))
				}
					
				{returnAddStudent(totalPackageCount - (studentInfo?.used || 0), studentInfo?.used || 0)}

				</div>
				<div className={styles.line}></div>
				<a href="/Pay" className={styles.go_price}>학습자 추가 구매</a>
			</div>{/* inner */}
		</div>{/* mypage_tab */}
		
		{/* Footer 분리됨 MainLayout Footer에 있음 */}
    </>
  );
}