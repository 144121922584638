import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import styles from "../../css/common.module.css";
import $ from 'jquery';
import {useReactToPrint} from 'react-to-print';

import { userMissionTotal, userMissionProgress, userKnowledgeAnswer, userTotalHumanAbility, userHumanAbility } from "../../actions/users";
import { studentList } from "../../actions/student";
import * as common from "../../lib";

export default function MypageReport() {

	const [mstIdx, setMstIdx] = useState(0);
	const [startDate, setStartDate] = useState(0);
	const [lastDate, setLastDate] = useState(0);
	const [abilityObject, setAbilityObject] = useState(null);

	const dispatch = useDispatch();	

	const authState = useSelector(state => state.auth);
    // console.log("authState : ", authState);

	const student = useSelector(state => state.student.list);
    // console.log("student : ", student);

	const missiontotal = useSelector(state => state.users.missiontotal);
    // console.log("missiontotal : ", missiontotal);

	const missionprogress = useSelector(state => state.users.missionprogress);
    // console.log("missionprogress : ", missionprogress);

	const knowledgeanswer = useSelector(state => state.users.knowledgeanswer);
    // console.log("knowledgeanswer : ", knowledgeanswer);

	// const totalability = useSelector(state => state.users.totalability);
    // console.log("totalability : ", totalability);

	const ability = useSelector(state => state.users.ability);
    // console.log("ability : ", ability);

	const mypage_tab_btn_areaRef =  useRef();
    const hover_bgRef =  useRef();
    const onRef =  useRef();
	const my_report_cognitive_ability_areaRef = useRef();

	const print_areaRef = useRef();

	useEffect(() => {

		// 여기에서 jQuery를 초기화 합니다.
		// jquery ready 호출. jquery3 부터 변경됨
		$(function(){
			// console.log("jquery ready!");

            //마이페이지 탭 버튼(활성화 효과)
            $(mypage_tab_btn_areaRef.current).children('li').children('a').on('click',function()
			{    
				let tg=$(this);
                $(mypage_tab_btn_areaRef.current).children('li').removeClass('on');
                tg.addClass('on');
            });
            
            //마이페이지 탭 버튼(후버 효과)
            $(window).on("resize", function()
			{
                if (window.innerWidth < 1200) 
                { 
                    // $('.mypage_tab_btn_area li.hover_bg').css('display','none');
                    $(mypage_tab_btn_areaRef.current).find(`li.`+`${styles.hover_bg}`).css('display','none');
                }
                else
                {
                    let nowbtnNum=$(mypage_tab_btn_areaRef.current).find(`li.`+`${styles.on}`).index();
                    let hoverBg=$(mypage_tab_btn_areaRef.current).find(`li.`+`${styles.hover_bg}`);

                    $(mypage_tab_btn_areaRef.current).children('div').children('ul').hover(function()
                    {
                        $(mypage_tab_btn_areaRef.current).children('div').children('ul').children('li').removeClass(`${styles.on}`);
                        hoverBg.css('display','block');
                        
                        $(mypage_tab_btn_areaRef.current).children('div').children('ul').children('li').on('mouseenter',function()
                        {
                            let tgIndex=$(this).index();
                            
                            if(tgIndex===1)
                            {
                                hoverBg.stop().animate({left:'332px'},100).animate({width:'116px'},100);
                            }
                            else if(tgIndex===2)
                            {
                                hoverBg.stop().animate({left:'460px'},100).animate({width:'150px'},100);
                            }
                            else if(tgIndex===3)
                            {
                                hoverBg.stop().animate({left:'620px'},100).animate({width:'116px'},100);
                            }
                            else if(tgIndex===4)
                            {
                                hoverBg.stop().animate({left:'742px'},100).animate({width:'116px'},100);
                            };
                        });
                        
                    },
                    function()
                    {
                        $(mypage_tab_btn_areaRef.current).children('div').children('ul').children('li').eq(nowbtnNum).addClass(`${styles.on}`);
                        hoverBg.css('display','none');
                        
                        if(nowbtnNum===0)
                        {
                            hoverBg.css({left:'332px'}).css({width:'116px'});
                        }
                        else if(nowbtnNum===1)
                        {
                            hoverBg.css({left:'460px'}).css({width:'150px'});
                        }
                        else if(nowbtnNum===2)
                        {
                            hoverBg.css({left:'620px'}).css({width:'116px'});
                        }
                        else if(nowbtnNum===3)
                        {
                            hoverBg.css({left:'742px'}).css({width:'116px'});
                        };
                    });
                    
                }
            }).resize();

		});

		// 학생 리스트 불러오기
        dispatch(studentList())
        .then(data => 
        {
            // console.log("studentList data : ", data);
			// 학생의 개수를 체크해서 하나도 없으면 페이지를 이동합니다.

			if (data.length === 0)
			{
				alert("마이페이지를 이용하실려면 최소 1명 이상의 학습자가 필요합니다.");
				window.location.href = "/UserMypage/MypageStudentAdd";
			}
        })
        .catch(e => {
            console.log(e);
        });

		// 여기서 디스패치로~
        dispatch(userMissionTotal())
        .then(data => 
        {
            // console.log("userMissionTotal data : ", data);
        })
        .catch(e => {
            console.log(e);
        });

		// 여기서 디스패치로~
        dispatch(userMissionProgress(mstIdx))
        .then(data => 
        {
            // console.log("userMissionProgress data : ", data);
        })
        .catch(e => {
            console.log(e);
        });

		// 여기서 디스패치로~
        dispatch(userKnowledgeAnswer(mstIdx))
        .then(data => 
        {
            // console.log("userKnowledgeAnswer data : ", data);
        })
        .catch(e => {
            console.log(e);
        });

		// 여기서 디스패치로~
        // dispatch(userTotalHumanAbility())
        // .then(data => 
        // {
        //     // console.log("userTotalHumanAbility data : ", data);
        // })
        // .catch(e => {
        //     console.log(e);
        // });

		// 여기서 디스패치로~
        dispatch(userHumanAbility(mstIdx))
        .then(data => 
        {
            // console.log("userHumanAbility data : ", data.data);
        })
        .catch(e => {
            console.log(e);
        });

	}, [$]);

	const handlePrint = useReactToPrint
	({
		content: () => print_areaRef.current,
	});

	const changeHandler = (e) => 
	{
		// console.log(e.target.value);

		let mst_idx = parseInt(e.target.value);
		setMstIdx(mst_idx);

		// 현재 불러온 학습자의 정보에서 가입날짜와 최근로그인 날짜를 추출합니다.
		// console.log(student.find(x => x.mst_idx === mst_idx));
		let studentValue = student.find(x => x.mst_idx === mst_idx);

		setStartDate(common.dateChangeReturn(studentValue.mst_reg_date));
		setLastDate(common.dateChangeReturn(studentValue.mst_lastlogin_date));
		
		// 여기서 디스패치로~
        dispatch(userMissionProgress(mst_idx))
        .then(data => 
        {
            // console.log("userMissionProgress data : ", data);
        })
        .catch(e => {
            console.log(e);
        });

		// 여기서 디스패치로~
        dispatch(userKnowledgeAnswer(mst_idx))
        .then(data => 
        {
            // console.log("userKnowledgeAnswer data : ", data);
        })
        .catch(e => {
            console.log(e);
        });

		// 여기서 디스패치로~
        dispatch(userHumanAbility(mst_idx))
        .then(data => 
        {
            // console.log("userHumanAbility data : ", data.data);

			// 서버에서 완전한 데이터 였을 때의 값을 미리 만듭니다.
			let abilityArray = [{mii_indicators_type:'Lo', cnt:0},{mii_indicators_type:'Cr', cnt:0},{mii_indicators_type:'Im', cnt:0},{mii_indicators_type:'En', cnt:0},{mii_indicators_type:'Ma', cnt:0},{mii_indicators_type:'Sc', cnt:0}];
			
			for (let i=0; i<abilityArray.length; i++)
			{
				for (let j=0; j<data.data.length; j++)
				{
					if (abilityArray[i].mii_indicators_type === data.data[j].mii_indicators_type)
						abilityArray[i].cnt = data.data[j].cnt;
				}
			}

			setAbilityObject(abilityArray);
        })
        .catch(e => {
            console.log(e);
        });

	};

	const returnAbility = (a, b, index) => 
	{
		// 지표 유형 Lo - 논리력, Cr - 창의력, Im - 상상력, En - 공학, Ma - 수학, Sc - 과학

		// common.percent(ability?.data !== null ? ability?.data[2]?.cnt || 0 : 0, totalability?.data[2].cnt || 0)

		let psercentValue = common.percent(a, b);
		// console.log("psercentValue : ", psercentValue);

		//인지능력향상 그래프 길이 조정
		let ability = $(my_report_cognitive_ability_areaRef.current).find(`.`+`${styles.cognitive_ability}`).eq(index);
		// let percentTxt=$(ability).find(`.`+`${styles.ability_percent}`).text();
		let graph=$(ability).find(`.`+`${styles.graph}`);
		let percentTxt = psercentValue + "%";
		
		// console.log("percentTxt : ", percentTxt);
		graph.css('width', percentTxt);

		return psercentValue;
	}

  return (
    <>
      	{/* Header 분리됨 MainLayout Header에 있음 */}

        <div className={styles.mypage_top_line_banner}>
			<div className={styles.inner}>
				<span>학부모 페이지</span>
			</div>
		</div>
		<div className={styles.mypage_tab_btn_area} ref={mypage_tab_btn_areaRef}>
			<div className={styles.inner}>
				<ul>
					<li className={styles.hover_bg} ref={hover_bgRef}></li>
					<li className={styles.on} ref={onRef}><a href="/UserMypage/Report">학습 리포트</a></li>
					<li><a href="/UserMypage/StudentInfo">학습자 정보/관리</a></li>
					<li><a href="/UserMypage/ParentsInfo">학부모 정보</a></li>
					<li><a href="/UserMypage/PayInfo">결제 정보</a></li>
				</ul>
			</div>
		</div>
		<div className={`${styles.mypage_tab} ${styles.mypage_report}`} ref={print_areaRef}>
			<div className={styles.inner}>
				<div className={styles.my_report_top_btn_area}>
					<select name="report_student_list" id={styles.report_student_list_select} defaultValue={'DEFAULT'} onChange={changeHandler}>
						<option value="DEFAULT" disabled>선택</option>
						{student?.map((row, index) =>
							<option value={row.mst_idx} key={index}>{row.mst_name}({row.ord_stat_cd === 'A' ? '유료' : '무료'})</option>
						)}
						{/* <option value="1">우주최강김코딩(무료)</option>
						<option value="2">우주최강김코딩(유료)</option> */}
					</select>
					<button type="button" onClick={handlePrint}>인쇄하기<img src="/assets/my_report_print@2x.png" alt="인쇄하기"/></button>
				</div>
				<span className={styles.my_tit}>학습 리포트</span>
				<p className={styles.for_freeuser_info}>* 무료체험은 데이터가 없어 학습 리포트의 지표가 0으로 표시됩니다. </p>
				<div className={styles.my_report_missonprogress_area}>
					 <div className={styles.head}>
						<span>야미코딩 각 구분별 미션 진행</span>
						<p>{ startDate === 0 && lastDate === 0 ? "" : `${startDate || ""}  ~  ${lastDate || ""}` }</p>
					 </div>
					 <div className={styles.wrap}>
						<div className={styles.misson_progress}>
							<div className={styles.misson_left}>
								<img src="/assets/my_report_progress1@2x.png" alt="코딩블록 배우기"/>
								<div className={styles.progress_wrap}>
									<span className={styles.now}>{missionprogress?.data !== null ? missionprogress?.data[0]?.cnt || 0 : 0}</span>
									<span>/{missiontotal?.data[0].cnt || 0}</span>
								</div>
							</div>
							<div className={styles.misson_txt}>
								<span className={styles.misson_name}>코딩블록 배우기</span>
								<p>처음 사용자를 위해 코딩블록의 기본 적인 기능을 배웁니다.</p>
							</div>
						</div>
						<div className={styles.misson_progress}>
							<div className={styles.misson_left}>
								<img src="/assets/my_report_progress2@2x.png" alt="순차 수업"/>
								<div className={styles.progress_wrap}>
									<span className={styles.now}>{missionprogress?.data !== null ? missionprogress?.data[1]?.cnt || 0 : 0}</span>
									<span>/{missiontotal?.data[1].cnt || 0}</span>
								</div>
							</div>
							<div className={styles.misson_txt}>
								<span className={styles.misson_name}>순차 수업</span>
								<p>명령문을 하나씩 수행하는 과정의 미션들입니다.</p>
							</div>
						</div>
						<div className={styles.misson_progress}>
							<div className={styles.misson_left}>
								<img src="/assets/my_report_progress3@2x.png" alt="반복 수업"/>
								<div className={styles.progress_wrap}>
									<span className={styles.now}>{missionprogress?.data !== null ? missionprogress?.data[2]?.cnt || 0 : 0}</span>
									<span>/{missiontotal?.data[2].cnt || 0}</span>
								</div>
							</div>
							<div className={styles.misson_txt}>
								<span className={styles.misson_name}>반복 수업</span>
								<p>특정 횟수만큼 반복하거나, 조건이 만족할 때까지 반복 하는 과정의 미션들입니다.</p>
							</div>
						</div>
						<div className={styles.misson_progress}>
							<div className={styles.misson_left}>
								<img src="/assets/my_report_progress4@2x.png" alt="선택 수업"/>
								<div className={styles.progress_wrap}>
									<span className={styles.now}>{missionprogress?.data !== null ? missionprogress?.data[3]?.cnt || 0 : 0}</span>
									<span>/{missiontotal?.data[3].cnt || 0}</span>
								</div>
							</div>
							<div className={styles.misson_txt}>
								<span className={styles.misson_name}>선택 수업</span>
								<p>조건에 따라 명령문을 선택적으로 수행하는 과정의 미션들입니다.</p>
							</div>
						</div>
						<div className={styles.misson_progress}>
							<div className={styles.misson_left}>
								<img src="/assets/my_report_progress5@2x.png" alt="특별 미션"/>
								<div className={styles.progress_wrap}>
									<span className={styles.now}>{missionprogress?.data !== null ? missionprogress?.data[4]?.cnt || 0 : 0}</span>
									<span>/{missiontotal?.data[4].cnt || 0}</span>
								</div>
							</div>
							<div className={styles.misson_txt}>
								<span className={styles.misson_name}>특별 미션</span>
								<p>얼음, 바람, 물리엔진 적용 등 특수한 기능과 순차, 반복, 선택의 혼합 형태로 난이도가 높은 미션들입니다.</p>
							</div>
						</div>
					 </div>
				</div>
				<div className={styles.my_report_study_time_area}>
					 <div className={styles.head}>
						<span>학습 시간</span>
						<p>2022.05</p>
					 </div>
					 <div className={styles.wrap}>
						<ul>
							{/* <li>
							    <span className={styles.name}>학습일</span>
								<span>0</span>
							</li>
							<li>
								<span className={styles.name}>학습시간</span>
								<span>0</span>
							</li> */}
							<li>
								<span className={styles.name}>지식은행 문제 풀이 수</span>
								<span>{knowledgeanswer?.data !== null ? knowledgeanswer?.data[0]?.cnt || 0 : 0}</span>
							</li>
						</ul>
					 </div>
				</div>
				<div className={styles.my_report_cognitive_ability_area} ref={my_report_cognitive_ability_areaRef}>
					 <div className={styles.head}>
						<span>인지 능력 향상</span>
					 </div>
					 <div className={styles.wrap}>
						<div className={styles.ability_area}>
							<div className={styles.cognitive_ability}>
								<div className={styles.left_area}>
									<img src="/assets/my_report_recognition1@2x.png" alt="논리력 향상"/>
								</div>
								<div className={styles.right_area}>
									<div className={styles.top_area}>
										<span className={styles.name}>논리력 향상</span>
										<div className={styles.graph_area}>
											<div className={styles.graph_wrap}>
												<div className={styles.graph}></div>
											</div>
											<span className={styles.ability_percent}>
											{abilityObject && abilityObject.find(word => word.mii_indicators_type === 'Lo').cnt || 0} 
											{/*returnAbility(ability?.data !== null ? ability?.data[3]?.cnt || 0 : 0, totalability?.data[3].cnt || 0, 0)*/}
											</span>
										</div>
									</div>
									<p>상황을 논리적으로 판단해서 문제를 해결합니다.</p>
								</div>
							</div>
							<div className={styles.cognitive_ability}>
								<div className={styles.left_area}>
									<img src="/assets/my_report_recognition2@2x.png" alt="창의력 향상" className={styles.creative_icon} />
								</div>
								<div className={styles.right_area}>
									<div className={styles.top_area}>
										<span className={styles.name}>창의력 향상</span>
										<div className={styles.graph_area}>
											<div className={styles.graph_wrap}>
												<div className={styles.graph}></div>
											</div>
											<span className={styles.ability_percent}>
											{abilityObject && abilityObject.find(word => word.mii_indicators_type === 'Cr').cnt || 0} 
												{/*returnAbility(ability?.data !== null ? ability?.data[0]?.cnt || 0 : 0, totalability?.data[0].cnt || 0, 1)*/}
											</span>
										</div>
									</div>
									<p>평범한 방법이 아닌 창의적인 방법으로 문제를 해결합니다.</p>
								</div>
							</div>
							<div className={styles.cognitive_ability}>
								<div className={styles.left_area}>
									<img src="/assets/my_report_recognition3@2x.png" alt="상상력 향상"/>
								</div>
								<div className={styles.right_area}>
									<div className={styles.top_area}>
										<span className={styles.name}>상상력 향상</span>
										<div className={styles.graph_area}>
											<div className={styles.graph_wrap}>
												<div className={styles.graph}></div>
											</div>
											<span className={styles.ability_percent}>
												{abilityObject && abilityObject.find(word => word.mii_indicators_type === 'Im').cnt || 0} 
												{/*ability?.data !== null ? ability?.data[2]?.cnt || 0 : 0*/}
												{/*returnAbility(ability?.data !== null ? ability?.data[2]?.cnt || 0 : 0, totalability?.data[2].cnt || 0, 2)*/}
											</span>
										</div>
									</div>
									<p>주어진 문제를 상상력으로 요점을 파악해 문제를 해결합니다.</p>
								</div>
							</div>
						</div>
						<div className={styles.ability_area}>
							<div className={styles.cognitive_ability}>
								<div className={styles.left_area}>
									<img src="/assets/my_report_science@2x.png" alt="과학"/>
								</div>
								<div className={styles.right_area}>
									<div className={styles.top_area}>
										<span className={styles.name}>과학</span>
										<div className={styles.graph_area}>
											<div className={styles.graph_wrap}>
												<div className={styles.graph}></div>
											</div>
											<span className={styles.ability_percent}>
												{abilityObject && abilityObject.find(word => word.mii_indicators_type === 'Sc').cnt || 0} 
												{/*ability?.data !== null ? ability?.data[5]?.cnt || 0 : 0*/}
												{/*returnAbility(ability?.data !== null ? ability?.data[5]?.cnt || 0 : 0, totalability?.data[5].cnt || 0, 3)*/}
											</span>
										</div>
									</div>
									<p>문제의 구조와 법칙등을 관찰하여 문제를 해결합니다.</p>
								</div>
							</div>
							<div className={styles.cognitive_ability}>
								<div className={styles.left_area}>
									<img src="/assets/my_report_math@2x.png" alt="수학"/>
								</div>
								<div className={styles.right_area}>
									<div className={styles.top_area}>
										<span className={styles.name}>수학</span>
										<div className={styles.graph_area}>
											<div className={styles.graph_wrap}>
												<div className={styles.graph}></div>
											</div>
											<span className={styles.ability_percent}>
												{abilityObject && abilityObject.find(word => word.mii_indicators_type === 'Ma').cnt || 0} 
												{/*ability?.data !== null ? ability?.data[4]?.cnt || 0 : 0*/}
												{/*returnAbility(ability?.data !== null ? ability?.data[4]?.cnt || 0 : 0, totalability?.data[4].cnt || 0, 4)*/}
											</span>
										</div>
									</div>
									<p>숫자, 크기, 구조 등의 개념을 생각해서 문제를 해결합니다.</p>
								</div>
							</div>
							<div className={styles.cognitive_ability}>
								<div className={styles.left_area}>
									<img src="/assets/my_report_engineering@2x.png" alt="공학"/>
								</div>
								<div className={styles.right_area}>
									<div className={styles.top_area}>
										<span className={styles.name}>공학</span>
										<div className={styles.graph_area}>
											<div className={styles.graph_wrap}>
												<div className={styles.graph}></div>
											</div>
											<span className={styles.ability_percent}>
												{abilityObject && abilityObject.find(word => word.mii_indicators_type === 'En').cnt || 0}
												{/*ability?.data !== null ? ability?.data[1]?.cnt || 0 : 0*/}
												{/*returnAbility(ability?.data !== null ? ability?.data[1]?.cnt || 0 : 0, totalability?.data[1].cnt || 0, 5)*/}
											</span>
										</div>
									</div>
									<p>과학적 원리와 실용적 지식을 활용하여 문제를 해결합니다.</p>
								</div>
							</div>
						</div>{/* ability_area */} 
					 </div>{/* wrap */}
				</div>{/* my_report_cognitive_ability_area */}
			</div>{/* inner */}
		</div>
		
		{/* Footer 분리됨 MainLayout Footer에 있음 */}
    </>
  );
}