import * as React from 'react';
import styles from "../../css/common.module.css";

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';

import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
// import { register } from "../../actions/auth";
import { parentIdCheck } from "../../actions/users";
import { useNavigate } from 'react-router-dom';

import $ from 'jquery';

export default function RegisterView() {

  const initialJoinState = {
    email: "", 
    password: "",
    confirmPassword: "",
    name: "",
    nickname: "",
    type: 0,
    phonenumber1: "010",
    phonenumber2: "",
    phonenumber3: "",
    agreement:false,
  };

  // const [checkBox, setCheckBox] = useState({agreement: false});
  const [join, setJoin] = useState(initialJoinState);

  const [fontSizeValue, setFontSizeValue] = useState(null);

  const [idCheck, setIdCheck] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleInputChange = event => {
    const { name, value } = event.target;
    // console.log("event target name : ", name);
    // console.log("event target value : ", value);
    
    // if(name === "agreement")
    // {
    //   setJoin({ ...join, [name]: event.target.checked });
    // }
    // else
    // {
    //   setJoin({ ...join, [name]: value });
    // }
    setJoin({ ...join, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // console.log("join : ", join);

    // if (!join.agreement)
    // {
    //     alert("회원가입을 위해 약관의 동의가 필요합니다. \n약관을 읽고 체크해주세요.");
    //     return;
    // }

    let body = {
      email: join.email,
      password: join.password,
      name: join.name,
      nickname: join.nickname,
      type: join.type,
      phone: join.phonenumber1 + "-" + join.phonenumber2 + "-" + join.phonenumber3
    };

    // console.log("body : ", body);

    if (body.email === "")
		{
			return alert("아이디(이메일)를 입력해주세요!");
		}
		else
		{
			if (!idCheck)
			{
				return alert("중복된 아이디(이메일)입니다. 다른 아이디를 입력해주세요!");
			}

			const regEmail = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;
      if (regEmail.test(body.email) === false) 
      {
        return alert('입력된 값은 이메일 형식이 아닙니다.');
      }
		}

    // 정규 표현식으로 비밀번호 생성 규칙에 맞는지 체크합니다.
    let reg = new RegExp(/(?=.*\d{1,20})(?=.*[~`!@#$%\^&*()-+=]{1,20})(?=.*[a-zA-Z]{1,20}).{8,20}$/);
    // console.log(reg.test(join.password));
    if( !reg.test(join.password) ) 
    {
        alert("비밀번호 생성 규칙은 영문, 숫자, 특수문자 포함 8자 이상 20자가지 입니다.");
        return false;
    }

    if(join.password !== join.confirmPassword)
    {
      return alert('비밀번호와 비밀번호 확인은 같아야 합니다.')
    }

    if(body.name === "")
    {
      return alert('이름을 입력해주세요.');
    }

    // if(body.nickname === "")
    // {
    //   return alert('닉네임을 입력해주세요.');
    // }

    const regPhone = /^01([0|1|6|7|8|9])-([0-9]{3,4})-([0-9]{4})$/;
    if (regPhone.test(body.phone) === false) 
    {
      return alert('입력된 값은 휴대전화번호 형식에 맞지 않습니다.');
    }

    if(body.type === 0)
    {
      return alert('계정 유형을 선택해주세요.');
    }

    // console.log(body);

    // console.log("user type : ", join.type);
    if (join.type === 1 || join.type === 2 )
    {
      // 여기서 디스패치로~
      // dispatch(register(body))
      // .then(data => 
      // {
      //     console.log("data : ", data);
      //     if (!data.success)
      //     {
      //         alert(data.msg);
      //     }
      //     else
      //     {
      //       // alert("가입완료");
      //       navigate("/auth/RegisterStudentView", {
      //         state: {
      //           parentIdx: data.msg,
      //         },
      //       });
      //     }
      //   })
      //   .catch(e => {
      //       console.log(e);
      //   });
      
      navigate("/auth/RegisterStudentView",
      {
        state: {
          body: body,
        },
      });

    }
    else if (join.type === 4)
    {
      alert("단체 가입은 추가 정보가 필요합니다.");
      navigate("/auth/groupRegister",
      {
        state: {
          joinData: body,
        },
      });
    }
  }

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(2), 
      width:108,
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }));

  useEffect(() => {

    let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ? true : false;
			
			if(!isMobile) 
			{
				  //PC
          setFontSizeValue({style: {fontSize: 15}});
      }
      else 
      {
          // mobile
          setFontSizeValue({style: {fontSize: 12}});
      }

      // console.log(typeof(fontSizeValue));

  }, []);

  const parentIDCheck = (event, email) => {

    // console.log("email : ", email);
    if(email === "")
    {
      return alert("이메일을 입력해주세요!");
    }

		// 학습자 중복 아이디 검사
		// console.log("dispatch parentIDCheck");

    dispatch(parentIdCheck(email))
    .then(data => 
    {
        // console.log("data : ", data);
        if(!data.success)
        {
          setIdCheck(true);
          $(`.${styles.input_area}.${styles.email}`).removeClass(`${styles.overlap}`);
          $(`.${styles.input_area}.${styles.email}`).removeClass(`${styles.overlapno}`);
          $(`.${styles.input_area}.${styles.email}`).addClass(`${styles.overlapno}`);
        }
        else
        {
          setIdCheck(false);
          $(`.${styles.input_area}.${styles.email}`).removeClass(`${styles.overlap}`);
          $(`.${styles.input_area}.${styles.email}`).removeClass(`${styles.overlapno}`);
          $(`.${styles.input_area}.${styles.email}`).addClass(`${styles.overlap}`);
        }
    })
    .catch(e => {
        console.log(e);
    });
	}

  // const hasError = passwordEntered =>
  //       join.password.length < 20 ? true : false;

  const hasError = (passwordEntered) =>
  {
    // 정규 표현식으로 비밀번호 생성 규칙에 맞는지 체크합니다.
    let reg = new RegExp(/(?=.*\d{1,20})(?=.*[~`!@#$%\^&*()-+=]{1,20})(?=.*[a-zA-Z]{1,20}).{8,20}$/);
    // console.log(reg.test(join.password));
    return !reg.test(join.password);
  }
    
  const hasNotSameError = passwordEntered =>
      join.password != join.confirmPassword ? true : false;

  return (
    <>
      <div className={`${styles.login_bg_wrapper} ${styles.join_bg_wrapper}`}>
        <div className={`${styles.join_area} ${styles.middle}`}>
          <div className={styles.login_top}>
            <a href="/" className={styles.logo}><img src="/assets/logo@2x.png" alt="logo"/></a>
            <a href="/" className={styles.to_join}>홈페이지로 돌아가기</a>
          </div>
          <span className={styles.login_title}>회원가입</span>
          <div className={`${styles.join_wrap} ${styles.center_align}`}>
            <div className={`${styles.input_area} ${styles.email}`}>
              <TextField
                required
                fullWidth
                id="email"
                label="이메일"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={handleInputChange}
                InputLabelProps={fontSizeValue} // font size of input label
                sx={{ paddingRight: 2 }}
              />
              <button type="button" onClick={(e)=>parentIDCheck(e, join.email)}>중복확인</button>
            </div>
            <div className={`${styles.input_area} ${styles.password}`}>
              <TextField
                required
                fullWidth
                name="password"
                label="비밀번호 영문,숫자,특수문자 조합 8~20자리"
                type="password"
                id="password"
                error={hasError('password')} // 해당 텍스트필드에 error 핸들러 추가
                onChange={handleInputChange}
                autoComplete="current-password"
                inputProps={{ maxLength: 20 }}
                InputLabelProps={fontSizeValue} // font size of input label
              />
            </div>
            <div className={`${styles.input_area} ${styles.re_password}`}>
              <TextField
                required
                fullWidth
                name="confirmPassword"
                label="패스워드 확인"
                type="password"
                id="confirmPassword"
                error={hasNotSameError('confirmPassword')} // 해당 텍스트필드에 error 핸들러 추가
                helperText={
                    hasNotSameError('confirmPassword') ? "입력한 비밀번호와 일치하지 않습니다." : null
                } // 에러일 경우에만 안내 문구 표시
                onChange={handleInputChange}
                autoComplete="current-password"
                inputProps={{ maxLength: 20 }}
                InputLabelProps={fontSizeValue} // font size of input label
              />
            </div>
            <div className={`${styles.input_area} ${styles.name}`}>
              <TextField
                required
                fullWidth
                id="name"
                label="Name"
                name="name"
                onChange={handleInputChange}
                InputLabelProps={fontSizeValue} // font size of input label
              />
            </div>
            {/* <div className={`${styles.input_area} ${styles.nikname}`}>
              <TextField
                required
                fullWidth
                id="nickname"
                label="Nick Name"
                name="nickname"
                onChange={handleInputChange}
                inputProps={{ maxLength: 7 }}
                InputLabelProps={fontSizeValue} // font size of input label
              />
            </div> */}
            <div className={`${styles.input_area} ${styles.tel} ${styles.cf}`}>
              <p>핸드폰번호</p>
              <div className={`${styles.input_area} ${styles.box1}`}>
                <TextField
                  required
                  fullWidth
                  id="phonenumber1"
                  label=""
                  name="phonenumber1"
                  value={join.phonenumber1}
                  InputProps={{
                    readOnly: true,
                    maxLength: 3
                  }}
                  onChange={handleInputChange}
                />
              </div>
              <div className={`${styles.input_area} ${styles.box2}`}>
                <TextField
                  required
                  fullWidth
                  id="phonenumber2"
                  name="phonenumber2"
                  onChange={handleInputChange}
                  inputProps={{ maxLength: 4 }}
                />
              </div>
              <div className={`${styles.input_area} ${styles.box3}`}>
                <TextField
                  required
                  fullWidth
                  id="phonenumber3"
                  name="phonenumber3"
                  onChange={handleInputChange}
                  inputProps={{ maxLength: 4 }}
                />
              </div>
            </div>
            <div className={styles.select_area}>
              {/* <span className={styles.select_name}>계정 유형</span>&nbsp;&nbsp; */}
              <FormControl>
                <InputLabel id="type">계정 유형</InputLabel>
                <Select
                  labelId="type"
                  id="type"
                  name="type"
                  value={join.type}
                  onChange={handleInputChange}
                  autoWidth
                  label="type"
                  input={<BootstrapInput />}
                  >
                  <MenuItem value={0} disabled>
                    <em>선택</em>
                  </MenuItem>
                  <MenuItem value={2}>일반</MenuItem>
                  <MenuItem value={4}>단체</MenuItem>
                </Select>
              </FormControl>
            </div>
            {
              join.type === 4 ? <a href="#" className={styles.ok} onClick={handleSubmit} >추가정보 입력</a> : <a href="#" className={styles.ok} onClick={handleSubmit} >학습자 생성</a>
            }
          </div>
        </div>
      </div>
    </>
  );
}