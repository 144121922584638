import {
  LOGIN_SUCCESS_AUTH,
  LOGIN_FAIL_AUTH,
  REGISTER_SUCCESS_AUTH,
  REGISTER_FAIL_AUTH,
  GROUP_REGISTER_SUCCESS_AUTH,
  GROUP_REGISTER_FAIL_AUTH,
  LOGOUT_AUTH,
  PASSWORD_CHANGE_AUTH,
  LOGIN_RELOAD_AUTH,
  PASSWORD_UPDATE_AUTH,
} from "./types";

import AuthDataService from "../services/AuthService";

export const login = (email, password) => async (dispatch) => 
{
  try 
  {
    const res = await AuthDataService.login({ email, password });

    // console.log("res : ", res);

    // 사용자 데이터가 들어왔는지 확인 후에 로컬스토리지에 저장한다.
    if (res.data.success) 
    {  
      localStorage.setItem("user", JSON.stringify(res.data.data));

      dispatch({
        type: LOGIN_SUCCESS_AUTH,
        payload: res.data.data,
      });
    }
    else
    {
      dispatch({
        type: LOGIN_FAIL_AUTH,
      });
    }

    return Promise.resolve(res.data);
  } 
  catch (err) 
  {
    dispatch({
      type: LOGIN_FAIL_AUTH,
    });

    return Promise.reject(err);
  }
};

export const loginReload = () => async (dispatch) => 
{
  try 
  {
    const res = await AuthDataService.loginReload();

    // console.log("res : ", res);

    // 사용자 데이터가 들어왔는지 확인 후에 로컬스토리지에 저장한다.
    if (res.data.success) 
    {  
      localStorage.setItem("user", JSON.stringify(res.data.data));

      dispatch({
        type: LOGIN_RELOAD_AUTH,
        payload: res.data.data,
      });
    }
    else
    {
      dispatch({
        type: LOGIN_FAIL_AUTH,
      });
    }

    return Promise.resolve(res.data);
  } 
  catch (err) 
  {
    dispatch({
      type: LOGIN_FAIL_AUTH,
    });

    return Promise.reject(err);
  }
};

export const logout = () => async (dispatch) => 
{
  try 
  {
    await AuthDataService.logout();

    dispatch({
      type: LOGOUT_AUTH,
    });

    return Promise.resolve();
  } 
  catch (err) 
  {
    return Promise.reject(err);
  }
};

export const register = (data) => async (dispatch) => 
{
  try 
  {
    const res = await AuthDataService.register(data);

    // console.log("res : ", res);

    // 사용자 데이터가 들어왔는지 확인 후에 로컬스토리지에 저장한다.
    if (res.data.success) 
    {
      localStorage.setItem("mem_idx", JSON.stringify(res.data.msg));

      dispatch({
        type: REGISTER_SUCCESS_AUTH,
        payload: res.data.msg,
      });
    }
    else
    {
      dispatch({
        type: REGISTER_FAIL_AUTH,
      });
    }

    return Promise.resolve(res.data);
  } 
  catch (err) 
  {
    dispatch({
      type: REGISTER_FAIL_AUTH,
    });

    return Promise.reject(err);
  }
};

export const socialLogin = (type, unique, email, name) => async (dispatch) => 
{
  try 
  {
    const res = await AuthDataService.socialUserCheck(type, unique, email, name);

    // console.log("res : ", res);

    // if (res.data.success)  // 회원가입이 되어 있다면 로그인을 시키고 바로 프로필로 이동함
    // {  
    //   console.log("res : ", res);

    //   // 로그인 및 프로필로 이동
    //   let user = {
    //     email: res.data.data.mem_email,
    //     idx: res.data.data.mem_idx,
    //     name: res.data.data.mem_name,
    //     type: res.data.data.mem_type,
    //     nickname: res.data.data.mem_name,
    //   };

    //   // localStorage.setItem("user", JSON.stringify(user));

    //   dispatch({
    //     type: LOGIN_SUCCESS_AUTH,
    //     payload: user,
    //   });
    // }
    // else // 가입된 회원이 아니라면 회원으로 가입시킨 후에 로그인 시켜서 프로필 페이지로 이동함
    // {
    //   dispatch({
    //     type: LOGIN_FAIL_AUTH,
    //   });
    // }

    // 사용자 데이터가 들어왔는지 확인 후에 로컬스토리지에 저장한다.
    if (res.data.success) 
    {  
      localStorage.setItem("user", JSON.stringify(res.data.data));

      dispatch({
        type: LOGIN_SUCCESS_AUTH,
        payload: res.data.data,
      });
    }
    else
    {
      dispatch({
        type: LOGIN_FAIL_AUTH,
      });
    }

    return Promise.resolve(res.data);
  } 
  catch (err) 
  {
    dispatch({
      type: LOGIN_FAIL_AUTH,
    });

    return Promise.reject(err);
  }
};


export const groupRegister = (data) => async (dispatch) => 
{
  try 
  {
    const res = await AuthDataService.groupRegister(data);

    // console.log("res : ", res);

    // 사용자 데이터가 들어왔는지 확인 후에 로컬스토리지에 저장한다.
    if (res.data.success) 
    {
      dispatch({
        type: GROUP_REGISTER_SUCCESS_AUTH,
      });
    }
    else
    {
      dispatch({
        type: GROUP_REGISTER_FAIL_AUTH,
      });
    }

    return Promise.resolve(res.data);
  } 
  catch (err) 
  {
    dispatch({
      type: GROUP_REGISTER_FAIL_AUTH,
    });

    return Promise.reject(err);
  }
};

export const passwordChange = (data) => async (dispatch) => {
  
  try {
    
    const res = await AuthDataService.passwordChange(data);

    // console.log("res : ", res);

    dispatch({
      type: PASSWORD_CHANGE_AUTH,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err);
  }

};

export const passwordUpdate = (data) => async (dispatch) => {
  
  try {
    
    const res = await AuthDataService.passwordUpdate(data);

    // console.log("res : ", res);

    dispatch({
      type: PASSWORD_UPDATE_AUTH,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err);
  }

};
