import http from "../http-common";

const packageGetAll = () => {
  return http.get("users/commonPackage");
};

const packageUseStudent = package_no => {
  return http.get(`/users/commonPackage/use/${package_no}`);
};

const packageUseNotStudent = () => {
  return http.get(`/users/commonPackage/useNot/`);
};

const packageUseStudentModify = data => {
  return http.put("users/commonPackage/use", data);
};

const packageStudentInfo = () => {
  return http.get("users/commonPackage/studentPackageUseInfo");
};

const packageStudentList = () => {
  return http.get("users/commonStudent");
};

const PackageService = {
  packageGetAll,
  packageUseStudent,
  packageUseNotStudent,
  packageUseStudentModify,
  packageStudentInfo,
  packageStudentList,
};

export default PackageService;
