import {
  CREATE_PRODUCT,
  LIST_PRODUCT,
  DELETE_PRODUCT,
  MODIFY_PRODUCT,
  SELECT_PRODUCT,     
} from "./types";

import ProductService from "../services/ProductService";

export const productCreate = (data) => async (dispatch) => {
  
  try {
    
    const res = await ProductService.productCreate(data);

    // console.log("res : ", res);

    dispatch({
      type: CREATE_PRODUCT,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const productList = () => async (dispatch) => {
  
  try {
    
    const res = await ProductService.productGetAll();

    // console.log("res : ", res);

    dispatch({
      type: LIST_PRODUCT,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const productSelect = (prd_idx) => async (dispatch) => {
  
  try {
    
    const res = await ProductService.productSelect(prd_idx);

    // console.log("res : ", res);

    dispatch({
      type: SELECT_PRODUCT,
      payload: res.data.data,
    });

    return Promise.resolve(res.data.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const productDelete = (prd_idx) => async (dispatch) => {
  
  try {

    // console.log("productDelete! prd_idx : ", prd_idx);

    const res = await ProductService.productDelete(prd_idx);

    // console.log("res : ", res);

    if (res.data.success === false)
    {
      alert(res.data.msg);
      return Promise.resolve(prd_idx);
    }
    else
    {
      dispatch({
        type: DELETE_PRODUCT,
        payload: { prd_idx },
      });
  
      return Promise.resolve(res.data);
    }

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};

export const productModify = (data) => async (dispatch) => {
  
  try {
    
    const res = await ProductService.productModify(data);

    // console.log("res : ", res);

    dispatch({
      type: MODIFY_PRODUCT,
      payload: res.data,
    });

    return Promise.resolve(res.data);

  } catch (err) {
    //console.log("Error: ", err.response);
    return Promise.reject(err.response);
  }

};
