import React from 'react';
import styles from "../../css/common.module.css";

const Footer = () => {

    return (
        <>
            <section className={styles.footer_section}>
			<div className={styles.footer}>
				<div className={styles.inner90}>
					<div className={styles.f_left}>
						<ul>
							<li><img src="/assets/f_logo@2x.png" alt="f_logo"/></li>
							<li>
								<a target="_blank" rel="noopener noreferrer" href="http://www.weclover.co.kr/">회사소개</a>
								<a href="/Use">이용약관</a>
								<a href="/Privacy">개인정보처리방침</a>
							</li>
							<li>
							(주)위클러버 (대표자:이성진)&nbsp;&nbsp;|&nbsp;&nbsp;서울특별시 관악구 낙성대로 4가길5, 낙성벤처창업센터 낙성대동주민센터점 2층 4호 <br/>통신판매업신고 : 제2018-서울관악-0254호&nbsp;&nbsp;|&nbsp;&nbsp;사업자등록번호 : 179-87-00821 | 	전화번호 : 02-875-3330				
							</li>
							<li>© 2022.weclover Inc. all rights reserved.</li>
						</ul>
					</div>
					<div className={styles.f_right}>
						<ul>
							<li>
								<a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/weclover">
									<img src="/assets/f_facebook@2x.png" alt="페이스북"/>
								</a>
								<a target="_blank" rel="noopener noreferrer" href="#!">
									<img src="/assets/f_insta@2x.png" alt="인스타"/>
								</a>
								<a href="https://www.youtube.com/channel/UCm-2bRy6jSI5Wg4MY4tS6hg" target="_blank" rel="noopener noreferrer">
									<img src="/assets/f_youtube@2x.png" alt="유튜브"/>
								</a>
							</li>
							{/* <li className={styles.faq}><a href="/Faq">자주묻는질문</a></li> */}
							{/* <li class="faq">채팅상담</li> */}
							<li className={styles.inquiry}>고객/제휴문의 : admin@weclover.co.kr</li>
						</ul>
					</div>
				</div>
			</div>
		    </section>
        </>
    );
    
};

export default Footer;